<template>
  <!-- <div class="md-layout-item md-size-175 tab-heading"> -->
  <div class="md-layout">
    <div class="overlayLoader" v-if="loader">
      <md-progress-spinner
        :md-diameter="100"
        class="md-accent spinner-postion"
        :md-stroke="7"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <!-- <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <div class="md-layout">
          <md-card-header
            class="md-card-header-text md-card-header-green search-icon"
          >
            <div class="card-icon vendor-icon">
              <md-icon style="font-size: 20px !important">search</md-icon>
            </div>
          </md-card-header>
          <div class="md-layout" style="margin-top: 10px; margin-left:-2rem;">
            <md-card-header class="md-card-header-text md-card-header-rose">
            </md-card-header>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <md-field>
                <label for="font">Name</label>
                <md-input
                  v-model="vendorName"
                  :title="vendorName"
                  disabled
                ></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-10">
              <md-field>
                <label for="font">PAN No.</label>
                <md-input
                  v-model="vendorPan"
                  :title="vendorPan"
                  disabled
                ></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <md-field>
                <label for="font">Approval Status</label>
                <md-input
                  v-model="appStatus"
                  :title="appStatus"
                  disabled
                ></md-input>
              </md-field>
            </div>

            <div
              style="margin-left:-1.5rem; margin-top:-0.5rem;"
              class="md-layout-item md-small-size-100 md-size-15"
              v-if="vendorPan !== 'PANNOTAVBL' && !isDocumentUploaded"
            >
              <md-field md-clearable>
                <label class="mandatory">Upload PAN</label>
                <md-file
                  required
                  v-model="panDocument"
                  @md-change="selectFile"
                />
              </md-field>
            </div>
            <div
              class="md-layout-item md-small-size-100 md-size-10"
              v-if="vendorPan !== 'PANNOTAVBL' && !isDocumentUploaded"
            >
              <md-button
                v-if="panDocument"
                class="md-raised md-rose search-btn"
                @click="getOpenKmAuthenticateUpload()"
                >Upload</md-button
              >
            </div>

            <div v-if="isDocumentUploaded">
              <b>PAN Document: </b><br />
              <span style="margin-top:1.8rem; cursor:pointer;">
                <a
                  style="margin-left:0.5rem;"
                  target="_blank"
                  :href="
                    downloadDocUrl +
                      '/download/' +
                      docDetailId +
                      '?token=' +
                      openKmAuth
                  "
                  ><b>{{ fileName }}</b></a
                >
              </span>
            </div>

            <div
              :style="
                vendorPan === 'PANNOTAVBL'
                  ? 'margin-left:18rem; margin-top:-0.5rem;'
                  : vendorPan !== 'PANNOTAVBL' && isDocumentUploaded
                  ? 'margin-left:10rem; margin-top:-0.5rem;'
                  : 'margin-top:-0.5rem;'
              "
              class="md-layout"
              v-if="appStatus === 'DRAFT' || appStatus === 'REJECTED'"
            >
              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-button
                  class="md-raised md-rose search-btn"
                  @click="openAddress()"
                  >Add Address</md-button
                >
              </div>
              <div
                class="md-layout-item md-small-size-100 md-size-15"
                v-if="
                  vendorAddressData.length &&
                    vendorContactData.length &&
                    vendorBankData.length &&
                    vendorComplianceData.length &&
                    vendorAdditionalData.length
                "
              >
                <md-button
                  class="md-raised md-rose search-btn"
                  @click="isAllDocUploaded()"
                  >Submit</md-button
                >
              </div>
            </div>

            <div style="margin-left:17rem;">
              <md-button
                class="md-raised md-rose search-btn"
                @click="showHideReviewModal(true)"
                >Review</md-button
              >
            </div>

            <div style="margin-top:5px;">
              <md-button
                class="md-raised md-rose search-btn"
                @click="addEditVendorDetails()"
                >save</md-button
              >
            </div>
          </div>
        </div>
      </md-card>
    </div> -->
    <div class="md-layout-item md-size-175 tab-heading">
      <md-tabs class="md-primary">
        <md-tab
          id="tab-address"
          md-label="Address"
          @click="selectedTab('address')"
        >
          <md-card>
            <!-- code for header details -->
            <!-- <div
            style="margin-left:63.5rem;"
            class="md-layout"
            v-if="appStatus === 'DRAFT'"
          >
            <div class="md-layout-item md-small-size-100 md-size-10">
              <md-button
                class="md-raised md-rose search-btn"
                @click="openAddress()"
                >Add Address</md-button
              >
            </div>
            <div
              class="md-layout-item md-small-size-100 md-size-15"
              v-if="
                vendorAddressData.length &&
                  vendorContactData.length &&
                  vendorBankData.length &&
                  vendorComplianceData.length
              "
            >
              <md-button
                class="md-raised md-rose search-btn"
                @click="isAllDocUploaded()"
                >Submit</md-button
              >
            </div>
          </div> -->
            <!-- <md-card-header
            class="md-card-header-text md-layout-item md-card-header-blue"
            :style="
              appStatus === 'DRAFT' ? 'margin-top: -30px' : 'margin-top: 7px'
            "
          >
            <div
              class="text-center card-text profile-card"
              style="height: 30px; width: 140px; padding: 3px !important"
            >
              <h5 class="title">Address Details</h5>
            </div>
          </md-card-header> -->
            <!-- New Header -->
            <!-- <div style="display: flex">
              <md-card-header
                class="md-card-header-text md-layout-item md-card-header-blue"
                style="margin-top: 5px !important"
              >
                <div class="card-text profile-card">
                  <h6 class="title">Address Details</h6>
                </div>
              </md-card-header>
              <md-card-header
                class="md-card-header-text md-card-header-blue"
                style="margin-top: 7px !important"
              >
                <div
                  class="card-text profile-card"
                  style="cursor:pointer;"
                  @click="getOpenKmAuthenticate()"
                  v-if="venInvoiceId"
                >
                  <md-icon
                    title="Upload Document"
                    style="width:15px; height:15px;"
                    >file_upload</md-icon
                  >
                </div>
              </md-card-header>
              <md-card-header
                class="md-card-header-text md-card-header-blue"
                style="margin-top: 5px !important"
              >
                <div
                  class="card-text profile-card"
                  style="cursor:pointer;"
                  @click="showViewInvoiceForm(false)"
                >
                  <h6 class="title">Submit</h6>
                </div>
              </md-card-header>
            </div> -->
            <!-- New Header -->
            <div class="md-layout" style="margin-top: 10px; margin-left:-2rem;">
              <md-card-header class="md-card-header-text md-card-header-rose">
                <!-- <div class="card-icon vendor-icon">
                <md-icon style="font-size: 30px !important"
                  >perm_identity</md-icon
                >
              </div> -->
              </md-card-header>
              <div class="md-layout-item md-small-size-100 md-size-20">
                <md-field>
                  <label for="font">Name</label>
                  <md-input
                    v-model="vendorName"
                    :title="vendorName"
                    disabled
                  ></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-10">
                <md-field>
                  <label for="font">PAN No.</label>
                  <md-input
                    v-model="vendorPan"
                    :title="vendorPan"
                    disabled
                  ></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-15">
                <md-field>
                  <label for="font">Approval Status</label>
                  <md-input
                    v-model="appStatus"
                    :title="appStatus"
                    disabled
                  ></md-input>
                </md-field>
              </div>

              <div
                style="margin-left:-1.5rem; margin-top:-0.5rem;"
                class="md-layout-item md-small-size-100 md-size-15"
                v-if="vendorPan !== 'PANNOTAVBL' && !isDocumentUploaded"
              >
                <md-field md-clearable>
                  <label class="mandatory">Upload PAN</label>
                  <md-file
                    required
                    v-model="panDocument"
                    @md-change="selectFile"
                  />
                </md-field>
              </div>
              <div
                class="md-layout-item md-small-size-100 md-size-10"
                v-if="vendorPan !== 'PANNOTAVBL' && !isDocumentUploaded"
              >
                <md-button
                  v-if="panDocument"
                  class="md-raised md-rose search-btn"
                  @click="getOpenKmAuthenticateUpload()"
                  >Upload</md-button
                >
              </div>

              <div v-if="isDocumentUploaded">
                <b>PAN Document: </b><br />
                <span style="margin-top:1.8rem; cursor:pointer;">
                  <a
                    style="margin-left:0.5rem;"
                    target="_blank"
                    :href="
                      downloadDocUrl +
                        '/download/' +
                        docDetailId +
                        '?token=' +
                        openKmAuth
                    "
                    ><b>{{ fileName }}</b></a
                  >
                </span>
              </div>

              <div
                :style="
                  vendorPan === 'PANNOTAVBL'
                    ? 'margin-left:18rem; margin-top:-0.5rem;'
                    : vendorPan !== 'PANNOTAVBL' && isDocumentUploaded
                    ? 'margin-left:10rem; margin-top:-0.5rem;'
                    : 'margin-top:-0.5rem;'
                "
                class="md-layout"
                v-if="appStatus === 'DRAFT' || appStatus === 'REJECTED'"
              >
                <div class="md-layout-item md-small-size-100 md-size-50">
                  <md-button
                    class="md-raised md-rose search-btn"
                    @click="openAddress()"
                    >Add Address</md-button
                  >
                </div>
                <div class="md-layout-item md-small-size-100 md-size-15">
                  <md-button
                    class="md-raised md-rose search-btn"
                    @click="isAllDtlsAvl()"
                    >Submit</md-button
                  >
                </div>
              </div>

              <!-- <div style="margin-left:17rem;">
              <md-button
                class="md-raised md-rose search-btn"
                @click="showHideReviewModal(true)"
                >Review</md-button
              >
            </div> -->
              <!-- <div class="md-layout-item md-small-size-100 md-size-15">
              <label for="font">E-Invoice Applicable</label>
              <b-form-checkbox
                style="margin-top:7px;"
                v-model="einvoice"
                :true-value="true"
                :false-value="false"
              ></b-form-checkbox>
            </div>

            <div style="margin-top:5px;">
              <md-button
                class="md-raised md-rose search-btn"
                @click="addEditVendorDetails()"
                >save</md-button
              >
            </div> -->
            </div>
            <md-card-content>
              <div class="md-layout-item">
                <b-table
                  striped
                  hover
                  outlined
                  sticky-header
                  :items="vendorAddressData"
                  :fields="vendorAddressField"
                  show-empty
                  class="bg-white"
                  @row-clicked="rowSelectedAddress"
                >
                  <template #cell(gstn_not_avail)="data">
                    <span>{{ data.item.gstn_not_avail ? "Yes" : "No" }}</span>
                  </template>
                  <template #cell(sez)="data">
                    <span>{{ data.item.sez ? "Yes" : "No" }}</span>
                  </template>
                  <template #cell(original_file_name)="data">
                    <a
                      href="#"
                      style="cursor:pointer;"
                      @click="
                        getOpenKmAuthenticate(
                          data.item.document_det_id,
                          data.item.original_file_name
                        )
                      "
                      >{{ data.item.original_file_name }}</a
                    >
                  </template>
                  <template #cell(file_name)="data">
                    <a
                      href="#"
                      style="cursor:pointer;"
                      @click="
                        getOpenKmAuthenticatePreview(
                          data.item.document_det_id,
                          data.item.original_file_name,
                          data.index,
                          vendorAddressData
                        )
                      "
                      ><span
                        v-if="data.item.document_det_id"
                        class="material-icons"
                      >
                        preview
                      </span></a
                    >
                  </template>
                  <template
                    #cell(delete)="data"
                    v-if="appStatus === 'DRAFT' || appStatus === 'REJECTED'"
                  >
                    <a>
                      <span
                        class="material-icons"
                        style="cursor:pointer; color:black"
                        @click="deleteAddressRecord(data.item.address_id)"
                      >
                        delete
                      </span></a
                    >
                  </template>
                </b-table>
              </div>
            </md-card-content>
          </md-card>
        </md-tab>

        <md-tab
          id="tab-contact"
          md-label="Contact Details"
          @click="selectedTab('contactDetails')"
        >
          <md-card>
            <!-- <div
            style="margin-left:63.5rem;"
            class="md-layout"
            v-if="appStatus === 'DRAFT'"
          >
            <div class="md-layout-item md-small-size-100 md-size-10">
              <md-button
                class="md-raised md-rose search-btn"
                @click="openContact()"
                >Add Contact</md-button
              >
            </div>
            <div
              class="md-layout-item md-small-size-100 md-size-15"
              v-if="
                vendorAddressData.length &&
                  vendorContactData.length &&
                  vendorBankData.length &&
                  vendorComplianceData.length
              "
            >
              <md-button
                class="md-raised md-rose search-btn"
                @click="isAllDocUploaded()"
                >Submit</md-button
              >
            </div>
          </div> -->
            <!-- <md-card-header
            class="md-card-header-text md-layout-item md-card-header-blue"
            :style="
              appStatus === 'DRAFT' ? 'margin-top: -30px' : 'margin-top: 7px'
            "
          >
            <div
              class="text-center card-text profile-card"
              style="height: 30px; width: 140px; padding: 3px !important"
            >
              <h5 class="title">Contact Details</h5>
            </div>
          </md-card-header> -->
            <div class="md-layout" style="margin-top: 10px; margin-left:-2rem;">
              <md-card-header class="md-card-header-text md-card-header-rose">
                <!-- <div class="card-icon vendor-icon">
                <md-icon style="font-size: 30px !important"
                  >perm_identity</md-icon
                >
              </div> -->
              </md-card-header>
              <div class="md-layout-item md-small-size-100 md-size-20">
                <md-field>
                  <label for="font">Name</label>
                  <md-input
                    v-model="vendorName"
                    :title="vendorName"
                    disabled
                  ></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-10">
                <md-field>
                  <label for="font">PAN No.</label>
                  <md-input
                    v-model="vendorPan"
                    :title="vendorPan"
                    disabled
                  ></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-15">
                <md-field>
                  <label for="font">Approval Status</label>
                  <md-input
                    v-model="appStatus"
                    :title="appStatus"
                    disabled
                  ></md-input>
                </md-field>
              </div>

              <div
                style="margin-left:-1.5rem; margin-top:-0.5rem;"
                class="md-layout-item md-small-size-100 md-size-15"
                v-if="vendorPan !== 'PANNOTAVBL' && !isDocumentUploaded"
              >
                <md-field md-clearable>
                  <label class="mandatory">Upload PAN</label>
                  <md-file
                    required
                    v-model="panDocument"
                    @md-change="selectFile"
                  />
                </md-field>
              </div>
              <div
                class="md-layout-item md-small-size-100 md-size-10"
                v-if="vendorPan !== 'PANNOTAVBL' && !isDocumentUploaded"
              >
                <md-button
                  v-if="panDocument"
                  class="md-raised md-rose search-btn"
                  @click="getOpenKmAuthenticateUpload()"
                  >Upload</md-button
                >
              </div>
              <div v-if="isDocumentUploaded">
                <b>PAN Document: </b><br />
                <span style="margin-top:1.8rem; cursor:pointer;">
                  <a
                    style="margin-left:0.5rem;"
                    target="_blank"
                    :href="
                      downloadDocUrl +
                        '/download/' +
                        docDetailId +
                        '?token=' +
                        openKmAuth
                    "
                    ><b>{{ fileName }}</b></a
                  >
                </span>
              </div>

              <div
                :style="
                  vendorPan === 'PANNOTAVBL'
                    ? 'margin-left:18rem; margin-top:-0.5rem;'
                    : vendorPan !== 'PANNOTAVBL' && isDocumentUploaded
                    ? 'margin-left:10rem; margin-top:-0.5rem;'
                    : 'margin-top:-0.5rem;'
                "
                class="md-layout"
                v-if="appStatus === 'DRAFT' || appStatus === 'REJECTED'"
              >
                <div class="md-layout-item md-small-size-100 md-size-50">
                  <md-button
                    class="md-raised md-rose search-btn"
                    @click="openContact()"
                    >Add Contact</md-button
                  >
                </div>
                <div class="md-layout-item md-small-size-100 md-size-15">
                  <md-button
                    class="md-raised md-rose search-btn"
                    @click="isAllDtlsAvl()"
                    >Submit</md-button
                  >
                </div>
              </div>
            </div>
            <md-card-content>
              <div class="md-layout-item">
                <b-table
                  striped
                  hover
                  outlined
                  sticky-header
                  :items="vendorContactData"
                  :fields="vendorContactField"
                  show-empty
                  class="bg-white"
                  @row-clicked="rowSelectedContact"
                >
                  <template
                    #cell(delete)="data"
                    v-if="appStatus === 'DRAFT' || appStatus === 'REJECTED'"
                  >
                    <a>
                      <span
                        class="material-icons"
                        style="cursor:pointer; color:black"
                        @click="deleteContactRecord(data.item.contact_id)"
                      >
                        delete
                      </span></a
                    >
                  </template>
                </b-table>
              </div>
            </md-card-content>
          </md-card>
        </md-tab>

        <md-tab
          id="tab-bank"
          md-label="Bank Details"
          @click="selectedTab('bankDetails')"
        >
          <md-card>
            <!-- <div
            style="margin-left:63.5rem;"
            class="md-layout"
            v-if="appStatus === 'DRAFT'"
          >
            <div class="md-layout-item md-small-size-100 md-size-10">
              <md-button
                class="md-raised md-rose search-btn"
                @click="openBank()"
                >Add Bank</md-button
              >
            </div>
            <div
              class="md-layout-item md-small-size-100 md-size-15"
              v-if="
                vendorAddressData.length &&
                  vendorContactData.length &&
                  vendorBankData.length &&
                  vendorComplianceData.length
              "
            >
              <md-button
                class="md-raised md-rose search-btn"
                @click="isAllDocUploaded()"
                >Submit</md-button
              >
            </div>
          </div> -->
            <!-- <md-card-header
            class="md-card-header-text md-layout-item md-card-header-blue"
            :style="
              appStatus === 'DRAFT' ? 'margin-top: -30px' : 'margin-top: 7px'
            "
          >
            <div
              class="text-center card-text profile-card"
              style="height: 30px; width: 140px; padding: 3px !important"
            >
              <h5 class="title">Bank Details</h5>
            </div>
          </md-card-header> -->
            <div class="md-layout" style="margin-top: 10px; margin-left:-2rem;">
              <md-card-header class="md-card-header-text md-card-header-rose">
                <!-- <div class="card-icon vendor-icon">
                <md-icon style="font-size: 30px !important"
                  >perm_identity</md-icon
                >
              </div> -->
              </md-card-header>
              <div class="md-layout-item md-small-size-100 md-size-20">
                <md-field>
                  <label for="font">Name</label>
                  <md-input
                    v-model="vendorName"
                    :title="vendorName"
                    disabled
                  ></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-10">
                <md-field>
                  <label for="font">PAN No.</label>
                  <md-input
                    v-model="vendorPan"
                    :title="vendorPan"
                    disabled
                  ></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-15">
                <md-field>
                  <label for="font">Approval Status</label>
                  <md-input
                    v-model="appStatus"
                    :title="appStatus"
                    disabled
                  ></md-input>
                </md-field>
              </div>

              <div
                style="margin-left:-1.5rem; margin-top:-0.5rem;"
                class="md-layout-item md-small-size-100 md-size-15"
                v-if="vendorPan !== 'PANNOTAVBL' && !isDocumentUploaded"
              >
                <md-field md-clearable>
                  <label class="mandatory">Upload PAN</label>
                  <md-file
                    required
                    v-model="panDocument"
                    @md-change="selectFile"
                  />
                </md-field>
              </div>
              <div
                class="md-layout-item md-small-size-100 md-size-10"
                v-if="vendorPan !== 'PANNOTAVBL' && !isDocumentUploaded"
              >
                <md-button
                  v-if="panDocument"
                  class="md-raised md-rose search-btn"
                  @click="getOpenKmAuthenticateUpload()"
                  >Upload</md-button
                >
              </div>
              <div v-if="isDocumentUploaded">
                <b>PAN Document: </b><br />
                <span style="margin-top:1.8rem; cursor:pointer;">
                  <a
                    style="margin-left:0.5rem;"
                    target="_blank"
                    :href="
                      downloadDocUrl +
                        '/download/' +
                        docDetailId +
                        '?token=' +
                        openKmAuth
                    "
                    ><b>{{ fileName }}</b></a
                  >
                </span>
              </div>

              <div
                :style="
                  vendorPan === 'PANNOTAVBL'
                    ? 'margin-left:18rem; margin-top:-0.5rem;'
                    : vendorPan !== 'PANNOTAVBL' && isDocumentUploaded
                    ? 'margin-left:10rem; margin-top:-0.5rem;'
                    : 'margin-top:-0.5rem;'
                "
                class="md-layout"
                v-if="appStatus === 'DRAFT' || appStatus === 'REJECTED'"
              >
                <div class="md-layout-item md-small-size-100 md-size-50">
                  <md-button
                    class="md-raised md-rose search-btn"
                    @click="openBank()"
                    >Add Bank</md-button
                  >
                </div>
                <div class="md-layout-item md-small-size-100 md-size-15">
                  <md-button
                    class="md-raised md-rose search-btn"
                    @click="isAllDtlsAvl()"
                    >Submit</md-button
                  >
                </div>
              </div>
            </div>
            <md-card-content>
              <div class="md-layout-item">
                <b-table
                  striped
                  hover
                  outlined
                  sticky-header
                  :items="vendorBankData"
                  :fields="vendorBankFields"
                  show-empty
                  class="bg-white"
                  @row-clicked="rowSelectedBank"
                >
                  <template #cell(original_file_name)="data">
                    <a
                      href="#"
                      style="cursor:pointer;"
                      @click="
                        getOpenKmAuthenticate(
                          data.item.document_det_id,
                          data.item.original_file_name
                        )
                      "
                      >{{ data.item.original_file_name }}</a
                    >
                  </template>
                  <template #cell(file_name)="data">
                    <a
                      href="#"
                      style="cursor:pointer;"
                      @click="
                        getOpenKmAuthenticatePreview(
                          data.item.document_det_id,
                          data.item.original_file_name,
                          data.index,
                          vendorBankData
                        )
                      "
                      ><span
                        v-if="data.item.document_det_id"
                        class="material-icons"
                      >
                        preview
                      </span></a
                    >
                  </template>
                  <template
                    #cell(delete)="data"
                    v-if="appStatus === 'DRAFT' || appStatus === 'REJECTED'"
                  >
                    <a>
                      <span
                        class="material-icons"
                        style="cursor:pointer; color:black"
                        @click="deleteBankRecord(data.item.party_bank_req_id)"
                      >
                        delete
                      </span></a
                    >
                  </template>
                </b-table>
              </div>
            </md-card-content>
          </md-card>
        </md-tab>

        <md-tab
          id="tab-compliance"
          md-label="Compliance"
          @click="selectedTab('compliance')"
        >
          <md-card>
            <!-- <div
            style="margin-left:62rem;"
            class="md-layout"
            v-if="appStatus === 'DRAFT'"
          >
            <div class="md-layout-item md-small-size-100 md-size-10">
              <md-button
                v-if="!vendorComplianceData.length"
                class="md-raised md-rose search-btn"
                @click="openCompliance()"
                >Add Compliance</md-button
              >
            </div>
            <div
              class="md-layout-item md-small-size-100 md-size-15"
              v-if="
                vendorAddressData.length &&
                  vendorContactData.length &&
                  vendorBankData.length &&
                  vendorComplianceData.length
              "
            >
              <md-button
                style="margin-left:1.5rem;"
                class="md-raised md-rose search-btn"
                @click="isAllDocUploaded()"
                >Submit</md-button
              >
            </div>
          </div> -->
            <!-- <md-card-header
            class="md-card-header-text md-layout-item md-card-header-blue"
            :style="
              appStatus === 'DRAFT' ? 'margin-top: -30px' : 'margin-top: 7px'
            "
          >
            <div
              class="text-center card-text profile-card"
              style="height: 30px; width: 160px; padding: 3px !important"
            >
              <h5 class="title">Compliance Details</h5>
            </div>
          </md-card-header> -->
            <div class="md-layout" style="margin-top: 10px; margin-left:-2rem;">
              <md-card-header class="md-card-header-text md-card-header-rose">
                <!-- <div class="card-icon vendor-icon">
                <md-icon style="font-size: 30px !important"
                  >perm_identity</md-icon
                >
              </div> -->
              </md-card-header>
              <div class="md-layout-item md-small-size-100 md-size-20">
                <md-field>
                  <label for="font">Name</label>
                  <md-input
                    v-model="vendorName"
                    :title="vendorName"
                    disabled
                  ></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-10">
                <md-field>
                  <label for="font">PAN No.</label>
                  <md-input
                    v-model="vendorPan"
                    :title="vendorPan"
                    disabled
                  ></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-15">
                <md-field>
                  <label for="font">Approval Status</label>
                  <md-input
                    v-model="appStatus"
                    :title="appStatus"
                    disabled
                  ></md-input>
                </md-field>
              </div>

              <div
                style="margin-left:-1.5rem; margin-top:-0.5rem;"
                class="md-layout-item md-small-size-100 md-size-15"
                v-if="vendorPan !== 'PANNOTAVBL' && !isDocumentUploaded"
              >
                <md-field md-clearable>
                  <label class="mandatory">Upload PAN</label>
                  <md-file
                    required
                    v-model="panDocument"
                    @md-change="selectFile"
                  />
                </md-field>
              </div>
              <div
                class="md-layout-item md-small-size-100 md-size-10"
                v-if="vendorPan !== 'PANNOTAVBL' && !isDocumentUploaded"
              >
                <md-button
                  v-if="panDocument"
                  class="md-raised md-rose search-btn"
                  @click="getOpenKmAuthenticateUpload()"
                  >Upload</md-button
                >
              </div>
              <div v-if="isDocumentUploaded">
                <b>PAN Document: </b><br />
                <span style="margin-top:1.8rem; cursor:pointer;">
                  <a
                    style="margin-left:0.5rem;"
                    target="_blank"
                    :href="
                      downloadDocUrl +
                        '/download/' +
                        docDetailId +
                        '?token=' +
                        openKmAuth
                    "
                    ><b>{{ fileName }}</b></a
                  >
                </span>
              </div>

              <div
                :style="
                  vendorPan === 'PANNOTAVBL'
                    ? 'margin-left:18rem; margin-top:-0.5rem;'
                    : vendorPan !== 'PANNOTAVBL' && isDocumentUploaded
                    ? 'margin-left:10rem; margin-top:-0.5rem;'
                    : 'margin-top:-0.5rem;'
                "
                class="md-layout"
                v-if="appStatus === 'DRAFT' || appStatus === 'REJECTED'"
              >
                <div class="md-layout-item md-small-size-100 md-size-45">
                  <md-button
                    v-if="!vendorComplianceData.length"
                    class="md-raised md-rose search-btn"
                    @click="openCompliance()"
                    >Add Compliance</md-button
                  >
                </div>
                <div class="md-layout-item md-small-size-100 md-size-15">
                  <md-button
                    style="margin-left:1.5rem;"
                    class="md-raised md-rose search-btn"
                    @click="isAllDtlsAvl()"
                    >Submit</md-button
                  >
                </div>
              </div>
            </div>
            <md-card-content>
              <div class="md-layout-item">
                <b-table
                  striped
                  hover
                  outlined
                  sticky-header
                  :items="vendorComplianceData"
                  :fields="vendorComplianceFields"
                  show-empty
                  class="bg-white"
                  @row-clicked="rowSelectedCompliance"
                >
                  <template #cell(original_file_name)="data">
                    <a
                      href="#"
                      style="cursor:pointer;"
                      @click="
                        getOpenKmAuthenticate(
                          data.item.document_det_id,
                          data.item.original_file_name
                        )
                      "
                      >{{ data.item.original_file_name }}</a
                    >
                  </template>
                  <template #cell(file_name)="data">
                    <a
                      href="#"
                      style="cursor:pointer;"
                      @click="
                        getOpenKmAuthenticatePreview(
                          data.item.document_det_id,
                          data.item.original_file_name,
                          data.index,
                          vendorComplianceData
                        )
                      "
                      ><span
                        v-if="data.item.document_det_id"
                        class="material-icons"
                      >
                        preview
                      </span></a
                    >
                  </template>
                </b-table>
              </div>
            </md-card-content>
          </md-card>
        </md-tab>

        <md-tab
          id="tab-addDetails"
          md-label="Additional Details"
          @click="selectedTab('addDetails')"
        >
          <md-card>
            <!-- <div
            style="margin-left:60rem;"
            class="md-layout"
            v-if="appStatus === 'DRAFT'"
          >
            <div class="md-layout-item md-small-size-100 md-size-10">
              <md-button
                v-if="!vendorAdditionalData.length"
                class="md-raised md-rose search-btn"
                @click="openDetails()"
                >Add Details</md-button
              >
            </div>
            <div
              class="md-layout-item md-small-size-100 md-size-15"
              v-if="
                vendorAddressData.length &&
                  vendorContactData.length &&
                  vendorBankData.length &&
                  vendorComplianceData.length
              "
            >
              <md-button
                style="margin-left:1.5rem;"
                class="md-raised md-rose search-btn"
                @click="isAllDocUploaded()"
                >Submit</md-button
              >
            </div>
          </div> -->
            <!-- <div class="md-layout-item md-small-size-100 md-size-15">
              <md-button class="md-raised md-rose search-btn">Save</md-button>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <md-button class="md-raised md-rose search-btn">Submit</md-button>
            </div> -->
            <!-- <md-card-header
            class="md-card-header-text md-layout-item md-card-header-blue"
            :style="
              appStatus === 'DRAFT' ? 'margin-top: -30px' : 'margin-top: 7px'
            "
          >
            <div
              class="text-center card-text profile-card"
              style="height: 30px; width: 160px; padding: 3px !important"
            >
              <h5 class="title">Additional Details</h5>
            </div>
          </md-card-header> -->
            <div class="md-layout" style="margin-top: 10px; margin-left:-2rem;">
              <md-card-header class="md-card-header-text md-card-header-rose">
                <!-- <div class="card-icon vendor-icon">
                <md-icon style="font-size: 30px !important"
                  >perm_identity</md-icon
                >
              </div> -->
              </md-card-header>
              <div class="md-layout-item md-small-size-100 md-size-20">
                <md-field>
                  <label for="font">Name</label>
                  <md-input
                    v-model="vendorName"
                    :title="vendorName"
                    disabled
                  ></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-10">
                <md-field>
                  <label for="font">PAN No.</label>
                  <md-input
                    v-model="vendorPan"
                    :title="vendorPan"
                    disabled
                  ></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-15">
                <md-field>
                  <label for="font">Approval Status</label>
                  <md-input
                    v-model="appStatus"
                    :title="appStatus"
                    disabled
                  ></md-input>
                </md-field>
              </div>

              <div
                style="margin-left:-1.5rem; margin-top:-0.5rem;"
                class="md-layout-item md-small-size-100 md-size-15"
                v-if="vendorPan !== 'PANNOTAVBL' && !isDocumentUploaded"
              >
                <md-field md-clearable>
                  <label class="mandatory">Upload PAN</label>
                  <md-file
                    required
                    v-model="panDocument"
                    @md-change="selectFile"
                  />
                </md-field>
              </div>
              <div
                class="md-layout-item md-small-size-100 md-size-10"
                v-if="vendorPan !== 'PANNOTAVBL' && !isDocumentUploaded"
              >
                <md-button
                  v-if="panDocument"
                  class="md-raised md-rose search-btn"
                  @click="getOpenKmAuthenticateUpload()"
                  >Upload</md-button
                >
              </div>
              <div v-if="isDocumentUploaded">
                <b>PAN Document: </b><br />
                <span style="margin-top:1.8rem; cursor:pointer;">
                  <a
                    style="margin-left:0.5rem;"
                    target="_blank"
                    :href="
                      downloadDocUrl +
                        '/download/' +
                        docDetailId +
                        '?token=' +
                        openKmAuth
                    "
                    ><b>{{ fileName }}</b></a
                  >
                </span>
              </div>

              <div
                :style="
                  vendorPan === 'PANNOTAVBL'
                    ? 'margin-left:18rem; margin-top:-0.5rem;'
                    : vendorPan !== 'PANNOTAVBL' && isDocumentUploaded
                    ? 'margin-left:10rem; margin-top:-0.5rem;'
                    : 'margin-top:-0.5rem;'
                "
                class="md-layout"
                v-if="appStatus === 'DRAFT' || appStatus === 'REJECTED'"
              >
                <div class="md-layout-item md-small-size-100 md-size-45">
                  <md-button
                    v-if="!vendorAdditionalData.length"
                    class="md-raised md-rose search-btn"
                    @click="openDetails()"
                    >Add Details</md-button
                  >
                </div>
                <div class="md-layout-item md-small-size-100 md-size-15">
                  <md-button
                    style="margin-left:1.5rem;"
                    class="md-raised md-rose search-btn"
                    @click="isAllDtlsAvl()"
                    >Submit</md-button
                  >
                </div>
              </div>
            </div>
            <md-card-content>
              <div class="md-layout-item">
                <b-table
                  striped
                  hover
                  outlined
                  sticky-header
                  :items="vendorAdditionalData"
                  :fields="vendorAdditionalFields"
                  show-empty
                  class="bg-white"
                  @row-clicked="rowSelectedAddDetails"
                >
                  <template #cell(add_info_value)="data">
                    <span
                      v-if="
                        data.item.add_info_vset === 'MSME' ||
                          data.item.add_info_vset === 'EINVOICE'
                      "
                    >
                      {{
                        data.item.add_info_value === "true" ? "Yes" : "No"
                      }}</span
                    >
                    <span v-else> {{ data.item.add_info_value }}</span>
                  </template>
                  <template #cell(original_file_name)="data">
                    <a
                      href="#"
                      style="cursor:pointer;"
                      @click="
                        getOpenKmAuthenticate(
                          data.item.document_det_id,
                          data.item.original_file_name
                        )
                      "
                      >{{ data.item.original_file_name }}</a
                    >
                  </template>
                  <template #cell(file_name)="data">
                    <a
                      href="#"
                      style="cursor:pointer;"
                      @click="
                        getOpenKmAuthenticatePreview(
                          data.item.document_det_id,
                          data.item.original_file_name,
                          data.index,
                          vendorAdditionalData
                        )
                      "
                      ><span
                        v-if="data.item.document_det_id"
                        class="material-icons"
                      >
                        preview
                      </span></a
                    >
                  </template>
                </b-table>
              </div>
            </md-card-content>
          </md-card>
        </md-tab>

        <md-tab
          id="tab-document"
          md-label="All Documents"
          @click="selectedTab('document')"
        >
          <md-card>
            <!-- <div
            style="margin-left:62rem;"
            class="md-layout"
            v-if="appStatus === 'DRAFT'"
          >
            <div class="md-layout-item md-small-size-100 md-size-10">
              <md-button
                class="md-raised md-rose search-btn"
                @click="openDocument()"
                >Add Document</md-button
              >
            </div>
            <div
              class="md-layout-item md-small-size-100 md-size-15"
              v-if="
                vendorAddressData.length &&
                  vendorContactData.length &&
                  vendorBankData.length &&
                  vendorComplianceData.length
              "
            >
              <md-button
                style="margin-left:9rem;"
                class="md-raised md-rose search-btn"
                @click="isAllDocUploaded()"
                >Submit</md-button
              >
            </div>
          </div> -->
            <!-- <md-card-header
            class="md-card-header-text md-layout-item md-card-header-blue"
            :style="
              appStatus === 'DRAFT' ? 'margin-top: -30px' : 'margin-top: 7px'
            "
          >
            <div
              class="text-center card-text profile-card"
              style="height: 30px; width: 160px; padding: 3px !important"
            >
              <h5 class="title">Uploaded Document</h5>
            </div>
          </md-card-header> -->
            <div class="md-layout" style="margin-top: 10px; margin-left:-2rem;">
              <md-card-header class="md-card-header-text md-card-header-rose">
                <!-- <div class="card-icon vendor-icon">
                <md-icon style="font-size: 30px !important"
                  >perm_identity</md-icon
                >
              </div> -->
              </md-card-header>
              <div class="md-layout-item md-small-size-100 md-size-20">
                <md-field>
                  <label for="font">Name</label>
                  <md-input
                    v-model="vendorName"
                    :title="vendorName"
                    disabled
                  ></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-10">
                <md-field>
                  <label for="font">PAN No.</label>
                  <md-input
                    v-model="vendorPan"
                    :title="vendorPan"
                    disabled
                  ></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-15">
                <md-field>
                  <label for="font">Approval Status</label>
                  <md-input
                    v-model="appStatus"
                    :title="appStatus"
                    disabled
                  ></md-input>
                </md-field>
              </div>
              <div
                style="margin-left:-1.5rem; margin-top:-0.5rem;"
                class="md-layout-item md-small-size-100 md-size-15"
                v-if="vendorPan !== 'PANNOTAVBL' && !isDocumentUploaded"
              >
                <md-field md-clearable>
                  <label class="mandatory">Upload PAN</label>
                  <md-file
                    required
                    v-model="panDocument"
                    @md-change="selectFile"
                  />
                </md-field>
              </div>
              <div
                class="md-layout-item md-small-size-100 md-size-10"
                v-if="vendorPan !== 'PANNOTAVBL' && !isDocumentUploaded"
              >
                <md-button
                  v-if="panDocument"
                  class="md-raised md-rose search-btn"
                  @click="getOpenKmAuthenticateUpload()"
                  >Upload</md-button
                >
              </div>
              <div v-if="isDocumentUploaded">
                <b>PAN Document: </b><br />
                <span style="margin-top:1.8rem; cursor:pointer;">
                  <a
                    style="margin-left:0.5rem;"
                    target="_blank"
                    :href="
                      downloadDocUrl +
                        '/download/' +
                        docDetailId +
                        '?token=' +
                        openKmAuth
                    "
                    ><b>{{ fileName }}</b></a
                  >
                </span>
              </div>
              <div
                class="md-layout"
                v-if="appStatus === 'DRAFT' || appStatus === 'REJECTED'"
              >
                <div class="md-layout-item md-small-size-100 md-size-15">
                  <!-- v-if="
                    vendorAddressData.length &&
                      vendorContactData.length &&
                      vendorBankData.length &&
                      vendorComplianceData.length &&
                      vendorAdditionalData.length
                  " -->
                  <md-button
                    style="margin-left:9rem;"
                    class="md-raised md-rose search-btn"
                    @click="isAllDtlsAvl()"
                    >Submit</md-button
                  >
                </div>
              </div>
            </div>
            <md-card-content>
              <div class="md-layout-item">
                <b-table
                  striped
                  hover
                  outlined
                  sticky-header
                  :items="documentData"
                  :fields="documentFields"
                  show-empty
                  class="bg-white"
                >
                  <template #cell(original_file_name)="data">
                    <a
                      href="#"
                      style="cursor:pointer;"
                      @click="
                        getOpenKmAuthenticate(
                          data.item.document_det_id,
                          data.item.original_file_name
                        )
                      "
                      >{{ data.item.original_file_name }}</a
                    >
                  </template>
                  <template #cell(file_name)="data">
                    <a
                      href="#"
                      style="cursor:pointer;"
                      @click="
                        getOpenKmAuthenticatePreview(
                          data.item.document_det_id,
                          data.item.original_file_name,
                          data.index,
                          documentData
                        )
                      "
                      ><span
                        v-if="data.item.document_det_id"
                        class="material-icons"
                      >
                        preview
                      </span></a
                    >
                  </template>
                  <template
                    #cell(delete_document)="data"
                    v-if="appStatus === 'DRAFT' || appStatus === 'REJECTED'"
                  >
                    <span
                      class="material-icons"
                      style="cursor:pointer;"
                      @click="
                        getOpenKmAuthenticateDelete(data.item.document_det_id)
                      "
                    >
                      delete
                    </span>
                    <!-- <a
                    href="#"
                    style="cursor:pointer;"
                    @click="
                      getOpenKmAuthenticateDelete(data.item.document_det_id)
                    "
                  >
                    <span class="material-icons">
                      delete
                    </span>
                  </a> -->
                  </template>
                </b-table>
              </div>
            </md-card-content>
          </md-card>
        </md-tab>
      </md-tabs>
    </div>
    <b-modal
      v-model="showAddressModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="lg"
      title="Add New Address"
      @hidden="showHideAddressModal(false)"
    >
      <!-- <template #modal-header>
        <span style="color: white;">Add New Address</span>
        <span
          style="color:white; cursor:pointer; margin-left:33rem"
          class="material-icons"
          @click="editFunction()"
        >
          edit
        </span>
        <span style="color:white; cursor:pointer;" class="material-icons">
          done
        </span>
        <span
          style="color:white; cursor:pointer;"
          class="material-icons"
          @click="showHideAddressModal(false)"
        >
          close
        </span>
      </template> -->
      <PartyOnboardingAddress
        :addressRowDetails="addressRowDetails"
        :approvalStatus="appStatus"
        :vendorPan="vendorPan"
        @updatedList="getVendorAddressDetails()"
      />
    </b-modal>
    <b-modal
      v-model="showContactModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="lg"
      @hidden="showHideContactModal(false)"
      title="Contact Details for Correspondence"
      ><PartyOnboardingContact
        :contactRowDetails="contactRowDetails"
        :approvalStatus="appStatus"
        @updatedList="getVendorContactDetails()"
      />
    </b-modal>
    <b-modal
      v-model="showBankModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="lg"
      @hidden="showHideBankModal(false)"
      title="Add New Bank"
      ><PartyOnboardingBank
        :bankRowDetails="bankRowDetails"
        :approvalStatus="appStatus"
        @updatedList="getVendorBankDetails()"
      />
    </b-modal>
    <b-modal
      v-model="showComplianceModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="wide-modal"
      @hidden="showHideComplianceModal(false)"
      title="Add New Compliance"
      ><PartyOnboardingCompliance
        :complianceRowDetails="complianceRowDetails"
        :approvalStatus="appStatus"
        @updatedList="getVendorComplianceDetails()"
      />
    </b-modal>
    <b-modal
      v-model="showAdditionalDetailsModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="wide-modal"
      @hidden="showHideAdditionalDetailsModal(false)"
      title="Add New Additional Details"
      ><PartyOnboardingAddDetails
        :additionalRowDetails="additionalRowDetails"
        :approvalStatus="appStatus"
        @updatedList="getAdditionalDetailsById()"
      />
    </b-modal>
    <b-modal
      v-model="showDocumentModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="wide-modal"
      @hidden="showHideDocumentModal(false)"
      title="Add Document"
      ><PartyOnboardingDocument
        :documentRowDetails="documentRowDetails"
        :approvalStatus="appStatus"
        @updatedList="getVendorDocumentDetails()"
      />
    </b-modal>
    <!--  -->

    <b-modal
      v-model="showSubmitModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="xl"
      @hidden="showHideSubmitModal(false)"
      title="Send For Approval"
    >
      <b-alert
        style="width:60%;"
        :variant="isSuccess ? 'primary' : 'danger'"
        :show="showAlert"
        dismissible
        fade
        @dismissed="showAlert = false"
        >{{ responseMsg }}
      </b-alert>
      <md-field>
        <md-textarea
          class="bold-text"
          style="margin-top:-1rem; height:120rem;"
          v-model="termsCondition"
          disabled
        ></md-textarea>
      </md-field>
      <label for="disclosure-heading">Disclosure(s):</label><br />
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-85">
          <!-- <label style="font-size:13px;" for="disclosure-1"
            >1. Any director or any immediate relative of any of the directors
            of your company are not directors in any other firm already
            registered and working as a vendor/ supplier with Delhivery. If yes,
            then please disclose details.</label
          > -->
          <label style="font-size:13px;" for="disclosure-1">{{
            clauseText1
          }}</label>
        </div>
        <div
          style="margin-top:-1%;"
          class="md-layout-item md-small-size-100 md-size-10"
        >
          <md-switch @change="clearClause1()" v-model="clause1"
            ><span style="color:black;"
              ><b> {{ clause1 ? "Yes" : "No" }}</b></span
            ></md-switch
          >
        </div>
        <md-field v-if="clause1">
          <label>Reason.</label>
          <md-input v-model="clauseReason1"></md-input>
        </md-field>
        <!-- <div
          style="margin-left:-3%;"
          class="md-layout-item md-small-size-100 md-size-13"
        >
          <b-form-radio>Yes</b-form-radio>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-7">
          <b-form-radio>No</b-form-radio>
        </div> -->
        <br />
        <div class="md-layout-item md-small-size-100 md-size-85">
          <label style="font-size:13px;" for="disclosure-2">
            {{ clauseText2 }}</label
          >
        </div>
        <div
          style="margin-top:-1%;"
          class="md-layout-item md-small-size-100 md-size-10"
        >
          <md-switch @change="clearClause2()" v-model="clause2"
            ><span style="color:black;"
              ><b> {{ clause2 ? "Yes" : "No" }}</b></span
            ></md-switch
          >
        </div>
        <md-field v-if="clause2">
          <label>Reason.</label>
          <md-input v-model="clauseReason2"></md-input>
        </md-field>
        <br />
        <div class="md-layout-item md-small-size-100 md-size-85">
          <label style="font-size:13px;" for="disclosure-3">
            {{ clauseText3 }}</label
          >
        </div>
        <div
          style="margin-top:-1%;"
          class="md-layout-item md-small-size-100 md-size-10"
        >
          <md-switch @change="clearClause3()" v-model="clause3"
            ><span style="color:black;"
              ><b> {{ clause3 ? "Yes" : "No" }}</b></span
            ></md-switch
          >
        </div>
        <md-field v-if="clause3">
          <label>Reason.</label>
          <md-input v-model="clauseReason3"></md-input>
        </md-field>
      </div>
      <div style="margin-left:35%;">
        <md-checkbox style="margin-top:1rem;" v-model="sendForApproval"
          >I agree to the <a>terms and conditions</a>.</md-checkbox
        >
        <div class="md-layout-item md-small-size-100 md-size-15">
          <md-button
            class="md-raised md-rose search-btn"
            :disabled="isApprovalSent"
            @click="sendApproval()"
            >Send for Approval</md-button
          >
        </div>
      </div>
    </b-modal>
    <!--  -->
    <b-modal
      v-model="showReviewModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="wide-modal"
      @hidden="showHideReviewModal(false)"
      title="Final Review"
    >
      <PartyOnboardingReview />
    </b-modal>
    <b-modal
      v-model="showOpenModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="xl"
      @hidden="showHideOpenModal(false)"
      title="Preview"
    >
      <previewDoc
        :authToken="authToken"
        :docDtlId="docDtlId"
        :uploadedFileName="uploadedFileName"
        :currentPageIndex="currentPageIndex"
        :data="dynamicData"
      ></previewDoc>
    </b-modal>
  </div>
</template>
<script>
import commonHelper from "../../../utility/common.helper.utility";
import URL_UTILITY from "../../../utility/url.utility";
import appStrings from "../../../utility/string.utility";
import PartyOnboardingAddress from "./PartyOnboardingAddress.vue";
import PartyOnboardingContact from "./PartyOnboardingContact.vue";
import PartyOnboardingBank from "./PartyOnboardingBank.vue";
import PartyOnboardingCompliance from "./PartyOnboardingCompliance.vue";
import PartyOnboardingAddDetails from "./PartyOnboardingAddDetails.vue";
import PartyOnboardingDocument from "./PartyOnboardingDocument.vue";
import PartyOnboardingReview from "./PartyOnboardingReview.vue";
import previewDoc from "../PreviewDoc/PreviewDoc.vue";
export default {
  components: {
    PartyOnboardingAddress,
    PartyOnboardingContact,
    PartyOnboardingBank,
    PartyOnboardingCompliance,
    PartyOnboardingAddDetails,
    PartyOnboardingDocument,
    PartyOnboardingReview,
    previewDoc,
  },
  name: "PartyOnboarding",
  watch: {},
  data() {
    return {
      clauseText1: null,
      clauseText2: null,
      clauseText3: null,
      clause1: false,
      clause2: false,
      clause3: false,
      clauseReason1: null,
      clauseReason2: null,
      clauseReason3: null,
      active: false,
      isoActive: false,
      vendorName: null,
      vendorPan: null,
      appStatus: null,
      einvoice: false,
      vendorId: null,
      partyReqId: this.$store.state.auth.partyId,
      showAlert: false,
      loader: false,
      isSuccess: false,
      responseMsg: "",
      editMode: false,
      currentPageIndex: null,
      dynamicData: null,
      vendorAddressData: [],
      vendorAddressField: [
        {
          key: "address_type_lookup_meaning",
          label: "Address Type",
        },
        {
          key: "address",
        },
        {
          key: "gstn",
          label: "GSTN",
        },
        {
          key: "gstn_not_avail",
          label: "GSTN Not Available",
          class: "tla-col",
        },
        {
          key: "sez",
          label: "SEZ",
        },
        {
          key: "section",
          label: "TDS Section",
        },
        {
          key: "original_file_name",
          label: "File Name",
        },
        {
          key: "file_name",
          label: "Preview",
        },
        {
          key: "delete",
        },
      ],
      showAddressModal: false,
      addressRowDetails: null,
      vendorContactData: [],
      vendorContactField: [
        {
          key: "name",
        },
        {
          key: "mob",
          label: "Mobile",
        },
        {
          key: "email",
        },
        {
          key: "delete",
        },
      ],
      showContactModal: false,
      contactRowDetails: null,
      vendorBankData: [],
      vendorBankFields: [
        {
          key: "bank_code",
          label: "Bank Name",
        },
        {
          key: "branch_name",
        },
        {
          key: "account_num",
          label: "Account Number",
        },
        {
          key: "ifsc",
          label: "IFSC",
        },
        {
          key: "beneficiary_name",
        },
        {
          key: "original_file_name",
          label: "File Name",
        },
        {
          key: "file_name",
          label: "Preview",
        },
        {
          key: "delete",
        },
      ],
      showBankModal: false,
      bankRowDetails: null,
      vendorComplianceData: [],
      vendorComplianceFields: [
        {
          key: "serial_no",
          label: "Serial No.",
        },
        {
          key: "iso_name",
          label: "Certificate Name",
        },
        {
          key: "is_applicable",
        },
        {
          key: "expiry_date",
        },
        {
          key: "original_file_name",
          label: "File Name",
        },
        {
          key: "file_name",
          label: "Preview",
        },
      ],
      showAdditionalDetailsModal: false,
      additionalRowDetails: null,
      vendorAdditionalData: [],
      vendorAdditionalFields: [
        {
          key: "add_info_desc_meaning",
          label: "Additional Info",
        },
        {
          key: "add_info_value",
          label: "Additional Value",
        },
        // {
        //   key: "certificate_no",
        // },
        {
          key: "original_file_name",
          label: "File Name",
        },
        {
          key: "file_name",
          label: "Preview",
        },
      ],
      showComplianceModal: false,
      complianceRowDetails: null,
      documentData: [],
      documentFields: [
        {
          key: "category",
        },
        {
          key: "original_file_name",
          label: "File Name",
        },
        {
          key: "file_name",
          label: "Preview",
        },
        {
          key: "delete_document",
        },
        {
          key: "file_size",
        },
      ],
      showDocumentModal: false,
      documentRowDetails: null,
      activeTab: "address",
      showSubmitModal: false,
      termsCondition: null,
      sendForApproval: false,
      approvalDetails: [],
      isApprovalSent: false,
      downloadDocUrl: URL_UTILITY.getDocumentDownloadUrl,
      vendorDetails: {
        name: null,
        pan_no: null,
        approval_status: null,
        einvoice: null,
        ora_party_id: null,
      },
      menuId: 374,
      submenuId: 82,
      panDocument: null,
      isDocumentUploaded: false,
      openKmAuth: null,
      fileRef: null,
      docDetailId: null,
      fileName: null,
      showReviewModal: false,
      termId: null,
      uploadedFileName: null,
      showOpenModal: false,
      docDtlId: null,
      authToken: null,
    };
  },
  mounted() {
    this.getVendorDetails();
    this.getVendorAddressDetails();
    this.getVendorContactDetails();
    this.getVendorBankDetails();
    this.getVendorComplianceDetails();
    this.getAdditionalDetailsById();
    this.getVendorDocumentDetails();
  },
  methods: {
    deleteAddressRecord(addressId) {
      const promt = confirm(
        "Are you sure, you want to delete this Address Record?"
      );
      if (promt) {
        const payload = {
          addressId: addressId,
        };
        this.$store
          .dispatch("vendor/deleteAddressRecord", payload)
          .then((response) => {
            if (response.status === 200) {
              alert("Record Deleted Successfully");
              this.getVendorAddressDetails();
            } else {
              alert(response.response.data.message || "Something went wrong");
            }
          })
          .catch(() => {});
      }
    },
    deleteContactRecord(contactId) {
      const promt = confirm(
        "Are you sure, you want to delete this Contact Record?"
      );
      if (promt) {
        const payload = {
          contactId: contactId,
        };
        this.$store
          .dispatch("vendor/deleteContactRecord", payload)
          .then((response) => {
            if (response.status === 200) {
              alert("Record Deleted Successfully");
              this.getVendorContactDetails();
            } else {
              alert(response.response.data.message || "Something went wrong");
            }
          })
          .catch(() => {});
      }
    },
    deleteBankRecord(bankId) {
      const promt = confirm(
        "Are you sure, you want to delete this Bank Record?"
      );
      if (promt) {
        const payload = {
          bankId: bankId,
        };
        this.$store
          .dispatch("vendor/deleteBankRecord", payload)
          .then((response) => {
            if (response.status === 200) {
              alert("Record Deleted Successfully");
              this.getVendorBankDetails();
            } else {
              alert(response.response.data.message || "Something went wrong");
            }
          })
          .catch(() => {});
      }
    },
    deleteDocument(docDetailId, token) {
      const promt = confirm("Are you sure, you want to delete this document?");
      if (promt) {
        const payload = {
          docDetailId: docDetailId,
          token: token,
        };
        this.$store
          .dispatch("vendor/deleteOpenKmDoc", payload)
          .then((response) => {
            if (response.status === 200) {
              alert("Document Deleted Successfully");
              this.getVendorDetails();
              this.getVendorDocumentDetails();
            } else {
              alert(response.response.data.message || "Something went wrong");
            }
          })
          .catch(() => {});
      }
    },
    // temp function for document preview need to change
    getOpenKmAuthenticatePreview(docDtlId, fileName, index, data) {
      const filterDynamicData = data.filter((a) => a.original_file_name);
      const getFileName = data[index].original_file_name;
      for (let i = 0; i < filterDynamicData.length; i++) {
        if (filterDynamicData[i].original_file_name == getFileName) {
          this.currentPageIndex = i + 1;
          break;
        }
      }
      this.dynamicData = filterDynamicData;
      const extension = commonHelper.getFileExtension(fileName);
      if (extension === "zip") {
        alert("Preview Not Supported this file.");
      } else {
        this.$store.dispatch("shared/setLoader", true);
        this.$store
          .dispatch("uploadDocument/getOpenKmAuthenticate")
          .then((response) => {
            this.$store.dispatch("shared/setLoader", false);
            if (response.status === 200) {
              this.openKmAuth = response.data.data.token;
              if (this.openKmAuth) {
                this.showHideOpenModal(
                  true,
                  docDtlId,
                  this.openKmAuth,
                  fileName
                );
              }
            }
          })
          .catch(() => {
            this.$store.dispatch("shared/setLoader", false);
          });
      }
    },

    getOpenKmAuthenticateDelete(docDetailId) {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("uploadDocument/getOpenKmAuthenticate")
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            if (this.openKmAuth) {
              this.deleteDocument(docDetailId, this.openKmAuth);
            }
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },

    showHideOpenModal(flag, docDtlId, authToken, fileName) {
      if (flag) {
        this.fileExtension = commonHelper.getFileExtension(fileName);
      }
      if (this.fileExtension === "xlsx") {
        alert("Not Allowed");
      } else {
        this.showOpenModal = flag;
        this.docDtlId = docDtlId;
        this.authToken = authToken;
        this.uploadedFileName = fileName;
      }
    },
    getTermsCondition() {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getTermsCondition")
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            const result = resp.data.data;
            this.termId = result.term_id;
            this.termsCondition = result.terms;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    addEditTermsCondition() {
      const payload = {
        term_id: this.termId,
        party_req_id: this.partyReqId,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/addEditTermsCondition", payload)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = response.response.data.message;
          // this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    showHideReviewModal(flag) {
      this.showReviewModal = flag;
    },
    isAllDtlsAvl() {
      if (
        this.vendorAddressData.length &&
        this.vendorContactData.length &&
        this.vendorBankData.length &&
        this.vendorComplianceData.length &&
        this.vendorAdditionalData.length
      ) {
        this.isAllDocUploaded();
      } else {
        let messageAlert = "";
        this.vendorAddressData.length
          ? messageAlert
          : (messageAlert += "Please Enter Address Details");
        this.vendorContactData.length
          ? messageAlert
          : messageAlert.length
          ? (messageAlert += "," + " Contact Details")
          : (messageAlert += "Please Enter Contact Details");
        this.vendorBankData.length
          ? messageAlert
          : messageAlert.length
          ? (messageAlert += "," + " Bank Details")
          : (messageAlert += "Please Enter Bank Details");
        this.vendorComplianceData.length
          ? messageAlert
          : messageAlert.length
          ? (messageAlert += "," + " Compliance Details")
          : (messageAlert += "Please Enter Compliance Details");
        this.vendorAdditionalData.length
          ? messageAlert
          : messageAlert.length
          ? (messageAlert += "and" + " Financial Details")
          : (messageAlert += "Please Enter Financial Details");
        messageAlert.length ? alert(messageAlert) : "";
      }
    },
    isAllDocUploaded() {
      const isAddressDocAvail = this.vendorAddressData.filter(
        (elem) => elem.gstn !== "GSTNOTAVAILABLE" && !elem.document_det_id
      );
      const isBankDocAvail = this.vendorBankData.filter(
        (elem) => !elem.document_det_id
      );
      const isComplianceDocAvail = this.vendorComplianceData.filter(
        (elem) => elem.is_applicable == "YES" && !elem.document_det_id
      );
      const isAddDetailsDocAvail = this.vendorAdditionalData.filter(
        (elem) =>
          elem.gl_calendar_dtl_id &&
          elem.add_info_value !== "0" &&
          !elem.document_det_id
      );
      if (this.vendorPan !== "PANNOTAVBL") {
        if (this.docDetailId && this.fileName) {
          if (
            isAddressDocAvail.length ||
            isBankDocAvail.length ||
            isComplianceDocAvail.length ||
            isAddDetailsDocAvail.length
          ) {
            if (
              isAddressDocAvail.length &&
              isBankDocAvail.length &&
              isComplianceDocAvail.length &&
              isAddDetailsDocAvail.length
            ) {
              alert(
                "Please Upload GST Certificate, Cancelled Cheque, Compliance Certificate and Financial Year Certificate"
              );
            } else if (
              isAddressDocAvail.length &&
              isBankDocAvail.length &&
              isComplianceDocAvail.length
            ) {
              alert(
                "Please Upload GST Certificate, Cancelled Cheque and Compliance Certificate"
              );
            } else if (
              isAddressDocAvail.length &&
              isBankDocAvail.length &&
              isAddDetailsDocAvail.length
            ) {
              alert(
                "Please Upload GST Certificate, Cancelled Cheque and Financial Year Certificate"
              );
            } else if (
              isAddressDocAvail.length &&
              isComplianceDocAvail.length &&
              isAddDetailsDocAvail.length
            ) {
              alert(
                "Please Upload GST Certificate, Compliance Certificate and Financial Year Certificate"
              );
            } else if (
              isBankDocAvail.length &&
              isComplianceDocAvail.length &&
              isAddDetailsDocAvail.length
            ) {
              alert(
                "Please Upload Cancelled Cheque, Compliance Certificate and Financial Year Certificate"
              );
            } else if (isAddressDocAvail.length && isBankDocAvail.length) {
              alert("Please Upload GST Certificate and Cancelled Cheque");
            } else if (
              isAddressDocAvail.length &&
              isComplianceDocAvail.length
            ) {
              alert("Please Upload GST Certificate, Compliance Certificate");
            } else if (
              isAddressDocAvail.length &&
              isAddDetailsDocAvail.length
            ) {
              alert(
                "Please Upload GST Certificate, Financial Year Certificate"
              );
            } else if (isBankDocAvail.length && isComplianceDocAvail.length) {
              alert(
                "Please Upload Cancelled Cheque and Compliance Certificate"
              );
            } else if (isBankDocAvail.length && isAddDetailsDocAvail.length) {
              alert(
                "Please Upload Cancelled Cheque and Financial Year Certificate"
              );
            } else if (
              isComplianceDocAvail.length &&
              isAddDetailsDocAvail.length
            ) {
              alert(
                "Please Upload Compliance Certificate and Financial Year Certificate"
              );
            } else if (isAddressDocAvail.length) {
              alert("Please Upload GST Certificate");
            } else if (isBankDocAvail.length) {
              alert("Please Upload Cancelled Cheque");
            } else if (isComplianceDocAvail.length) {
              alert("Please Upload Compliance Certificate");
            } else if (isAddDetailsDocAvail.length) {
              alert("Please Upload Financial Year Certificate");
            }
          } else if (
            !isAddressDocAvail.length &&
            !isBankDocAvail.length &&
            !isComplianceDocAvail.length &&
            !isAddDetailsDocAvail.length
          ) {
            this.showHideSubmitModal(true);
          }
        } else {
          alert("PAN Document is Mandatory");
        }
      } else if (this.vendorPan === "PANNOTAVBL") {
        if (
          isAddressDocAvail.length ||
          isBankDocAvail.length ||
          isComplianceDocAvail.length ||
          isAddDetailsDocAvail.length
        ) {
          if (
            isAddressDocAvail.length &&
            isBankDocAvail.length &&
            isComplianceDocAvail.length &&
            isAddDetailsDocAvail.length
          ) {
            alert(
              "Please Upload GST Certificate, Cancelled Cheque, Compliance Certificate and Financial Year Certificate"
            );
          } else if (
            isAddressDocAvail.length &&
            isBankDocAvail.length &&
            isComplianceDocAvail.length
          ) {
            alert(
              "Please Upload GST Certificate, Cancelled Cheque and Compliance Certificate"
            );
          } else if (
            isAddressDocAvail.length &&
            isBankDocAvail.length &&
            isAddDetailsDocAvail.length
          ) {
            alert(
              "Please Upload GST Certificate, Cancelled Cheque and Financial Year Certificate"
            );
          } else if (
            isAddressDocAvail.length &&
            isComplianceDocAvail.length &&
            isAddDetailsDocAvail.length
          ) {
            alert(
              "Please Upload GST Certificate, Compliance Certificate and Financial Year Certificate"
            );
          } else if (
            isBankDocAvail.length &&
            isComplianceDocAvail.length &&
            isAddDetailsDocAvail.length
          ) {
            alert(
              "Please Upload Cancelled Cheque, Compliance Certificate and Financial Year Certificate"
            );
          } else if (isAddressDocAvail.length && isBankDocAvail.length) {
            alert("Please Upload GST Certificate and Cancelled Cheque");
          } else if (isAddressDocAvail.length && isComplianceDocAvail.length) {
            alert("Please Upload GST Certificate, Compliance Certificate");
          } else if (isAddressDocAvail.length && isAddDetailsDocAvail.length) {
            alert("Please Upload GST Certificate, Financial Year Certificate");
          } else if (isBankDocAvail.length && isComplianceDocAvail.length) {
            alert("Please Upload Cancelled Cheque and Compliance Certificate");
          } else if (isBankDocAvail.length && isAddDetailsDocAvail.length) {
            alert(
              "Please Upload Cancelled Cheque and Financial Year Certificate"
            );
          } else if (
            isComplianceDocAvail.length &&
            isAddDetailsDocAvail.length
          ) {
            alert(
              "Please Upload Compliance Certificate and Financial Year Certificate"
            );
          } else if (isAddressDocAvail.length) {
            alert("Please Upload GST Certificate");
          } else if (isBankDocAvail.length) {
            alert("Please Upload Cancelled Cheque");
          } else if (isComplianceDocAvail.length) {
            alert("Please Upload Compliance Certificate");
          } else if (isAddDetailsDocAvail.length) {
            alert("Please Upload Financial Year Certificate");
          }
        } else if (
          !isAddressDocAvail.length &&
          !isBankDocAvail.length &&
          !isComplianceDocAvail.length &&
          !isAddDetailsDocAvail.length
        ) {
          this.showHideSubmitModal(true);
        }
      }
    },
    selectFile(event) {
      if (event[0].size <= 5242880) {
        this.fileRef = event;
      } else {
        this.fileRef = null;
        alert(event[0].name + " file should be less than 5 MB.");
        // this.showAlert = true;
        // this.isSuccess = false;
        // this.responseMsg = event[0].name + " file should be less than 5 MB.";
      }
    },
    getOpenKmAuthenticate(docDtlId, fileName) {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("uploadDocument/getOpenKmAuthenticate")
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            const url =
              this.downloadDocUrl +
              "/download/" +
              docDtlId +
              "?token=" +
              this.openKmAuth;
            commonHelper.downloadFile(url, fileName);
            // this.downloadFunction(docDtlId, fileName);
            // window.location.href =
            //   this.downloadDocUrl +
            //   "/download/" +
            //   docDtlId +
            //   "?token=" +
            //   this.openKmAuth;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },

    getOpenKmAuthenticateUpload() {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("uploadDocument/getOpenKmAuthenticate")
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            if (!this.isDocumentUploaded) {
              this.uploadFile();
            }
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    uploadFile() {
      if (this.fileRef) {
        let formData = new FormData();
        formData.append("file", this.fileRef.item(0));
        formData.append("token", this.openKmAuth);
        formData.append("doc_detail_id", 0);
        formData.append("menu_id", this.menuId);
        formData.append("sub_menu_id", this.submenuId);
        formData.append("record_id", this.partyReqId);
        this.loader = true;
        this.$store.dispatch("shared/setLoader", true);
        this.$store
          .dispatch("uploadDocument/uploadFile", formData)
          .then((response) => {
            this.loader = false;
            this.$store.dispatch("shared/setLoader", false);
            this.showAlert = true;
            if (response.status === 200) {
              alert(response.data.message);
              this.isDocumentUploaded = true;
              this.docDetailId = response.data.data.doc_detail_id;
              this.fileName = response.data.data.original_file_name;
              this.getVendorDocumentDetails();
              // this.$emit("updatedList");
            } else {
              alert(response.data.message || "Something went wrong");
            }
          })
          .catch(() => {
            this.loader = false;
            this.$store.dispatch("shared/setLoader", false);
          });
      } else {
        alert("Please select file less than 5 MB");
      }
    },

    addEditVendorDetails() {
      const payload = {
        einovice: this.einvoice,
        party_req_id: Number(this.partyReqId),
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/addEditVendorDetails", payload)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = response.response.data.message;
          // this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    sendApproval() {
      if (this.sendForApproval) {
        if (
          this.vendorAddressData.length > 0 &&
          this.vendorContactData.length > 0 &&
          this.vendorBankData.length > 0
        ) {
          if (!this.clause1 && !this.clause2 && !this.clause3) {
            this.addEditTermsCondition();
            this.sendWorkflowProcess();
          } else {
            if (this.clause1 && !this.clause2 && !this.clause3) {
              if (this.clauseReason1) {
                this.addEditTermsCondition();
                this.sendWorkflowProcess();
              } else {
                alert("Please enter reason for selected clause");
              }
            } else if (!this.clause1 && this.clause2 && !this.clause3) {
              if (this.clauseReason2) {
                this.addEditTermsCondition();
                this.sendWorkflowProcess();
              } else {
                alert("Please enter reason for selected clause");
              }
            } else if (!this.clause1 && !this.clause2 && this.clause3) {
              if (this.clauseReason3) {
                this.addEditTermsCondition();
                this.sendWorkflowProcess();
              } else {
                alert("Please enter reason for selected clause");
              }
            } else if (this.clause1 && this.clause2 && !this.clause3) {
              if (this.clauseReason1 && this.clauseReason2) {
                this.addEditTermsCondition();
                this.sendWorkflowProcess();
              } else {
                alert("Please enter reason for selected clause");
              }
            } else if (this.clause1 && !this.clause2 && this.clause3) {
              if (this.clauseReason1 && this.clauseReason3) {
                this.addEditTermsCondition();
                this.sendWorkflowProcess();
              } else {
                alert("Please enter reason for selected clause");
              }
            } else if (!this.clause1 && this.clause2 && this.clause3) {
              if (this.clauseReason2 && this.clauseReason3) {
                this.addEditTermsCondition();
                this.sendWorkflowProcess();
              } else {
                alert("Please enter reason for selected clause");
              }
            } else if (this.clause1 && this.clause2 && this.clause3) {
              if (
                this.clauseReason1 &&
                this.clauseReason2 &&
                this.clauseReason3
              ) {
                this.addEditTermsCondition();
                this.sendWorkflowProcess();
              } else {
                alert("Please enter reason for selected clause");
              }
            }
          }
        } else {
          alert("Record Pending");
          // alert("Please enter reason for selected clause");
        }
      } else {
        alert("Please accept terms and condition");
      }
    },
    sendWorkflowProcess() {
      const payload = {
        event_id: 32,
        record_id: this.partyReqId,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/sendWorkflowProcess", payload)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          this.showAlert = true;
          if (resp.status === 200) {
            this.isSuccess = true;
            this.responseMsg = resp.data.message;
            this.approvalDetails = resp.data.data;
            this.sendWorkflowApproval();
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = response.response.data.message;
          // this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    sendWorkflowApproval() {
      const payload = {
        event_id: 32,
        record_id: this.partyReqId,
        approval_details: this.approvalDetails,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/sendWorkflowApproval", payload)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          this.showAlert = true;
          if (resp.status === 200) {
            this.isApprovalSent = true;
            this.isSuccess = true;
            this.responseMsg = resp.data.message;
            this.addEditVendorDisclosureDetails();
            this.getVendorDetails();
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = response.response.data.message;
          // this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    showHideSubmitModal(flag) {
      this.showSubmitModal = flag;
      if (flag) {
        this.showAlert = false;
        this.responseMsg = "";
        this.getTermsCondition();
        this.getVendorDisclosureDetails();
      }
    },
    getVendorDetails() {
      const payload = {
        party_req_id: this.$store.state.auth.partyId,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getVendorDetails", payload)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            const result = resp.data.data;
            this.vendorName = result.name;
            this.vendorPan = result.pan_no;
            this.appStatus = result.approval_status;
            this.docDetailId = result.document_det_id;
            this.fileName = result.original_file_name;
            if (result.document_det_id) {
              this.isDocumentUploaded = true;
              this.getOpenKmAuthenticateUpload();
            } else {
              this.fileName = null;
              this.docDetailId = null;
              this.isDocumentUploaded = false;
              this.panDocument = null;
            }
            // this.einvoice = result.einvoice;
            this.vendorId = result.ora_party_id;
            // this.$store.commit("vendor/setVendorId", result.ora_party_id);
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getVendorDisclosureDetails() {
      this.loader = true;
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getVendorDisclosureDetails")
        .then((response) => {
          this.loader = false;
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            const result = response.data.data[0];
            this.clauseText1 = result.disclosure_text1;
            this.clauseText2 = result.disclosure_text2;
            this.clauseText3 = result.disclosure_text3;
          }
        })
        .catch(() => {
          this.loader = false;
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getTermsConditionById() {
      const payload = {
        party_req_id: this.$store.state.auth.partyId,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getTermsConditionById", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditVendorDisclosureDetails() {
      const payload = {
        party_req_id: this.$store.state.auth.partyId,
        term_id: this.termId,
        disclosure_id: 0,
        disclosure_text1: this.clauseText1,
        disclosure_text2: this.clauseText2,
        disclosure_text3: this.clauseText3,
        disclosure_status1: this.clause1,
        disclosure_status2: this.clause2,
        disclosure_status3: this.clause3,
        disclosure_reason1: this.clauseReason1,
        disclosure_reason2: this.clauseReason2,
        disclosure_reason3: this.clauseReason3,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/addEditVendorDisclosureDetails", payload)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          this.showAlert = true;
          if (resp.status === 200) {
          } else {
            this.isSuccess = false;
            this.responseMsg = "Error in Terms & Condition.";
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = "Error in Terms & Condition.";
          // this.responseMsg = appStrings.autoFailedMsg;
        });
    },

    rowSelectedAddress(item) {
      this.addressRowDetails = item;
      this.showHideAddressModal(true, "row");
    },
    rowSelectedContact(item) {
      this.contactRowDetails = item;
      this.showHideContactModal(true);
    },
    rowSelectedBank(item) {
      this.bankRowDetails = item;
      this.showHideBankModal(true);
    },
    rowSelectedAddDetails(item) {
      this.additionalRowDetails = item;
      this.showHideAdditionalDetailsModal(true);
    },
    rowSelectedCompliance(item) {
      this.complianceRowDetails = item;
      this.showHideComplianceModal(true);
    },

    openAddress() {
      this.addressRowDetails = null;
      this.editMode = false;
      this.showHideAddressModal(true);
    },
    openContact() {
      this.contactRowDetails = null;
      this.editMode = false;
      this.showHideContactModal(true);
    },
    openBank() {
      this.bankRowDetails = null;
      this.editMode = false;
      this.showHideBankModal(true);
    },
    openCompliance() {
      this.complianceRowDetails = null;
      this.editMode = false;
      this.showHideComplianceModal(true);
    },
    openDetails() {
      this.additionalRowDetails = null;
      this.editMode = false;
      this.showHideAdditionalDetailsModal(true);
    },
    openDocument() {
      this.documentRowDetails = null;
      this.editMode = false;
      this.showHideDocumentModal(true);
    },

    showHideAddressModal(flag) {
      this.showAddressModal = flag;
    },
    showHideContactModal(flag) {
      this.showContactModal = flag;
    },
    showHideBankModal(flag) {
      this.showBankModal = flag;
    },
    showHideComplianceModal(flag) {
      this.showComplianceModal = flag;
    },
    showHideAdditionalDetailsModal(flag) {
      this.showAdditionalDetailsModal = flag;
    },
    showHideDocumentModal(flag) {
      this.showDocumentModal = flag;
    },
    clearClause1() {
      this.clauseReason1 = null;
    },
    clearClause2() {
      this.clauseReason2 = null;
    },
    clearClause3() {
      this.clauseReason3 = null;
    },

    getVendorAddressDetails() {
      const payload = {
        party_req_id: this.$store.state.auth.partyId,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getVendorAddressDetails", payload)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            const result = resp.data.data;
            this.vendorAddressData = result;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getVendorContactDetails() {
      const payload = {
        party_req_id: this.$store.state.auth.partyId,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getVendorContactDetails", payload)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            const result = resp.data.data;
            this.vendorContactData = result;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getVendorBankDetails() {
      const payload = {
        party_req_id: this.$store.state.auth.partyId,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getVendorBankDetails", payload)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            const result = resp.data.data;
            this.vendorBankData = result;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getVendorComplianceDetails() {
      const payload = {
        party_req_id: this.$store.state.auth.partyId,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getVendorComplianceDetails", payload)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            const result = resp.data.data;
            this.vendorComplianceData = result;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getAdditionalDetailsById() {
      const payload = {
        party_req_id: this.$store.state.auth.partyId,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getAdditionalDetailsById", payload)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            const result = resp.data.data;
            this.vendorAdditionalData = result;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getVendorDocumentDetails() {
      const payload = {
        party_req_id: this.$store.state.auth.partyId,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getVendorDocumentDetails", payload)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            const result = resp.data.data;
            this.documentData = result.map((data) => {
              data.file_size = this.formatBytes(data.file_size);
              return data;
            });
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },

    selectedTab(tabVal) {
      this.activeTab = tabVal;
      if (this.activeTab === "address") {
        this.getVendorAddressDetails();
      } else if (this.activeTab === "contactDetails") {
        this.getVendorContactDetails();
      } else if (this.activeTab === "bankDetails") {
        this.getVendorBankDetails();
      } else if (this.activeTab === "compliance") {
        this.getVendorComplianceDetails();
      } else if (this.activeTab === "addDetails") {
        this.getAdditionalDetailsById();
      } else if (this.activeTab === "document") {
        this.getVendorDocumentDetails();
      }
      this.getVendorDetails();
    },
    downloadFunction(docDtlId, fileName) {
      const downloadUrl =
        this.downloadDocUrl +
        "/download/" +
        docDtlId +
        "?token=" +
        this.openKmAuth;
      fetch(downloadUrl)
        .then((res) => res.blob())
        .then((data) => {
          var a = document.createElement("a");
          a.href = window.URL.createObjectURL(data);
          a.download = fileName;
          a.click();
        });
    },
  },
};
</script>
<style>
.card-icon {
  margin-right: 0px !important;
}
.card-text {
  padding: 0px !important;
}
.mla-col > div {
  width: 90px !important;
}
.tla-col > div {
  width: 170px !important;
}
.highlight {
  border: 3px solid greenyellow;
}
.tab-fix {
  border: 3px solid blue;
}
.bold-text {
  font-weight: bold;
}
</style>
