<template>
  <div>
    <div class="overlayLoader" v-if="loader">
      <md-progress-spinner
        :md-diameter="100"
        class="md-accent spinner-postion"
        :md-stroke="7"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <md-card>
          <div class="md-layout">
            <md-card-header
              class="md-card-header-text md-card-header-green search-icon"
            >
              <div class="card-icon vendor-icon">
                <md-icon style="font-size: 20px !important">search</md-icon>
              </div>
            </md-card-header>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <md-field>
                <label>Invoice Number</label>
                <md-input type="text" v-model="invNum"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <md-field>
                <label>Amount From</label>
                <md-input
                  v-numericOnly
                  type="text"
                  v-model="amountFrom"
                ></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <md-field>
                <label>Amount To</label>
                <md-input
                  v-numericOnly
                  type="text"
                  v-model="amountTo"
                ></md-input>
              </md-field>
            </div>
            <div
              class="md-layout-item md-small-size-100 md-size-14 DateFormate"
            >
              <date-picker
                valueType="format"
                format="DD-MMM-YYYY"
                placeholder="From Date"
                v-model="fromDate"
                @change="toDate = null"
              ></date-picker>
            </div>
            <div
              class="md-layout-item md-small-size-100 md-size-14 DateFormate"
            >
              <date-picker
                md-immediately
                valueType="format"
                format="DD-MMM-YYYY"
                placeholder="To Date"
                v-model="toDate"
                :disabled-date="disabledDates"
                :disabled="!fromDate"
              ></date-picker>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-22">
              <div style="margin-top: 5%">
                <md-button
                  class="md-raised md-success search-btn"
                  style="margin-right: 3%"
                  @click="getAdvanceReportData()"
                  >Search</md-button
                >
                <md-button
                  class="md-raised md-info search-btn"
                  style="margin-right: 3%"
                  @click="clearSearch()"
                  >Clear</md-button
                >
                <md-button
                  v-if="advanceReportData.length"
                  style="height:28px;"
                  class="md-warning md-just-icon"
                  @click="downloadCustomExcel()"
                  title="Download Excel"
                  ><md-icon>file_download</md-icon></md-button
                >
              </div>
            </div>
          </div>
        </md-card>
      </div>

      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <md-card>
          <div class="md-layout-item">
            <b-table
              striped
              hover
              outlined
              sticky-header
              :items="advanceReportData"
              :fields="advanceReportFields"
              show-empty
              class="bg-white"
              style="max-height:calc(100vh - 230px)"
              @row-clicked="rowSelected"
            >
              <!-- <template #head(invoice_amount)="">
                <span
                  style="text-align:center !important; margin-right: 28% !important;"
                >
                  Amount
                </span>
              </template> -->
              <template #cell(invoice_amount)="data">
                <span
                  class="text-fix-adv"
                  style="text-align:centre !important;"
                >
                  {{
                    data.item.invoice_amount !== 0
                      ? formattedCurrency(data.item.invoice_amount)
                      : data.item.invoice_amount
                  }}</span
                >
              </template>
              <template #cell(amount_paid)="data">
                <span class="text-fix-adv">
                  {{
                    data.item.amount_paid !== 0
                      ? formattedCurrency(data.item.amount_paid)
                      : data.item.amount_paid
                  }}</span
                >
              </template>
              <template #cell(amount_adjusted)="data">
                <span class="text-fix-adv">
                  {{
                    data.item.amount_adjusted !== 0
                      ? formattedCurrency(data.item.amount_adjusted)
                      : data.item.amount_adjusted
                  }}</span
                >
              </template>
              <template #cell(amount_unadjusted)="data">
                <span class="text-fix-adv-un">
                  {{
                    data.item.amount_unadjusted !== 0
                      ? formattedCurrency(data.item.amount_unadjusted)
                      : data.item.amount_unadjusted
                  }}</span
                >
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
            <div
              class="pagination-count"
              v-if="advanceReportData.length && totalRows <= perPage"
            >
              <p>
                Showing
                {{
                  totalRows == 0 ? totalRows : perPage * (currentPage - 1) + 1
                }}
                to {{ totalRows }} of {{ totalRows }} entries
              </p>
            </div>
            <div class="pagination-count" v-else-if="advanceReportData.length">
              <p>
                Showing {{ perPage * (currentPage - 1) + 1 }} to
                {{
                  perPage * currentPage >= totalRows
                    ? totalRows
                    : perPage * currentPage
                }}
                of {{ totalRows }}
                entries
              </p>
            </div>
          </div>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import commonHelper from "../../../../src/utility/common.helper.utility";
import DatePicker from "vue2-datepicker";
export default {
  components: {
    DatePicker,
  },
  name: "AdvanceReport",
  watch: {
    currentPage: function() {
      this.getAdvanceReportData();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getAdvanceReportData();
    },
  },
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      loader: false,
      advanceReportData: [],
      advanceReportFields: [
        {
          key: "legal_entity",
        },
        {
          key: "invoice_num",
          label: "Invoice Number",
        },
        {
          key: "invoice_amount",
          class: "text-fix-adv",
        },
        {
          key: "amount_paid",
          class: "text-fix-adv",
        },
        {
          key: "amount_adjusted",
          label: "Amt. Adjusted",
          class: "text-fix-adv",
        },
        {
          key: "amount_unadjusted",
          label: "Amt. Unadjusted",
          class: "text-fix-adv-un",
        },
        {
          key: "invoice_currency_code",
          label: "Currency",
        },
        {
          key: "tds",
          label: "TDS",
        },
        {
          key: "invoice_date",
        },
      ],
      fromDate: null,
      toDate: null,
      invNum: null,
      amountFrom: null,
      amountTo: null,
      vendorId: null,
      totalPayload: null,
    };
  },
  mounted() {
    this.vendorId = JSON.parse(sessionStorage.getItem("VendorId"));
    if (this.vendorId) {
      this.getAdvanceReportData();
    }
  },
  methods: {
    disabledDates(date) {
      return commonHelper.disabledDates(date, this.fromDate);
    },
    formattedCurrency(num) {
      return commonHelper.formatAmount(num);
    },
    getAdvanceReportData() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        vendor_id: this.vendorId,
        invoice_num: this.invNum,
        invoice_amt_from: this.amountFrom,
        invoice_date_to: this.amountTo,
        invoice_date_from: this.fromDate,
        invoice_date_to: this.toDate,
      };
      this.totalPayload = payload;
      this.loader = true;
      this.$store
        .dispatch("vendor/getAdvanceReportDtls", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.advanceReportData = result.map((elem) => {
              elem.invoice_date = commonHelper.formatDate(elem.invoice_date);
              return elem;
            });
            this.totalRows = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    downloadCustomExcel() {
      this.totalPayload._limit = this.totalRows;
      this.loader = true;
      this.$store
        .dispatch("vendor/getAdvanceReportDtls", this.totalPayload)
        .then((resp) => {
          this.loader = false;
          const result = resp.data.data.data.page.map((elem) => {
            elem.invoice_date = commonHelper.formatDate(elem.invoice_date);
            elem.invoice_amount =
              elem.invoice_amount !== 0
                ? commonHelper.formatAmount(elem.invoice_amount)
                : elem.invoice_amount;
            elem.amount_paid =
              elem.amount_paid !== 0
                ? commonHelper.formatAmount(elem.amount_paid)
                : elem.amount_paid;
            elem.amount_adjusted =
              elem.amount_adjusted !== 0
                ? commonHelper.formatAmount(elem.amount_adjusted)
                : elem.amount_adjusted;
            elem.amount_unadjusted =
              elem.amount_unadjusted !== 0
                ? commonHelper.formatAmount(elem.amount_unadjusted)
                : elem.amount_unadjusted;
            return elem;
          });
          const customDownloadData = result.map((obj) => ({
            legal_entity: obj.legal_entity,
            invoice_number: obj.invoice_num,
            invoice_amount: obj.invoice_amount,
            amount_paid: obj.amount_paid,
            amount_adjusted: obj.amount_adjusted,
            amount_unadjusted: obj.amount_unadjusted,
            currency: obj.invoice_currency_code,
            tds: obj.tds,
            invoice_date: obj.invoice_date,
          }));
          if (customDownloadData) {
            commonHelper.downloadExcel(customDownloadData, "advance-report");
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clearSearch() {
      this.invNum = null;
      this.fromDate = null;
      this.toDate = null;
      this.amountFrom = null;
      this.amountTo = null;
      this.getAdvanceReportData();
    },
    rowSelected() {},
  },
};
</script>
<style>
.vendor-icon {
  margin-right: 0px !important;
  padding: 0px !important;
}
.vendor-icon i {
  font-size: 20px !important;
  line-height: 60px !important;
  width: 30px !important;
  height: 30px !important;
  text-align: center;
}
.search-icon {
  margin: 0 5px !important;
}
.md-card .md-card-content {
  padding: 5px 0px !important;
}
.text-fix-adv {
  margin-right: 39% !important;
  text-align: right !important;
}
.text-fix-adv-un {
  margin-right: 28% !important;
  text-align: right !important;
}
</style>
