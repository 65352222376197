<template>
  <div>
    <div class="overlayLoader" v-if="loader">
      <md-progress-spinner
        :md-diameter="100"
        class="md-accent spinner-postion"
        :md-stroke="7"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <!-- For Create Invoice With PO -->
    <div
      class="md-layout-item md-medium-size-100 md-small-size-100 md-size-100"
      v-if="viewInvoice"
    >
      <md-card>
        <div style="display: flex">
          <md-card-header
            class="md-card-header-text md-layout-item md-card-header-blue"
            style="margin-top: 5px !important"
          >
            <div class="card-text profile-card">
              <h6 class="title">Header Details</h6>
            </div>
          </md-card-header>
          <md-card-header
            class="md-card-header-text md-card-header-blue"
            style="margin-top: 7px !important"
          >
            <div
              class="card-text profile-card"
              style="cursor:pointer;"
              @click="getOpenKmAuthenticate()"
              v-if="venInvoiceId"
            >
              <!-- <h6 class="title">Upload Document</h6> -->
              <md-icon title="Upload Document" style="width:15px; height:15px;"
                >file_upload</md-icon
              >
            </div>
          </md-card-header>
          <md-card-header
            class="md-card-header-text md-card-header-blue"
            style="margin-top: 5px !important"
          >
            <div
              class="card-text profile-card"
              style="cursor:pointer;"
              @click="showViewInvoiceForm(false)"
            >
              <h6 class="title">View Invoice</h6>
            </div>
          </md-card-header>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-25">
            <md-switch
              v-model="radio"
              @change="clearSwitch(radio)"
              :disabled="poHeaderDetails.approval_status === 'INPROCESS'"
              ><b style="color:black;">
                <span v-if="!radio">Click here for Invoice without PO</span
                ><span v-else>Click here for Invoice with PO</span>
              </b></md-switch
            >
            <!-- <md-radio v-model="radio" value="accent"
              ><b> Invoice with Agreement</b>
            </md-radio>
            <md-radio v-model="radio" value="primary" class="md-primary"
              ><b>Invoice without Agreement</b></md-radio
            > -->
          </div>
          <div
            class="md-layout-item md-small-size-100 md-size-15"
            v-if="!radio"
          >
            <md-field md-clearable @md-clear="clearPoValues()">
              <label for="font">PO Number</label>
              <md-input
                v-model="poHeaderDetails.po_number"
                :title="poHeaderDetails.po_number"
                @click="showHidePoModal(true)"
                :disabled="
                  poHeaderDetails.approval_status === 'INPROCESS' ||
                    venInvoiceId > 0
                "
                readonly
              ></md-input>
            </md-field>
          </div>
        </div>
        <!-- </md-card>
      <md-card> -->
        <!-- <md-card-header
          class="md-card-header-text md-layout-item md-card-header-blue"
          style="margin: 8px"
        >
          <div
            class="text-center card-text profile-card"
            style="height: 30px; font-weight:bold; width: 120px; padding: 3px !important"
          >
            <h5 class="title">Header Details</h5>
          </div>
        </md-card-header> -->
        <div class="md-layout" v-if="!radio">
          <div
            style="margin-right:1.5%;"
            class="md-layout-item md-small-size-100 md-size-15"
          >
            <md-field>
              <label for="text" class="mandatory">Invoice Number</label>
              <md-input
                v-model="poHeaderDetails.inv_number"
                :title="poHeaderDetails.inv_number"
                :disabled="poHeaderDetails.approval_status === 'INPROCESS'"
                @input="upperCaseFunction(poHeaderDetails.inv_number)"
                @blur="isInvNumValid()"
                maxlength="16"
              >
              </md-input>
            </md-field>
            <!-- <div class="error" v-if="$v.poHeaderDetails.inv_number.$error">
              This field is required
            </div> -->
          </div>
          <div
            style="margin-right:1.5%;"
            class="md-layout-item md-small-size-100 md-size-15"
          >
            <md-field>
              <label for="text" class="mandatory">Invoice Amount</label>
              <md-input
                v-model="poHeaderDetails.inv_amount"
                @blur="clearDeliveryQty()"
                v-numericOnly
                :disabled="poHeaderDetails.approval_status === 'INPROCESS'"
              ></md-input>
            </md-field>
            <!-- <div class="error" v-if="$v.poHeaderDetails.inv_amount.$error">
              This field is required
            </div> -->
          </div>
          <div
            style="margin-right:1.5%;"
            class="md-layout-item md-small-size-100 md-size-15"
          >
            <md-field>
              <label for="text" class="mandatory">Third Party GSTN</label>
              <md-input
                v-if="radio"
                v-model="poHeaderDetails.third_party_gstn"
                :title="poHeaderDetails.third_party_gstn"
                maxlength="15"
              ></md-input>
              <md-input
                v-else-if="!radio"
                v-model="poHeaderDetails.third_party_gstn"
                :title="poHeaderDetails.third_party_gstn"
                disabled
              ></md-input>
            </md-field>
            <!-- <div
              class="error"
              v-if="$v.poHeaderDetails.third_party_gstn.$error"
            >
              This field is required
            </div> -->
          </div>
          <div
            style="margin-right:1.5%;"
            class="md-layout-item md-small-size-100 md-size-15"
          >
            <md-field>
              <label for="text" class="mandatory">Company GSTN</label>
              <md-input
                v-if="radio"
                v-model="poHeaderDetails.first_party_gstn"
                :title="poHeaderDetails.first_party_gstn"
                maxlength="15"
              ></md-input>
              <md-input
                v-if="!radio"
                v-model="poHeaderDetails.first_party_gstn"
                :title="poHeaderDetails.first_party_gstn"
                disabled
              ></md-input>
            </md-field>
            <!-- <div
              class="error"
              v-if="$v.poHeaderDetails.first_party_gstn.$error"
            >
              This field is required
            </div> -->
          </div>
          <div
            style="margin-right:1.5%;"
            class="md-layout-item md-small-size-100 md-size-15"
          >
            <!-- :disabled="poHeaderDetails.po_number ? true : false" -->
            <md-field>
              <label for="text" class="mandatory">Currency</label>
              <md-input
                v-model="poHeaderDetails.currency"
                :title="poHeaderDetails.currency"
                :disabled="!radio"
              ></md-input>
            </md-field>
            <!-- <div class="error" v-if="$v.poHeaderDetails.currency.$error">
              This field is required
            </div> -->
          </div>
          <div
            style="margin-right:1.5%;"
            class="md-layout-item md-small-size-100 md-size-15"
          >
            <md-field>
              <label for="text" class="mandatory">Status</label>
              <md-input
                v-model="poHeaderDetails.approval_status"
                :title="poHeaderDetails.approval_status"
                disabled
              ></md-input>
            </md-field>
            <!-- <div class="error" v-if="$v.poHeaderDetails.approval_status.$error">
              This field is required
            </div> -->
          </div>
          <!--  -->
          <div
            style="margin-right:1.5%;"
            class="md-layout-item md-small-size-100 md-size-15"
          >
            <md-field>
              <label>Diff Amount</label>
              <md-input
                v-model="poHeaderDetails.dif_amount"
                disabled
                v-numericOnly
              ></md-input>
            </md-field>
          </div>
          <div
            style="margin-right:1.5%;"
            class="md-layout-item md-small-size-100 md-size-15"
          >
            <md-field>
              <label for="text" class="mandatory">Payment Term</label>
              <md-input
                v-model="poHeaderDetails.payment_term"
                :title="poHeaderDetails.payment_term"
                disabled
              ></md-input>
            </md-field>
            <!-- <div class="error" v-if="$v.poHeaderDetails.payment_term.$error">
              This field is required
            </div> -->
          </div>
          <div
            style="margin-right:1.5%;"
            class="md-layout-item md-small-size-100 md-size-15"
          >
            <md-field>
              <label for="text">Vendor Site Name</label>
              <md-input
                v-model="poHeaderDetails.vendor_site_name"
                :title="poHeaderDetails.vendor_site_name"
                disabled
              ></md-input>
            </md-field>
          </div>
          <div
            style="margin-right:1.5%;"
            class="md-layout-item md-small-size-100 md-size-15"
          >
            <md-field>
              <label for="text">Buyer Name</label>
              <md-input
                v-model="poHeaderDetails.buyer_name"
                :title="poHeaderDetails.buyer_name"
                disabled
              ></md-input>
            </md-field>
          </div>
          <div
            style="margin-right:1.5%;"
            class="md-layout-item md-small-size-100 md-size-15"
          >
            <md-field>
              <label for="text">Buyer Email ID</label>
              <md-input
                v-model="poHeaderDetails.buyer_email_id"
                :title="poHeaderDetails.buyer_email_id"
                disabled
              ></md-input>
            </md-field>
          </div>
          <!-- <div class="md-layout-item md-small-size-100 md-size-12">
            <md-field>
              <label for="text">GRN Creater Name</label>
              <md-input
                v-model="poHeaderDetails.grn_creator_name"
                :title="poHeaderDetails.grn_creator_name"
                disabled
              ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-12">
            <md-field>
              <label for="text">GRN Creater Email ID</label>
              <md-input
                v-model="poHeaderDetails.grn_creator_email_id"
                :title="poHeaderDetails.grn_creator_email_id"
                disabled
              ></md-input>
            </md-field>
          </div> -->
          <!-- <div class="md-layout-item md-small-size-100 md-size-20">
            <md-datepicker md-immediately v-model="venInvDate"
              ><label>Invoice Date</label></md-datepicker
            >
          </div> -->
          <div
            style="margin-right:1.5%;"
            class="md-layout-item md-small-size-100 md-size-15 DateFormate"
          >
            <md-field>
              <date-picker
                valueType="format"
                format="DD-MMM-YYYY"
                placeholder="Invoice Date"
                v-model="poHeaderDetails.inv_date"
                style="margin-top:-0.5rem;"
                :disabled-date="disabledDates"
                :disabled="poHeaderDetails.approval_status === 'INPROCESS'"
              ></date-picker>
            </md-field>
          </div>
        </div>
        <div class="md-layout" v-if="radio">
          <div class="md-layout-item md-small-size-100 md-size-20">
            <md-field>
              <label for="text" class="mandatory">Invoice Number</label>
              <md-input
                v-model="invoiceHeader.inv_number"
                :title="invoiceHeader.inv_number"
              >
              </md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-20">
            <md-field>
              <label for="text" class="mandatory">Invoice Amount</label>
              <md-input
                v-model="invoiceHeader.inv_amount"
                :title="invoiceHeader.inv_amount"
                v-numericOnly
              >
              </md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-20 DateFormate">
            <md-field>
              <date-picker
                valueType="format"
                format="DD-MMM-YYYY"
                placeholder="Invoice Date"
                v-model="invoiceHeader.inv_date"
                style="margin-top:-0.5rem;"
                :disabled-date="disabledDates"
              ></date-picker>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-20">
            <md-field
              md-clearable
              @md-clear="clearFieldsWithoutPo('legal_entity')"
            >
              <label for="text" class="mandatory">Legal Entity</label>
              <md-input
                v-model="invoiceHeader.le_name"
                :title="invoiceHeader.le_name"
                @click="showHideInvoiceCommonModal(true, 'legal_entity')"
                readonly
              >
              </md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-20">
            <md-field md-clearable>
              <label for="text" class="mandatory">Company GSTN</label>
              <md-input
                v-model="invoiceHeader.company_gstn_name"
                :title="invoiceHeader.company_gstn_name"
                @click="gstnBasedOnLeInvDate('compGstn')"
                readonly
              >
                <!-- @click="showHideInvoiceCommonModal(true, 'company_gstn')" -->
              </md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-20">
            <md-field md-clearable>
              <label for="text" class="mandatory">Vendor GSTN</label>
              <md-input
                v-model="invoiceHeader.vendor_gstn_name"
                :title="invoiceHeader.vendor_gstn_name"
                @click="gstnBasedOnLeInvDate('vendorGstn')"
                readonly
              >
              </md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-20">
            <md-field>
              <label for="text" class="mandatory">Status</label>
              <md-input
                v-model="invoiceHeader.approval_status"
                :title="invoiceHeader.approval_status"
                disabled
              >
              </md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-20">
            <md-field md-clearable>
              <label for="text" class="mandatory">Currency</label>
              <md-input
                v-model="invoiceHeader.currency"
                :title="invoiceHeader.currency"
                @click="showHideInvoiceCommonModal(true, 'currency_code')"
                readonly
              >
              </md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-20">
            <md-field>
              <label for="text" class="mandatory">Diff Amount</label>
              <md-input v-model="invoiceHeader.dif_amount" disabled> </md-input>
            </md-field>
          </div>
        </div>
      </md-card>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-small-size-100 md-size-100"
      v-if="viewInvoice && !radio"
    >
      <md-card>
        <div style="display: flex">
          <md-card-header
            class="md-card-header-text md-layout-item md-card-header-blue"
            style="margin-top: 5px !important"
          >
            <div class="card-text profile-card">
              <h6 class="title">PO Line Details</h6>
            </div>
          </md-card-header>
          <md-card-header
            class="md-card-header-text md-card-header-blue"
            style="margin-top: 5px !important"
          >
            <div
              v-if="
                poLineDetailsData.length &&
                  poHeaderDetails.approval_status !== 'INPROCESS'
              "
              class="card-text profile-card"
              style="cursor:pointer;"
              @click="addMultiRow()"
            >
              <h6 class="title">Add</h6>
            </div>
          </md-card-header>
        </div>
        <!-- <md-card-header
          class="md-card-header-text md-layout-item md-card-header-blue"
          style="margin: 8px"
        >
          <div
            class="text-center card-text profile-card"
            style="height: 30px; width: 120px; padding: 3px !important"
          >
            <h6 class="title">PO Line Details</h6>
          </div>
          <md-button
            style="margin-left:64rem;"
            class="md-raised md-rose search-btn"
            @click="addMultipleRow()"
            >Add</md-button
          >
        </md-card-header> -->
        <div class="md-layout-item">
          <template>
            <b-card>
              <b-table
                striped
                hover
                outlined
                :items="poLineDetailsData"
                :fields="poLineDetailsFields"
                show-empty
                class="bg-white"
                sticky-header
              >
                <template #cell(select)="data">
                  <b-form-checkbox
                    style="margin-left:0.5rem;"
                    v-model="data.item.select"
                    :value="true"
                    :unchecked-value="false"
                  ></b-form-checkbox>
                </template>
                <template #cell(po_price)="data">
                  <span>{{ formattedCurrency(+data.item.po_price) }}</span>
                </template>
                <template #cell(deliver_qty)="data">
                  <b-form-input
                    v-model="data.item.deliver_qty"
                    style="width:5rem; text-align: center;"
                    @blur="validateDeliverQty(data.item)"
                    v-numericOnly
                  ></b-form-input>
                </template>
                <template #cell(hsn_sac)="data">
                  <b-form-input
                    v-model="data.item.hsn_sac"
                    style="width:5rem; text-align: center;"
                    @blur="validateHsnSac(data.item)"
                    maxLength="8"
                    v-numericOnly
                  ></b-form-input>
                </template>
                <template #cell(tax_details)="data">
                  <span
                    style="cursor:pointer; color:#007bff"
                    @click="showHideTaxModal(true, data.item, data.index)"
                    >Tax Details</span
                  >
                </template>

                <template #cell(remarks)="data">
                  <b-form-input
                    v-model="data.item.remarks"
                    :title="data.item.remarks"
                  ></b-form-input>
                </template>

                <template #cell(NOTE_TO_VENDOR)="data">
                  <span
                    style="cursor:context-menu;"
                    :title="data.item.NOTE_TO_VENDOR"
                    >{{ truncateComment(data.item.NOTE_TO_VENDOR, 80) }}</span
                  >
                </template>
              </b-table>
            </b-card>
          </template>
        </div>
      </md-card>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-small-size-100 md-size-100"
      v-if="viewInvoice && !radio"
    >
      <md-card>
        <div style="display: flex">
          <md-card-header
            class="md-card-header-text md-layout-item md-card-header-blue"
            style="margin-top: 5px !important"
          >
            <div class="card-text profile-card">
              <h6 class="title">New Invoice Details</h6>
            </div>
          </md-card-header>
          <md-card-header
            class="md-card-header-text md-card-header-blue"
            style="margin-top: 5px !important"
          >
            <div
              v-if="
                newInvoiceDetailsData.length &&
                  poHeaderDetails.approval_status !== 'INPROCESS'
              "
              class="card-text profile-card"
              style="cursor:pointer;"
              @click="removeMultiRow()"
            >
              <h6 class="title">Remove</h6>
            </div>
          </md-card-header>
        </div>
        <!-- <md-card-header
          class="md-card-header-text md-layout-item md-card-header-blue"
          style="margin: 8px"
        >
          <div
            class="text-center card-text profile-card"
            style="height: 30px; width: 160px; padding: 3px !important"
          >
            <h5 class="title">New Invoice Details</h5>
          </div>
          <md-button
            v-if="newInvoiceDetailsData.length"
            style="margin-left:61rem;"
            class="md-raised md-rose search-btn"
            @click="removeMultipleRow()"
            >Remove</md-button
          >
        </md-card-header> -->

        <div class="md-layout-item">
          <template>
            <b-card>
              <b-table
                striped
                hover
                outlined
                :items="newInvoiceDetailsData"
                :fields="newInvoiceDetailsFields"
                show-empty
                class="bg-white"
                sticky-header
              >
                <template #cell(select)="data">
                  <b-form-checkbox
                    v-model="data.item.select"
                    :value="true"
                    :unchecked-value="false"
                  ></b-form-checkbox>
                </template>
                <template #cell(po_price)="data">
                  <span>{{ formattedCurrency(+data.item.po_price) }}</span>
                </template>
                <template #cell(tax_amount)="data">
                  <!-- <span>{{ parseFloat(data.item.tax_amount).toFixed(2) }}</span> -->
                  <span>{{ formattedCurrency(+data.item.tax_amount) }}</span>
                </template>
                <template #cell(tax_details)="data">
                  <span
                    style="cursor:pointer; color:#007bff"
                    @click="showHidePreviewTaxModal(true, data.item)"
                    >Tax Details</span
                  >
                </template>
                <template #cell(remarks)="data">
                  <span
                    style="cursor:context-menu;"
                    :title="data.item.remarks"
                    >{{ truncateComment(data.item.remarks, 20) }}</span
                  >
                </template>
                <template #cell(NOTE_TO_VENDOR)="data">
                  <span
                    style="cursor:context-menu;"
                    :title="data.item.NOTE_TO_VENDOR"
                    >{{ truncateComment(data.item.NOTE_TO_VENDOR, 80) }}</span
                  >
                </template>
              </b-table>
            </b-card>
          </template>
        </div>
      </md-card>
      <!-- v-if="poHeaderDetails.po_number" -->
      <md-button
        v-if="
          newInvoiceDetailsData.length &&
            (poHeaderDetails.approval_status == 'DRAFT' ||
              poHeaderDetails.approval_status == 'REJECTED')
        "
        style="float:right;"
        class="md-raised md-rose search-btn"
        @click="validateInvoiceDetails()"
        >Save</md-button
      >
      <md-button
        style="float:right;"
        class="md-raised md-rose search-btn"
        v-if="venInvoiceId && poHeaderDetails.approval_status == 'DRAFT'"
        @click="validateDocument()"
        >Submit</md-button
      >
      <!-- :disabled="isApprovalSent" -->
    </div>
    <!-- For Create Invoice With PO -->

    <!-- For Create Invoice Without PO -->
    <div
      class="md-layout-item md-medium-size-100 md-small-size-100 md-size-100"
      v-if="viewInvoice && radio"
    >
      <md-card>
        <div style="display: flex">
          <md-card-header
            class="md-card-header-text md-layout-item md-card-header-blue"
            style="margin-top: 5px !important"
          >
            <div class="card-text profile-card">
              <h6 class="title">Invoice Details</h6>
            </div>
          </md-card-header>
        </div>
        <div class="md-layout-item">
          <b-table
            striped
            style="max-height:400px;"
            hover
            outlined
            :items="invoiceWithoutPoData"
            :fields="invoiceWithoutPoFields"
            show-empty
            class="bg-white"
            sticky-header
          >
            <template #cell(item_description)="data">
              <b-form-input
                v-model="data.item.item_description"
                :title="data.item.item_description"
              ></b-form-input>
            </template>
            <template #cell(price)="data">
              <b-form-input
                v-model="data.item.price"
                v-numericOnly
                @blur="calculateTotalAmount(data.item, 'price')"
              ></b-form-input>
            </template>
            <template #cell(deliver_qty)="data">
              <b-form-input
                v-model="data.item.deliver_qty"
                @blur="calculateTotalAmount(data.item, 'deliver_qty')"
              ></b-form-input>
            </template>
            <template #cell(total_price)="data">
              {{
                data.item.total_price !== 0
                  ? formattedCurrency(data.item.total_price)
                  : data.item.total_price
              }}
            </template>
            <template #cell(hsn_sac)="data">
              <b-form-input
                v-model="data.item.hsn_sac"
                maxlength="8"
                v-numericOnly
              ></b-form-input>
            </template>
            <template #cell(tax_category_name)="data">
              <b-form-input
                v-model="data.item.tax_category_name"
                :title="data.item.tax_category_name"
              ></b-form-input>
            </template>
            <template #cell(tax_details)="data">
              <span
                v-if="invoiceHeader.le_id"
                style="cursor:pointer; color:#007bff"
                @click="showHideTaxWithoutPoModal(true, data.item, data.index)"
                >Details</span
              >
            </template>
            <template #cell(add)="">
              <div @click="addVendorInvoiceLine()">
                <md-icon>add_circle</md-icon>
              </div>
            </template>
            <template #cell(remove)="data">
              <div
                v-if="data.index > 0"
                @click="removeVendorInvoiceLine(data.index)"
              >
                <md-icon>delete</md-icon>
              </div>
            </template>
          </b-table>
          <md-button
            style="float:right;"
            class="md-raised md-rose search-btn"
            @click="savenNewFunction()"
            >Save</md-button
          >
          <md-button
            style="float:right;"
            class="md-raised md-rose search-btn"
            v-if="
              venInvoiceId && poHeaderDetails.approval_status !== 'INPROCESS'
            "
            :disabled="isApprovalSent"
            @click="newFunction()"
            >Submit</md-button
          >
        </div>
      </md-card>
    </div>
    <!-- For Create Invoice Without PO -->

    <!-- For View Grid Invoice -->
    <div
      class="md-layout-item md-medium-size-100 md-small-size-100 md-size-100"
      v-if="!viewInvoice"
    >
      <md-card>
        <div style="display: flex">
          <md-card-header
            class="md-card-header-text md-layout-item md-card-header-blue"
            style="margin-top: 5px !important"
          >
            <!-- <div class="card-text profile-card">
              <h6 class="title">Search Filter</h6>
            </div> -->
          </md-card-header>
          <md-card-header
            class="md-card-header-text md-card-header-blue"
            style="margin-top: 10px !important"
          >
            <div
              class="card-text profile-card"
              style="cursor:pointer;"
              @click="showViewInvoiceForm(true)"
            >
              <h6 class="title">Add Invoice</h6>
            </div>
          </md-card-header>
        </div>
        <div class="md-layout">
          <md-card-header
            class="md-card-header-text md-card-header-green search-icon"
          >
            <div class="card-icon vendor-icon">
              <md-icon style="font-size: 20px !important">search</md-icon>
            </div>
          </md-card-header>
          <div
            style="min-width:10%;"
            class="md-layout-item md-small-size-100 md-size-10"
          >
            <md-field>
              <label for="text">PO Number</label>
              <md-input
                v-model="searchFilters.poNumber"
                :title="searchFilters.poNumber"
                v-numericOnly
              ></md-input>
            </md-field>
          </div>
          <div
            style="min-width:10%;"
            class="md-layout-item md-small-size-100 md-size-10"
          >
            <md-field>
              <label>Invoice No.</label>
              <md-input
                type="text"
                v-model="searchFilters.invNumber"
                :title="searchFilters.invNumber"
              ></md-input>
            </md-field>
          </div>
          <div
            style="min-width:10%;"
            class="md-layout-item md-small-size-100 md-size-10"
          >
            <md-field>
              <label for="text">Amount From</label>
              <md-input
                v-model="searchFilters.invAmountFrom"
                :title="searchFilters.invAmountFrom"
                v-numericOnly
              ></md-input>
            </md-field>
          </div>
          <div
            style="min-width:10%;"
            class="md-layout-item md-small-size-100 md-size-10"
          >
            <md-field>
              <label for="text">Amount To</label>
              <md-input
                v-model="searchFilters.invAmountTo"
                :title="searchFilters.invAmountTo"
                v-numericOnly
              ></md-input>
            </md-field>
          </div>
          <div
            style="min-width:10%;"
            class="md-layout-item md-small-size-100 md-size-10"
          >
            <md-field md-clearable>
              <label for="text">Status</label>
              <md-input
                v-model="searchFilters.status.text"
                :title="searchFilters.status.text"
                readonly
                @click="openValueSetModal('PARTY_REQ_APPROVAL_STATUS_VSET')"
              ></md-input>
            </md-field>
          </div>
          <div
            style="max-width:12%;"
            class="md-layout-item md-small-size-100 md-size-12 DateFormate"
          >
            <date-picker
              v-model="searchFilters.fromDate"
              valueType="format"
              format="DD-MMM-YYYY"
              placeholder="From Date"
              @change="toDate = null"
            ></date-picker>
          </div>
          <div
            style="max-width:12%;"
            class="md-layout-item md-small-size-100 md-size-12 DateFormate"
          >
            <date-picker
              v-model="searchFilters.toDate"
              valueType="format"
              format="DD-MMM-YYYY"
              placeholder="To Date"
              :disabled="!searchFilters.fromDate"
            ></date-picker>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-16">
            <div style="margin-top: 10px;">
              <md-button
                class="md-raised md-success search-btn"
                @click="getVendorInvoiceDetails()"
                style="margin-right: 10px"
                >Search</md-button
              ><md-button
                class="md-raised md-info search-btn"
                style="margin-right: 10px"
                @click="clearFilters()"
                >Clear</md-button
              >
              <md-button
                v-if="invoiceDetailsData.length"
                style="height:28px;"
                class="md-warning md-just-icon"
                @click="downloadCustomExcel()"
                title="Download Excel"
                ><md-icon>file_download</md-icon></md-button
              >
            </div>
          </div>
        </div>
      </md-card>
    </div>

    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      v-if="!viewInvoice"
    >
      <md-card>
        <div style="display: flex">
          <md-card-header
            class="md-card-header-text md-layout-item md-card-header-rose"
            style="margin-top: 5px !important"
          >
            <div class="card-text profile-card">
              <h6 class="title">Invoice Details</h6>
            </div>
          </md-card-header>
          <!-- <md-card-header
            class="md-card-header-text md-card-header-blue"
            style="margin-top: 5px !important"
          >
            <div class="card-text profile-card" @click="showQueryForm(true)">
              <h6 class="title">Add Query</h6>
            </div>
          </md-card-header> -->
        </div>
        <div class="md-layout-item" style="margin-top: 3px !important">
          <b-table
            striped
            hover
            outlined
            sticky-header
            :items="invoiceDetailsData"
            :fields="invoiceDetailsField"
            show-empty
            class="bg-white"
            @row-clicked="rowSelectedGrid"
          >
            <template #cell(inv_amt)="data">
              <span class="text-fix-invamt">{{
                data.item.inv_amt !== 0
                  ? formattedCurrency(data.item.inv_amt)
                  : data.item.inv_amt
              }}</span>
            </template>
            <template #cell(uploaded_document)="data">
              <a href="#" @click="getOpenKmAuthenticate()">{{
                data.item.uploaded_document
              }}</a>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPageGrid"
            :total-rows="totalRowsGrid"
            :per-page="perPageGrid"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
          <div
            class="pagination-count"
            v-if="invoiceDetailsData.length && totalRowsGrid <= perPageGrid"
          >
            <p>
              Showing
              {{
                totalRowsGrid == 0
                  ? totalRowsGrid
                  : perPageGrid * (currentPageGrid - 1) + 1
              }}
              to {{ totalRowsGrid }} of {{ totalRowsGrid }} entries
            </p>
          </div>
          <div class="pagination-count" v-else-if="invoiceDetailsData.length">
            <p>
              Showing {{ perPageGrid * (currentPageGrid - 1) + 1 }} to
              {{
                perPageGrid * currentPageGrid >= totalRowsGrid
                  ? totalRowsGrid
                  : perPageGrid * currentPageGrid
              }}
              of {{ totalRowsGrid }}
              entries
            </p>
          </div>
        </div>
      </md-card>
    </div>
    <!-- For View Grid Invoice -->

    <b-modal
      v-model="showTaxModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="lg"
      title="Tax Details"
      @hidden="showHideTaxModal(false, null, null)"
      ><b-table
        striped
        hover
        outlined
        :items="taxDetailsData"
        :fields="taxDetailsFields"
        show-empty
        class="bg-white font-fix"
        sticky-header
      >
        <template #cell(tax_type)="data">
          <b-form-input
            v-if="!data.item.tax_type_id"
            v-model="data.item.tax_type"
            :title="data.item.tax_type"
            @click="showHideTaxTypeModal(true, data.index)"
            readonly
          ></b-form-input>
          <span v-else>{{ data.item.tax_type }}</span>
        </template>
        <template #cell(taxable_amount)="data">
          <span
            >{{
              data.item.taxable_amount
                ? formattedCurrency(+data.item.taxable_amount)
                : null
            }}
          </span>
        </template>
        <template #cell(tax_amt)="data">
          <b-form-input
            v-if="!data.item.tax_rate_percentage"
            v-model="data.item.tax_amt"
          ></b-form-input>
          <span v-else
            >{{
              data.item.tax_amt ? formattedCurrency(+data.item.tax_amt) : null
            }}
          </span>
        </template>
        <template #cell(rcm_flag)="data">
          <span>{{
            data.item.rcm_flag == "N"
              ? "No"
              : data.item.rcm_flag == "Y"
              ? "Yes"
              : null
          }}</span>
        </template>
        <template #cell(add)="">
          <div @click="addTaxRow()">
            <md-icon>add_circle</md-icon>
          </div>
        </template>
        <template #cell(remove)="data">
          <div
            v-if="!data.item.tax_rate_percentage"
            @click="removeTaxRow(data.index)"
          >
            <md-icon>delete</md-icon>
          </div>
        </template>
      </b-table>
      <div
        class="md-layout-item md-small-size-100 md-size-15"
        style="margin-top: 30%; float:right;"
      >
        <md-button
          class="md-raised md-success search-btn"
          @click="addEditTaxDetails()"
          >Save</md-button
        >
      </div>
    </b-modal>
    <b-modal
      v-model="showPreviewTaxModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="lg"
      title="Tax Details"
      @hidden="showHidePreviewTaxModal(false, null)"
    >
      <b-table
        striped
        hover
        outlined
        :items="previewTaxData"
        :fields="previewTaxFields"
        show-empty
        class="bg-white font-fix"
        sticky-header
      >
        <template #cell(taxable_amount)="data">
          <span>{{
            data.item.taxable_amount
              ? formattedCurrency(+data.item.taxable_amount)
              : null
          }}</span>
        </template>
        <template #cell(amount)="data">
          <span>{{
            data.item.amount ? formattedCurrency(+data.item.amount) : null
          }}</span>
        </template>
        <template #cell(rcm_flag)="data">
          <span>{{
            data.item.rcm_flag == "N"
              ? "No"
              : data.item.rcm_flag == "Y"
              ? "Yes"
              : null
          }}</span>
        </template>
      </b-table>
    </b-modal>
    <b-modal
      v-model="showTaxTypeModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="md"
      title="Tax Type"
      @hidden="showHideTaxTypeModal(false, null)"
    >
      <b-table
        striped
        hover
        outlined
        :items="taxTypeData"
        :fields="taxTypeFields"
        show-empty
        class="bg-white font-fix"
        sticky-header
        @row-clicked="rowSelectedTaxType"
      >
      </b-table>
    </b-modal>
    <b-modal
      v-model="showPoModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="wide-modal"
      title="PO Number"
      @hidden="showHidePoModal(false)"
    >
      <div>
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-30">
            <md-field>
              <label for="font">Search By PO Number</label>
              <md-input v-model="poNumberById" v-numericOnly> </md-input>
            </md-field>
          </div>
          <div
            class="md-layout-item md-small-size-100 md-size-10"
            style="margin-top: 10px"
          >
            <md-button
              class="md-raised md-success search-btn"
              @click="getPoNumberDetailsById()"
              >Search</md-button
            >
          </div>
          <div
            class="md-layout-item md-small-size-100 md-size-10"
            style="margin-top: 10px"
          >
            <md-button
              class="md-raised md-info search-btn"
              @click="clearSearch()"
              >Clear</md-button
            >
          </div>
        </div>
      </div>
      <b-table
        striped
        hover
        outlined
        :items="poNumberData"
        :fields="poNumberFields"
        show-empty
        class="bg-white font-fix"
        sticky-header
        @row-clicked="rowSelectedPoNumber"
      >
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="fill"
        size="sm"
        class="my-0"
      ></b-pagination>
      <div
        class="pagination-count1"
        v-if="poNumberData.length && totalRows <= perPage"
      >
        <p>
          Showing
          {{ totalRows == 0 ? totalRows : perPage * (currentPage - 1) + 1 }}
          to {{ totalRows }} of {{ totalRows }} entries
        </p>
      </div>
      <div class="pagination-count1" v-else-if="poNumberData.length">
        <p>
          Showing {{ perPage * (currentPage - 1) + 1 }} to
          {{
            perPageGrid * currentPage >= totalRows
              ? totalRows
              : perPage * currentPage
          }}
          of {{ totalRows }}
          entries
        </p>
      </div>
    </b-modal>
    <b-modal
      v-model="showDocumentModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="xl"
      title="Upload Document"
      @hidden="showHideDocumentModal(false)"
    >
      <uploadDocument
        :wfHdrId="venInvoiceId"
        :openKmAuth="openKmAuth"
        :propMenuId="menuId"
        :PropSubMenuId="subMenuId"
        :approval_status="poHeaderDetails.approval_status"
        formName="invoice_entry"
      />
    </b-modal>

    <!-- b-modal for Invoice Without PO -->
    <b-modal
      v-model="showInvoiceCommonModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      :size="
        modalType === 'legal_entity'
          ? 'md'
          : modalType === 'company_gstn'
          ? 'md'
          : modalType === 'vendor_gstn'
          ? 'md'
          : modalType === 'currency_code'
          ? 'md'
          : ''
      "
      :title="
        modalType === 'legal_entity'
          ? 'Legal Entity'
          : modalType === 'company_gstn'
          ? 'Company GSTN'
          : modalType === 'vendor_gstn'
          ? 'Vendor GSTN'
          : modalType === 'currency_code'
          ? 'Currency'
          : ''
      "
      @hidden="showHideInvoiceCommonModal(false, null)"
    >
      <div v-if="modalType === 'legal_entity'">
        <div class="md-layout font-fix">
          <label for="filter">Legal Entity Name:</label>

          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field style="margin-top: -5px">
              <label>Search by value</label>
              <md-input
                type="text"
                v-model="legalEntitySearch"
                :title="legalEntitySearch"
              ></md-input>
            </md-field>
          </div>

          <div style="margin-top: -7px">
            <md-button
              class="md-raised md-success search-btn"
              @click="getLegalEntityVendorInvoice()"
              style="margin-left: 15px"
              >Search</md-button
            >
          </div>
        </div>
        <b-table
          striped
          hover
          outlined
          :items="legalEntityData"
          :fields="legalEntityFields"
          show-empty
          class="bg-white font-fix"
          sticky-header
          @row-clicked="rowSelectedLegalEntity"
          style="max-height:calc(100vh - 250px)"
        >
        </b-table>
        <b-pagination
          v-model="currentPageLegalEntity"
          :total-rows="totalRowsLegalEntity"
          :per-page="perPageLegalEntity"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </div>
      <div v-if="modalType === 'company_gstn'">
        <div class="md-layout font-fix">
          <label for="filter">Company GSTN:</label>

          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field style="margin-top: -5px">
              <label>Search by value</label>
              <md-input
                type="text"
                v-model="companyGstnSearch"
                :title="companyGstnSearch"
              ></md-input>
            </md-field>
          </div>

          <div style="margin-top: -7px">
            <md-button
              class="md-raised md-success search-btn"
              @click="getCompanyGstnVendorInvoice()"
              style="margin-left: 15px"
              >Search</md-button
            >
          </div>
        </div>
        <b-table
          striped
          hover
          outlined
          :items="companyGstnData"
          :fields="companyGstnFields"
          show-empty
          class="bg-white font-fix"
          sticky-header
          @row-clicked="rowSelectedCompanyGstn"
        >
          <template #head(registration_number)="">
            <span>Company GST Number</span></template
          >
        </b-table>
        <b-pagination
          v-model="currentPageCompanyGstn"
          :total-rows="totalRowsCompanyGstn"
          :per-page="perPageCompanyGstn"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </div>
      <div v-if="modalType === 'vendor_gstn'">
        <div class="md-layout font-fix">
          <label for="filter">Vendor GSTN:</label>

          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field style="margin-top: -5px">
              <label>Search by value</label>
              <md-input
                type="text"
                v-model="vendorGstnSearch"
                :title="vendorGstnSearch"
              ></md-input>
            </md-field>
          </div>

          <div style="margin-top: -7px">
            <md-button
              class="md-raised md-success search-btn"
              @click="getVendorGstnVendorInvoice()"
              style="margin-left: 15px"
              >Search</md-button
            >
          </div>
        </div>
        <b-table
          striped
          hover
          outlined
          :items="vendorGstnData"
          :fields="vendorGstnFields"
          show-empty
          class="bg-white font-fix"
          sticky-header
          @row-clicked="rowSelectedVendorGstn"
          style="max-height:calc(100vh - 300px)"
        >
          <template #head(registration_number)="">
            <span>Vendor GST Number</span></template
          >
        </b-table>
        <b-pagination
          v-model="currentPageVendorGstn"
          :total-rows="totalRowsVendorGstn"
          :per-page="perPageVendorGstn"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </div>
      <div v-if="modalType === 'currency_code'">
        <div class="md-layout font-fix">
          <label for="filter">Currency:</label>

          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field style="margin-top: -5px">
              <label>Search by value</label>
              <md-input
                type="text"
                v-model="currencySearch"
                :title="currencySearch"
              ></md-input>
            </md-field>
          </div>

          <div style="margin-top: -7px">
            <md-button
              class="md-raised md-success search-btn"
              @click="getCurrencyVendorInvoice()"
              style="margin-left: 15px"
              >Search</md-button
            >
          </div>
        </div>
        <b-table
          striped
          hover
          outlined
          :items="currencyData"
          :fields="currencyFields"
          show-empty
          class="bg-white font-fix"
          sticky-header
          @row-clicked="rowSelectedCurrency"
          style="max-height:calc(100vh - 300px)"
        >
          <template #head(registration_number)="">
            <span>Currency</span></template
          >
        </b-table>
        <b-pagination
          v-model="currentPageCurrency"
          :total-rows="totalRowsCurrency"
          :per-page="perPageCurrency"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </div>
    </b-modal>
    <b-modal
      v-model="showTaxWithoutPoModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="lg"
      title="Tax Details"
      @hidden="showHideTaxWithoutPoModal(false, null, null)"
      ><b-table
        striped
        hover
        outlined
        :items="taxWithoutPoData"
        :fields="taxWithoutPoFields"
        show-empty
        class="bg-white font-fix"
        sticky-header
      >
        <template #cell(tax_type)="data">
          <b-form-input
            v-model="data.item.tax_type"
            :title="data.item.tax_type"
            @click="showHideTaxCommonModal(true, 'taxType', data.index)"
            readonly
          ></b-form-input>
        </template>
        <template #cell(tax_rate_percentage)="data">
          <b-form-input
            v-model="data.item.tax_rate_percentage"
            :title="data.item.tax_rate_percentage"
            @click="showHideTaxCommonModal(true, 'taxRate', data.index)"
            readonly
          ></b-form-input>
        </template>
        <template #cell(add)="">
          <div @click="addTaxWithoutPoRow()">
            <md-icon>add_circle</md-icon>
          </div>
        </template>
        <template #cell(remove)="data">
          <div v-if="data.index > 0" @click="removeTaxWithoutPoRow(data.index)">
            <md-icon>delete</md-icon>
          </div>
        </template>
        <!-- <template #cell(tax_type)="data">
          <b-form-input
            v-if="!data.item.tax_type_id"
            v-model="data.item.tax_type"
            :title="data.item.tax_type"
            @click="showHideTaxTypeModal(true, data.index)"
            readonly
          ></b-form-input>
          <span v-else>{{ data.item.tax_type }}</span>
        </template>
        <template #cell(taxable_amount)="data">
          <span
            >{{ formattedCurrency(Number(data.item.taxable_amount)) }}
          </span>
        </template>
        <template #cell(tax_amt)="data">
          <b-form-input
            v-if="!data.item.tax_rate_percentage"
            v-model="data.item.tax_amt"
          ></b-form-input>
          <span v-else
            >{{ formattedCurrency(Number(data.item.tax_amt)) }}
          </span>
        </template>
        <template #cell(add)="">
          <div @click="addTaxRow()">
            <md-icon>add_circle</md-icon>
          </div>
        </template>
        <template #cell(remove)="data">
          <div
            v-if="!data.item.tax_rate_percentage"
            @click="removeTaxRow(data.index)"
          >
            <md-icon>delete</md-icon>
          </div>
        </template> -->
      </b-table>
      <div
        class="md-layout-item md-small-size-100 md-size-15"
        style="margin-top: 30%; float:right;"
      >
        <md-button class="md-raised md-success search-btn" @click="isTaxValid()"
          >Save</md-button
        >
      </div>
    </b-modal>
    <b-modal
      v-model="showTaxCommonModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="md"
      :title="
        modalType === 'taxType'
          ? 'Tax Type'
          : modalType === 'taxRate'
          ? 'Tax Rate'
          : ''
      "
      @hidden="showHideTaxCommonModal(false, null, null)"
    >
      <div v-if="modalType === 'taxType'">
        <b-table
          striped
          hover
          outlined
          :items="taxTypeWithoutPoData"
          :fields="taxTypeWithoutPoFields"
          show-empty
          class="bg-white font-fix"
          sticky-header
          @row-clicked="rowSelectedTaxTypeWithoutPo"
          style="max-height:calc(100vh - 100px)"
        >
        </b-table>
      </div>
      <div v-if="modalType === 'taxRate'">
        <b-table
          striped
          hover
          outlined
          :items="taxRateWithoutPoData"
          :fields="taxRateWithoutPoFields"
          show-empty
          class="bg-white font-fix"
          sticky-header
          @row-clicked="rowSelectedTaxRateWithoutPo"
          style="max-height:calc(100vh - 100px)"
        >
          <template #head(tax_rate_percentage)="">
            <span>Tax Rate Percentage</span></template
          >
        </b-table>
        <b-pagination
          v-model="currentPageTaxRateWithoutPo"
          :total-rows="totalRowsTaxRateWithoutPo"
          :per-page="perPageTaxRateWithoutPo"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </div>
    </b-modal>
    <!-- b-modal for Invoice Without PO -->
    <valueSetModal
      v-if="showValueSetModal"
      @selectedvalueSet="selectedvalueSet"
      @closeValueSetModal="closeValueSetModal"
    ></valueSetModal>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import commonHelper from "../../../utility/common.helper.utility";
import { required } from "vuelidate/lib/validators";
import uploadDocument from "../../uploadDocument.vue";
import appStrings from "../../../utility/string.utility";
export default {
  components: {
    DatePicker,
    uploadDocument,
  },
  name: "VendorInvoiceEntry",
  watch: {
    currentPage: function() {
      this.getPoNumberDetailsById();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getPoNumberDetailsById();
    },
    currentPageGrid: function() {
      this.getVendorInvoiceDetails();
    },
    perPageGrid: function() {
      this.currentPageGrid = 1;
      this.getVendorInvoiceDetails();
    },
    currentPageLegalEntity: function() {
      this.getLegalEntityVendorInvoice();
    },
    perPageLegalEntity: function() {
      this.currentPageLegalEntity = 1;
      this.getLegalEntityVendorInvoice();
    },
    currentPageVendorGstn: function() {
      this.getVendorGstnVendorInvoice();
    },
    perPageVendorGstn: function() {
      this.currentPageVendorGstn = 1;
      this.getVendorGstnVendorInvoice();
    },
    currentPageCompanyGstn: function() {
      this.getCompanyGstnVendorInvoice();
    },
    perPageCompanyGstn: function() {
      this.currentPageCompanyGstn = 1;
      this.getCompanyGstnVendorInvoice();
    },
    currentPageCurrency: function() {
      this.getCurrencyVendorInvoice();
    },
    perPageCurrency: function() {
      this.currentPageCurrency = 1;
      this.getCurrencyVendorInvoice();
    },
    currentPageTaxRateWithoutPo: function() {
      this.getTaxRateVendorInvoice();
    },
    perPageTaxRateWithoutPo: function() {
      this.currentPageTaxRateWithoutPo = 1;
      this.getTaxRateVendorInvoice();
    },
  },
  // validations: {
  //   poHeaderDetails: {
  //     inv_number: {
  //       required,
  //     },
  //     inv_amount: {
  //       required,
  //     },
  //     first_party_gstn: {
  //       required,
  //     },
  //     third_party_gstn: {
  //       required,
  //     },
  //     currency: {
  //       required,
  //     },
  //     payment_term: {
  //       required,
  //     },
  //     approval_status: {
  //       required,
  //     },
  //   },
  // },
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      perPageGrid: commonHelper.perPageRecord,
      pageOptionsGrid: commonHelper.getPageOption(),
      currentPageGrid: 1,
      totalRowsGrid: null,
      loader: false,
      vendorId: null,
      vendorName: null,
      venInvoiceId: 0,
      poHeaderDetails: {
        po_header_id: null,
        po_number: null,
        inv_number: null,
        inv_amount: null,
        first_party_gstn: null,
        third_party_gstn: null,
        currency: null,
        approval_status: "DRAFT",
        dif_amount: null,
        payment_term: null,
        vendor_site_id: null,
        vendor_site_name: null,
        buyer_name: null,
        buyer_email_id: null,
        buyer_emp_id: null,
        inv_date: null,
        po_creation_date: null,
        org_id: null,
        po_status: null,
        grn_creator_name: null,
        grn_creator_email_id: null,
        grn_creator_id: null,
      },
      invTaxAbleVal: null,
      venInvDate: null,
      radio: false,
      showPoModal: false,
      poNumberById: null,
      poLineDetailsData: [],
      poLineDetailsFields: [
        {
          key: "select",
          class: "item-col",
          stickyColumn: true,
        },
        {
          key: "po_number",
          label: "PO Number",
        },
        { key: "po_line_num", label: "PO Line No." },
        {
          key: "po_release_num",
          label: "PO Release",
        },
        {
          key: "po_shipment_line_num",
          label: "Shipment Line No.",
        },
        {
          key: "item_code",
        },
        {
          key: "item_description",
          label: "Description",
          class: "width-fix-des",
        },
        {
          key: "po_price",
          label: "Unit Price",
        },
        {
          key: "uom",
          label: "UOM",
        },
        {
          key: "quantity",
          label: "Total Qty.",
        },
        {
          key: "billed_pending",
        },
        {
          key: "pending_qty",
          label: "Pending Qty.",
        },
        {
          key: "deliver_qty",
          label: "Deliver Qty.",
        },
        {
          key: "hsn_sac",
          label: "HSN/SAC",
        },
        {
          key: "NOTE_TO_VENDOR",
          label: "Note To Vendor",
          class: "width-fix-des",
        },
        {
          key: "shp_to_loc",
          label: "Ship to Loc",
        },
        {
          key: "item_type",
        },
        // {
        //   key: "tax_rate",
        // },
        {
          key: "tax_category_name",
          label: "Tax Category",
        },
        {
          key: "tax_details",
        },
        {
          key: "remarks",
        },
        // {
        //   key: "other_tax",
        // },
      ],
      newInvoiceDetailsData: [],
      newInvoiceDetailsFields: [
        {
          key: "select",
          class: "item-col",
        },
        {
          key: "po_number",
          label: "PO Number",
        },
        { key: "po_line_num", label: "PO Line No." },
        {
          key: "po_release_num",
          label: "PO Release",
        },
        {
          key: "po_shipment_line_num",
          label: "Shipment Line No.",
        },
        {
          key: "item_code",
        },
        {
          key: "item_description",
          label: "Description",
          class: "width-fix-des",
        },
        {
          key: "po_price",
          label: "Unit Price",
        },
        {
          key: "uom",
          label: "UOM",
        },
        {
          key: "deliver_qty",
          label: "Deliver Qty.",
        },
        {
          key: "hsn_sac",
          label: "HSN/SAC",
        },
        {
          key: "NOTE_TO_VENDOR",
          label: "Note To Vendor",
          class: "width-fix-des",
        },
        {
          key: "shp_to_loc",
          label: "Ship To Loc",
        },
        {
          key: "item_type",
        },
        // {
        //   key: "tax_rate",
        // },
        {
          key: "tax_category_name",
          label: "Tax Category",
        },
        {
          key: "tax_amount",
        },
        {
          key: "tax_details",
        },
        {
          key: "remarks",
        },
      ],
      poNumberFields: [
        {
          key: "po_number",
          label: "PO Number",
          class: "poItem-col text-left",
        },
        {
          key: "first_party_gstn",
          label: "Delhivery GSTN",
        },
        {
          key: "third_party_gstn",
          label: "Vendor GSTN",
        },
        {
          key: "payment_term",
        },
        {
          key: "vendor_site_name",
        },
        {
          key: "currency",
        },
        {
          key: "po_creation_date",
          label: "PO Creation Date",
        },
      ],
      poNumberData: [],
      viewInvoice: true,
      // View Vendor Invoice Variables
      searchFilters: {
        poNumber: null,
        invNumber: null,
        invAmountFrom: null,
        invAmountTo: null,
        fromDate: null,
        toDate: null,
        status: { text: null, value: null },
      },
      showValueSetModal: false,
      invoiceDetailsData: [],
      invoiceDetailsField: [
        {
          key: "le_name",
          label: "Legal Entity",
        },
        {
          key: "po_num",
          label: "PO Number",
        },
        {
          key: "inv_num",
          label: "Invoice No.",
        },
        {
          key: "inv_amt",
          label: "Invoice Amount",
          class: "text-fix-invamt",
        },
        {
          key: "first_party_gstn",
          label: "Company GSTN",
        },
        {
          key: "third_party_gstn",
          label: "Third Party GSTN",
        },
        { key: "currency" },
        {
          key: "approval_status",
        },
        {
          key: "inv_date",
          label: "Invoice Date",
        },
        // {
        //   key: "uploaded_document",
        //   label: "Document",
        // },
      ],
      taxDetailsData: [
        {
          tax_type: null,
          tax_type_id: null,
          tax_rate_id: null,
          tax_rate_type: null,
          taxable_amount: null,
          tax_rate_percentage: null,
          tax_amt: null,
        },
      ],
      taxDetailsFields: [
        { key: "tax_type" },
        { key: "taxable_amount" },
        { key: "tax_rate_percentage", label: "Tax Rate" },
        { key: "tax_amt", label: "Tax Amount" },
        { key: "rcm_flag", label: "RCM" },
        { key: "add", stickyColumn: true },
        { key: "remove", stickyColumn: true },
      ],
      showTaxModal: false,
      showDocumentModal: false,
      openKmAuth: null,
      menuId: 408,
      subMenuId: 84,
      totalTaxableAmount: null,
      showTaxTypeModal: false,
      taxTypeData: [],
      taxTypeFields: [
        { key: "tax_type_id" },
        { key: "tax_rate_name", label: "Tax Type" },
      ],
      specIndex: null,
      previewTaxData: [],
      previewTaxFields: [
        { key: "tax_type" },
        { key: "taxable_amount" },
        { key: "tax_rate" },
        { key: "amount", label: "Tax Amount" },
        { key: "rcm_flag", label: "RCM" },
      ],
      showPreviewTaxModal: false,
      sumIndex: null,
      approvalDetails: [],
      isApprovalSent: false,
      taxData: {
        tax_rate_id: null,
        tax_type_id: null,
        tax_rate_type: null,
      },
      totalPayload: null,

      // Variables for Invoice Without PO
      invoiceWithoutPoData: [
        {
          item_description: null,
          price: null,
          deliver_qty: null,
          total_price: null,
          hsn_sac: null,
          tax_category_name: null,
        },
      ],
      invoiceWithoutPoFields: [
        {
          key: "add",
          stickyColumn: true,
        },
        { key: "remove", stickyColumn: true },
        {
          key: "item_description",
          label: "Description",
        },
        {
          key: "price",
        },
        {
          key: "deliver_qty",
          label: "Deliver Qty.",
        },
        {
          key: "total_price",
        },
        {
          key: "hsn_sac",
          label: "HSN/SAC",
        },
        // {
        //   key: "tax_category_name",
        //   label: "Tax Category",
        // },
        {
          key: "tax_details",
        },
      ],
      invoiceHeader: {
        inv_number: null,
        inv_amount: null,
        le_id: null,
        le_name: null,
        company_gstn_name: null,
        vendor_gstn_name: null,
        approval_status: "DRAFT",
        currency_id: null,
        currency: null,
        dif_amount: null,
        inv_date: null,
      },
      showInvoiceCommonModal: false,
      modalType: null,
      legalEntitySearch: "",
      legalEntityData: [],
      legalEntityFields: [
        {
          key: "name",
          label: "Legal Entity",
        },
      ],
      perPageLegalEntity: commonHelper.perPageRecord,
      currentPageLegalEntity: 1,
      totalRowsLegalEntity: null,
      companyGstnSearch: "",
      companyGstnData: [],
      companyGstnFields: [
        {
          key: "registration_number",
          label: "Company GST Number",
          class: "align-item-text",
        },
      ],
      perPageCompanyGstn: commonHelper.perPageRecord,
      currentPageCompanyGstn: 1,
      totalRowsCompanyGstn: null,
      vendorGstnSearch: "",
      vendorGstnData: [],
      vendorGstnFields: [
        {
          key: "registration_number",
          label: "Vendor GST Number",
          class: "align-item-text",
        },
      ],
      perPageVendorGstn: commonHelper.perPageRecord,
      currentPageVendorGstn: 1,
      totalRowsVendorGstn: null,
      currencySearch: "",
      currencyData: [],
      currencyFields: [
        {
          key: "currency_code",
          label: "Currency",
          class: "align-item-text",
        },
      ],
      perPageCurrency: commonHelper.perPageRecord,
      currentPageCurrency: 1,
      totalRowsCurrency: null,
      showTaxWithoutPoModal: false,
      taxWithoutPoData: [
        {
          tax_type_id: null,
          tax_type: null,
          tax_rate_percentage: null,
        },
      ],
      taxWithoutPoFields: [
        { key: "tax_type" },
        { key: "tax_rate_percentage", label: "Tax Rate" },
        { key: "add", stickyColumn: true },
        { key: "remove", stickyColumn: true },
      ],
      taxTypeWithoutPoData: [],
      taxTypeWithoutPoFields: [
        { key: "tax_type_id" },
        { key: "tax_type_code", label: "Tax Type" },
      ],
      taxRateWithoutPoData: [],
      taxRateWithoutPoFields: [
        { key: "tax_rate_percentage", class: "align-item-text" },
      ],
      perPageTaxRateWithoutPo: commonHelper.perPageRecord,
      totalRowsTaxRateWithoutPo: null,
      currentPageTaxRateWithoutPo: 1,
      showTaxCommonModal: false,
      tempTaxData: [],
    };
  },
  mounted() {
    this.vendorId = JSON.parse(sessionStorage.getItem("VendorId"));
    this.vendorName = JSON.parse(sessionStorage.getItem("VendorName"));
    if (this.$route.query.params && this.$route.query.params == "grid") {
      this.showViewInvoiceForm(false);
    }
  },
  methods: {
    isTaxValid() {
      if (this.newFunction()) {
        alert("Case Pass");
      }
    },
    newFunction() {
      this.tempTaxData = this.taxWithoutPoData.map((elem) => elem.tax_type);
      if (this.tempTaxData.includes("CGST")) {
        if (
          this.tempTaxData.includes("UGST") &&
          this.tempTaxData.includes("SGST")
        ) {
          if (this.tempTaxData.includes("IGST")) {
            return alert("Invalid Case IGST cannot apply with this case");
          } else {
            return true;
            // alert("Case Pass CGST with UGST and SGST");
          }
        } else if (
          this.tempTaxData.includes("UGST") ||
          this.tempTaxData.includes("SGST")
        ) {
          if (this.tempTaxData.includes("IGST")) {
            return alert("Invalid Case IGST cannot apply with this case");
          } else {
            return true;
            // alert("Case Pass CGST with SGST or UGST");
          }
        } else if (this.tempTaxData.includes("IGST")) {
          return alert("Invalid Case CGST cannot apply with IGST");
        } else if (
          !this.tempTaxData.includes("UGST") ||
          !this.tempTaxData.includes("SGST")
        ) {
          return alert("Invalid Case SGST and UGST Mandatory with CGST");
        }
      } else if (this.tempTaxData.includes("IGST")) {
        if (
          this.tempTaxData.includes("UGST") ||
          this.tempTaxData.includes("CGST") ||
          this.tempTaxData.includes("SGST")
        ) {
          return alert(
            "Invalid Case. UGST, CGST and SGST cannot apply with IGST"
          );
        } else {
          return true;
          // alert("Case Pass with IGST");
        }
      } else if (
        this.tempTaxData.includes("SGST") ||
        this.tempTaxData.includes("UGST")
      ) {
        return alert("Invalid Case. Please Select CGST with SGST and UGST");
      }
    },

    //
    validateHsnSac(item) {
      if (item.hsn_sac.length < 6) {
        alert("HSN Code Should between 6 to 8 digits.");
        item.hsn_sac = null;
      }
    },
    isAllDetailsAvail() {
      if (this.invoiceHeader.inv_number && this.invoiceHeader.inv_amount) {
        return true;
      } else {
        return false;
      }
    },
    savenNewFunction() {
      if (this.isAllDetailsAvail()) {
        if (this.invoiceHeader.dif_amount == 0) {
          const invoiceWithoutPo = this.invoiceWithoutPoData.map((elem) => {
            return {
              vendor_inv_dtl_id: elem.vendor_inv_dtl_id
                ? elem.vendor_inv_dtl_id
                : 0,
              item_description: elem.item_description,
              po_item_price: elem.price,
              deliver_item_qty: elem.deliver_qty,
              hsn_sac: elem.hsn_sac,
              uom: elem.uom,
              item_type: elem.item_type,
              tax_category_name: elem.tax_category_name,
            };
          });
          if (invoiceWithoutPo.length) {
            const payload = {
              vendor_id: this.vendorId,
              vendor_name: this.vendorName,
              vendor_invoice_id: this.venInvoiceId,
              inv_num: this.invoiceHeader.inv_number,
              inv_amt: this.invoiceHeader.inv_amount,
              inv_date: this.invoiceHeader.inv_date,
              le_id: this.invoiceHeader.le_id,
              first_party_gstn: this.invoiceHeader.company_gstn_name,
              third_party_gstn: this.invoiceHeader.vendor_gstn_name,
              approval_status: this.invoiceHeader.approval_status,
              currency: this.invoiceHeader.currency,
              dif_amount: Number(this.invoiceHeader.dif_amount),
            };
            // this.loader = true;
            // this.$store
            //   .dispatch("vendor/addEditVendorInvoiceDetails", payload)
            //   .then((response) => {
            //     this.loader = false;
            //     this.showAlert = true;
            //     if (response.status === 200) {
            //       const result = response.data.data;
            //       if (result) {
            //         alert("Invoice Created Successfully");
            //         this.venInvoiceId = result.vendor_inv_id;
            //         this.testingFunction("invoiceRowDtls", this.venInvoiceId);
            //       }
            //     } else {
            //       alert("Something went wrong");
            //       this.isSuccess = false;
            //       this.responseMsg = response.data.message;
            //     }
            //   })
            //   .catch(() => {
            //     alert("Something went wrong");
            //     this.loader = false;
            //     this.showAlert = true;
            //     this.isSuccess = false;
            //     this.responseMsg = appStrings.autoFailedMsg;
            //   });
          }
        } else {
          alert("Invoice Difference Amount should be 0.");
        }
      } else {
        alert("Please Enter all Details.");
      }
    },
    isInvNumValid() {
      if (this.poHeaderDetails.inv_number) {
        if (
          commonHelper.containsSpecialCharacters(
            this.poHeaderDetails.inv_number
          )
        ) {
          this.poHeaderDetails.inv_number = null;
          alert("Only / - are allowed as Special Characters in Invoice Number");
        }
      }
    },
    // isHeaderAvail() {
    //   const keysWithoutValue = {};
    //   const tempArr = [];
    //   for (const key in this.poHeaderDetails) {
    //     if (
    //       this.poHeaderDetails.hasOwnProperty(key) &&
    //       this.poHeaderDetails[key] === null
    //     ) {
    //       keysWithoutValue[key] = null;
    //       tempArr.push(key);
    //     }
    //   }
    // },
    isHeaderAvail() {
      const tempArr = ["invNum", "invAmt", "invDate"];
      let msg = null;
      for (let i = 0; i < tempArr.length; i++) {
        if (tempArr[i] === "invNum" && !this.poHeaderDetails.inv_number) {
          msg = "Please Select Invoice Number";
        } else if (
          tempArr[i] === "invAmt" &&
          !this.poHeaderDetails.inv_amount
        ) {
          if (msg) {
            msg = msg + ", Invoice Amount";
          } else {
            msg = "Please Select Invoice Amount";
          }
        } else if (tempArr[i] === "invDate" && !this.poHeaderDetails.inv_date) {
          if (msg) {
            msg = msg + " and Invoice Date";
          } else {
            msg = "Please Select Invoice Date";
          }
        }
      }
      if (msg) {
        alert(msg);
      } else {
        return true;
      }
    },
    validateInvoiceDetails() {
      if (this.isHeaderAvail()) {
        if (this.poHeaderDetails.dif_amount == 0) {
          if (this.venInvoiceId > 0) {
            this.addEditVendorInvoiceDetails();
          } else {
            this.invoiceNumberValidation();
          }
        } else {
          alert("Invoice Difference Amount should be 0.");
        }
      }
    },
    invoiceNumberValidation() {
      const payload = {
        invoice_number: this.poHeaderDetails.inv_number,
        invoice_date: this.poHeaderDetails.inv_date,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/invoiceNumberValidation", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            if (result == "false") {
              this.addEditVendorInvoiceDetails();
            } else {
              alert("Invoice Number already exist in the Same Financial Year");
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },

    addEditVendorInvoiceDetails() {
      // this.$v.$touch();
      // if (!this.$v.$invalid) {
      //   if (this.poHeaderDetails.inv_date) {
      if (this.isHeaderAvail()) {
        if (this.poHeaderDetails.dif_amount == 0) {
          const filterInvoiceDetails = this.newInvoiceDetailsData.map(
            (elem) => {
              return {
                vendor_inv_dtl_id: elem.vendor_inv_dtl_id
                  ? elem.vendor_inv_dtl_id
                  : 0,
                po_line_id: elem.po_line_id,
                po_line_num: elem.po_line_num,
                po_line_location_id: elem.po_line_location_id,
                po_release_id: elem.po_release_id,
                po_release_line_id: elem.po_release_line_id, //
                po_rel_num: elem.po_release_num,
                po_shipment_line_num: elem.po_shipment_line_num,
                item_id: elem.item_id,
                item_code: elem.item_code,
                po_item_price: elem.po_price,
                uom: elem.uom,
                po_item_qty: elem.quantity,
                deliver_item_qty: elem.deliver_qty,
                pending_qty: elem.pending_qty,
                hsn_sac: elem.hsn_sac,
                tax_cat_id: elem.tax_category_id,
                shp_to_loc: elem.shp_to_loc,
                item_type: elem.item_type,
                taxable_value: elem.taxable_value,
                tax_rate: elem.tax_rate,
                item_description: elem.item_description,
                po_distribution_id: elem.po_distribution_id,
                tax_category_name: elem.tax_category_name,
                tax_amount: Number(elem.tax_amount),
                tax_dtls: elem.tax_dtls,
                remarks: elem.remarks,
                note_to_vendor: elem.NOTE_TO_VENDOR,
              };
            }
          );
          if (filterInvoiceDetails.length) {
            const payload = {
              vendor_id: this.vendorId,
              vendor_name: this.vendorName,
              vendor_invoice_id: this.venInvoiceId,
              po_header_id: this.poHeaderDetails.po_header_id,
              po_num: this.poHeaderDetails.po_number,
              inv_num: this.poHeaderDetails.inv_number,
              inv_amt: this.poHeaderDetails.inv_amount,
              inv_date: this.poHeaderDetails.inv_date,
              first_party_gstn: this.poHeaderDetails.first_party_gstn,
              third_party_gstn: this.poHeaderDetails.third_party_gstn,
              po_creation_date: this.poHeaderDetails.po_creation_date,
              currency: this.poHeaderDetails.currency,
              payment_term: this.poHeaderDetails.payment_term,
              inv_agreement: this.radio,
              approval_status: this.poHeaderDetails.approval_status,
              dif_amount: Number(this.poHeaderDetails.dif_amount),
              ou_id: this.poHeaderDetails.org_id,
              vendor_site_id: this.poHeaderDetails.vendor_site_id,
              vendor_site_name: this.poHeaderDetails.vendor_site_name,
              buyer_name: this.poHeaderDetails.buyer_name,
              buyer_email_id: this.poHeaderDetails.buyer_email_id,
              buyer_emp_id: this.poHeaderDetails.buyer_emp_id,
              grn_creator_name: this.poHeaderDetails.grn_creator_name,
              grn_creator_email_id: this.poHeaderDetails.grn_creator_email_id,
              grn_creator_id: this.poHeaderDetails.grn_creator_id,
              po_status: this.poHeaderDetails.po_status,
              vendor_invoice_dtls: filterInvoiceDetails
                ? filterInvoiceDetails
                : null,
            };
            this.loader = true;
            this.$store
              .dispatch("vendor/addEditVendorInvoiceDetails", payload)
              .then((response) => {
                this.loader = false;
                if (response.status === 200) {
                  const result = response.data.data;
                  if (result) {
                    alert("Invoice Created Successfully");
                    this.venInvoiceId = result.vendor_inv_id;
                    this.poHeaderDetails.po_number = result.po_num;
                    // this.clearFieldsSwitch();
                    this.testingFunction("invoiceRowDtls", this.venInvoiceId);
                  }
                } else {
                  alert(response.data.message);
                }
              })
              .catch(() => {
                alert(response.data.message);
              });
          } else {
            alert("Please select any invoice.");
          }
        } else {
          alert("Invoice Difference Amount should be 0.");
        }
      }
      // }
      // else {
      //   alert("Please Enter Invoice Date");
      // }
      // }
    },
    getPoNumberDetailsById() {
      const payload = {
        vendor_id: this.vendorId,
        po_number: this.poNumberById,
        _page: this.currentPage - 1,
        _limit: this.perPage,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getPoNumberDetailsById", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.poNumberData = result;
            this.totalRows = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addMultiRow() {
      const filterRow = this.poLineDetailsData.filter((data) => data.select);
      const unfilterRow = this.poLineDetailsData.filter((data) => !data.select);
      if (filterRow.length) {
        const filterDeliveryQty = filterRow.filter((elem) => !elem.deliver_qty);
        if (filterDeliveryQty.length) {
          alert("Please Enter Deliver Qty for Selected Invoice.");
        } else {
          const isTaxAvail = filterRow.filter((ele) => !ele.tax_amount);
          if (isTaxAvail.length) {
            alert("Please Select Tax Details for Selected Invoice.");
          } else {
            const checkHsn = filterRow.filter(
              (ele) => ele.hsn_sac.length < 6 || ele.hsn_sac.length > 8
            );
            if (checkHsn.length) {
              alert("HSN Code Should between 6 to 8 digits.");
            } else {
              this.poLineDetailsData = unfilterRow;
              const tempData = filterRow.map((elem) => {
                elem.select = false;
                // elem.taxable_value = elem.deliver_qty * elem.po_price;
                // elem.igst = (elem.taxable_value * elem.tax_rate) / 100;
                // elem.sgst = elem.igst / 2;
                // elem.cgst = elem.igst / 2;
                return elem;
              });
              this.newInvoiceDetailsData.push(...tempData);
              this.calculateInvoiceAmount();
            }
          }
        }
      } else {
        alert("Please Select PO Line Details");
      }
    },
    removeMultiRow() {
      const filterRow = this.newInvoiceDetailsData.filter(
        (data) => data.select
      );
      const unfilterRow = this.newInvoiceDetailsData.filter(
        (data) => !data.select
      );
      if (filterRow.length) {
        this.newInvoiceDetailsData = unfilterRow;
        const tempData = filterRow.map((elem) => {
          elem.select = false;
          return elem;
        });
        this.poLineDetailsData.push(...tempData);
      } else {
        alert("Please Select Invoice");
      }
    },
    validateDeliverQty(item) {
      if (this.poHeaderDetails.inv_amount) {
        if (item.deliver_qty > item.pending_qty) {
          alert("Deliver Qty. should be Less than & Equal To Pending Qty.");
          item.deliver_qty = null;
        }
      } else {
        item.deliver_qty = null;
        alert("Please Enter Invoice Amount First.");
      }
    },
    showHidePoModal(flag) {
      this.showPoModal = flag;
      if (flag) {
        this.poNumberById = null;
        this.getPoNumberDetailsById();
      }
    },
    rowSelectedPoNumber(item) {
      // this.poHeaderDetails = item;
      this.poHeaderDetails.po_header_id = item.po_header_id;
      this.poHeaderDetails.po_number = item.po_number;
      this.poHeaderDetails.first_party_gstn = item.first_party_gstn;
      this.poHeaderDetails.third_party_gstn = item.third_party_gstn;
      this.poHeaderDetails.currency = item.currency;
      this.poHeaderDetails.payment_term = item.payment_term;
      this.poHeaderDetails.po_creation_date = item.po_creation_date;
      this.poHeaderDetails.org_id = item.org_id;
      this.poHeaderDetails.vendor_site_id = item.vendor_site_id;
      this.poHeaderDetails.vendor_site_name = item.vendor_site_name;
      this.poHeaderDetails.buyer_name = item.buyer_name;
      this.poHeaderDetails.buyer_email_id = item.buyer_email_id;
      this.poHeaderDetails.buyer_emp_id = item.buyer_emp_id;
      this.poHeaderDetails.po_status = item.po_status;
      this.poHeaderDetails.grn_creator_name = item.grn_creator_name;
      this.poHeaderDetails.grn_creator_email_id = item.grn_creator_email_id;
      this.poHeaderDetails.grn_creator_id = item.grn_creator_id;
      this.poHeaderDetails.inv_number = null;
      this.poHeaderDetails.inv_amount = null;
      this.poHeaderDetails.dif_amount = null;
      this.poHeaderDetails.inv_date = null;
      this.showPoModal = false;
      // this.getPoLineDetailsById();
      this.testingFunction("poRowDtls");
    },
    clearSearch() {
      this.poNumberById = null;
      this.getPoNumberDetailsById();
    },
    clearPoValues() {
      this.clearFieldsSwitch();
    },
    clearSwitch(type) {
      if (type) {
        this.clearFieldsSwitch();
      } else {
        this.clearWithoutPoFields();
      }
    },
    clearWithoutPoFields() {
      this.invoiceHeader = {
        inv_number: null,
        inv_amount: null,
        le_id: null,
        le_name: null,
        company_gstn_name: null,
        vendor_gstn_name: null,
        approval_status: "DRAFT",
        currency_id: null,
        currency: null,
        dif_amount: null,
        inv_date: null,
      };
      this.invoiceWithoutPoData = [
        {
          item_description: null,
          price: null,
          deliver_qty: null,
          total_price: null,
          hsn_sac: null,
          tax_category_name: null,
        },
      ];
    },
    disabledDates(date) {
      const restrictedFromDate = new Date(
        this.poHeaderDetails.po_creation_date
      );
      restrictedFromDate.setHours(0, 0, 0, 0);
      const restrictedToDate = new Date();
      restrictedToDate.setHours(0, 0, 0, 0);
      return date < restrictedFromDate || date > restrictedToDate;
      // return commonHelper.disabledDates(date, this.poHeaderDetails.po_creation_date);
    },
    showViewInvoiceForm(flag) {
      this.viewInvoice = flag;
      if (!flag) {
        this.getVendorInvoiceDetails();
      }
      this.clearFieldsSwitch();
    },
    getVendorInvoiceDetails() {
      const payload = {
        _page: this.currentPageGrid - 1,
        _limit: this.perPageGrid,
        po_num: this.searchFilters.poNumber,
        inv_num: this.searchFilters.invNumber,
        inv_amt_from: this.searchFilters.invAmountFrom,
        inv_amt_to: this.searchFilters.invAmountTo,
        approval_status: this.searchFilters.status.value,
        vendor_id: this.vendorId,
        from_date: this.searchFilters.fromDate,
        to_date: this.searchFilters.toDate,
      };
      this.totalPayload = payload;
      this.loader = true;
      this.$store
        .dispatch("vendor/getVendorInvoiceDetails", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.invoiceDetailsData = result;
            this.totalRowsGrid = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    downloadCustomExcel() {
      this.totalPayload._limit = this.totalRowsGrid;
      this.loader = true;
      this.$store
        .dispatch("vendor/getVendorInvoiceDetails", this.totalPayload)
        .then((resp) => {
          this.loader = false;
          const result = resp.data.data.page.map((elem) => {
            elem.inv_amt =
              elem.inv_amt !== 0
                ? commonHelper.formatAmount(elem.inv_amt)
                : elem.inv_amt;
            return elem;
          });
          const customDownloadData = result.map((obj) => ({
            legal_entity: obj.le_name,
            po_number: obj.po_num,
            invoice_no: obj.inv_num,
            invoice_amount: obj.inv_amt,
            company_gstn: obj.first_party_gstn,
            third_party_gstn: obj.third_party_gstn,
            currency: obj.currency,
            approval_status: obj.approval_status,
            invoice_date: obj.inv_date,
          }));
          if (customDownloadData) {
            commonHelper.downloadExcel(customDownloadData, "pending-invoice");
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null,
        };
        this.eventBus.$emit("valueSetCode", vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (
        this.vsetCode === appStrings.VALUESETTYPE.PARTY_REQ_APPROVAL_STATUS_VSET
      ) {
        this.searchFilters.status.text = item.value_meaning;
        this.searchFilters.status.value = item.value_code;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearFilters() {
      this.searchFilters = {
        poNumber: null,
        invNumber: null,
        invAmountFrom: null,
        invAmountTo: null,
        status: { text: null, value: null },
        fromDate: null,
        toDate: null,
      };
      this.getVendorInvoiceDetails();
    },
    rowSelectedGrid(item) {
      this.showViewInvoiceForm(true);
      // this.vendorInvoiceDetailsDataSet(item);
      this.poHeaderDetails.po_number = item.po_num;
      this.testingFunction("invoiceRowDtls", item.vendor_inv_id);
    },
    // vendorInvoiceDetailsDataSet(item) {
    //   this.poHeaderDetails.po_header_id = item.po_header_id;
    //   this.poHeaderDetails.po_number = item.po_num;
    //   this.poHeaderDetails.inv_number = item.inv_num;
    //   this.poHeaderDetails.inv_amount = item.inv_amt;
    //   this.poHeaderDetails.first_party_gstn = item.first_party_gstn;
    //   this.poHeaderDetails.third_party_gstn = item.third_party_gstn;
    //   this.poHeaderDetails.currency = item.currency;
    //   this.poHeaderDetails.status = item.inv_status;
    //   this.poHeaderDetails.payment_term = item.payment_term;
    //   this.poHeaderDetails.inv_date = item.inv_date;
    //   this.poHeaderDetails.po_creation_date = item.po_creation_date;
    //   this.poHeaderDetails.org_id = item.org_id;
    //   this.poHeaderDetails.dif_amount = item.dif_amount;
    //   this.venInvoiceId = item.vendor_inv_id;
    //   // this.getPoLineDetailsById();
    //   this.testingFunction("invoiceRowDtls", this.venInvoiceId);
    //   // this.getVendorInvoiceLineDetails(this.venInvoiceId);
    // },
    getVendorInvoiceLineDetails(invHdrId, poLineData) {
      const payload = {
        invHdrId: invHdrId,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getVendorInvoiceLineDetails", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            // this.newInvoiceDetailsData = result.invoice_dtls;
            this.poHeaderDetails.po_header_id = result.po_header_id;
            this.poHeaderDetails.po_number = result.po_num;
            this.poHeaderDetails.inv_number = result.inv_num;
            this.poHeaderDetails.inv_amount = result.inv_amt;
            this.poHeaderDetails.first_party_gstn = result.first_party_gstn;
            this.poHeaderDetails.third_party_gstn = result.third_party_gstn;
            this.poHeaderDetails.currency = result.currency;
            this.poHeaderDetails.approval_status = result.approval_status;
            this.poHeaderDetails.payment_term = result.payment_term;
            this.poHeaderDetails.inv_date = result.inv_date;
            this.poHeaderDetails.po_creation_date = result.po_creation_date;
            this.poHeaderDetails.org_id = result.ou_id;
            this.poHeaderDetails.dif_amount = result.dif_amount;
            this.poHeaderDetails.vendor_site_id = result.vendor_site_id;
            this.poHeaderDetails.vendor_site_name = result.vendor_site_name;
            this.poHeaderDetails.buyer_name = result.buyer_name;
            this.poHeaderDetails.buyer_email_id = result.buyer_email_id;
            this.poHeaderDetails.buyer_emp_id = result.buyer_emp_id;
            this.poHeaderDetails.grn_creator_name = result.grn_creator_name;
            this.poHeaderDetails.grn_creator_email_id =
              result.grn_creator_email_id;
            this.poHeaderDetails.grn_creator_id = result.grn_creator_id;
            this.poHeaderDetails.po_status = result.po_status;
            this.venInvoiceId = result.vendor_inv_id;
            const invoiceLineDetailsTesting = result.invoice_dtls;
            this.mapingPoInvoiceDtlsData(invoiceLineDetailsTesting, poLineData);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    mapingPoInvoiceDtlsData(invLineData, poLineData) {
      const invLineMapData = invLineData.map((ele) => ele.po_line_id);
      const invLineIdsSet = new Set(invLineMapData);
      const filteredArray = poLineData.filter(
        (ele) => !invLineIdsSet.has(ele.po_line_id)
      );
      this.newInvoiceDetailsData = invLineData;
      this.poLineDetailsData = filteredArray;
    },
    clearFieldsSwitch() {
      this.venInvoiceId = 0;
      this.poHeaderDetails = {
        po_header_id: null,
        po_number: null,
        inv_number: null,
        inv_amount: null,
        first_party_gstn: null,
        third_party_gstn: null,
        currency: null,
        approval_status: "DRAFT",
        dif_amount: null,
        payment_term: null,
        inv_date: null,
        po_creation_date: null,
        org_id: null,
        vendor_site_id: null,
        vendor_site_name: null,
        buyer_name: null,
        buyer_email_id: null,
        buyer_emp_id: null,
        grn_creator_name: null,
        grn_creator_id: null,
        grn_creator_email_id: null,
        po_status: null,
      };
      this.poLineDetailsData = [];
      this.newInvoiceDetailsData = [];
    },
    showHideTaxModal(flag, item, index) {
      if (flag) {
        if (item.deliver_qty) {
          this.showTaxModal = flag;
          this.sumIndex = index;
          if (item.vendor_inv_dtl_id) {
            this.taxDetailsData = item.tax_details;
            this.taxDetailsData = item.tax_details.map((elem) => {
              if (elem.manual !== "Y") {
                elem.taxable_amount = item.deliver_qty * item.po_price;
                elem.tax_rate_percentage = elem.tax_rate;
              } else {
                elem.taxable_amount = null;
              }
              elem.tax_amt = elem.amount;
              // elem.tax_amt =
              //   (elem.taxable_amount * elem.tax_rate_percentage) / 100;
              return elem;
            });
          } else {
            this.getTaxInvoiceDetails(item);
          }
        } else {
          alert("Please Enter Deliver Qty.");
        }
      }
    },
    getTaxInvoiceDetails(item) {
      const payload = {
        po_line_id: item.po_line_id,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getTaxInvoiceDetails", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data;
            this.taxDetailsData = result;
            this.taxDetailsData = result.map((elem) => {
              elem.taxable_amount = item.deliver_qty * item.po_price;
              elem.tax_amt =
                (elem.taxable_amount * elem.tax_rate_percentage) / 100;
              return elem;
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideDocumentModal(flag) {
      this.showDocumentModal = flag;
    },
    getOpenKmAuthenticate() {
      this.loader = true;
      this.$store
        .dispatch("uploadDocument/getOpenKmAuthenticate")
        .then((response) => {
          this.loader = false;
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            this.showHideDocumentModal(true);
          } else {
            alert(appStrings.DEPENDENTVSETMSG.DEFAULTMSG);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addTaxRow() {
      this.taxDetailsData.push({
        tax_type: null,
        tax_type_id: null,
        tax_rate_id: null,
        taxable_amount: null,
        tax_rate_percentage: null,
        tax_amt: null,
        tax_rate_type: null,
        manual: "Y",
      });
    },
    removeTaxRow(index) {
      this.taxDetailsData.splice(index, 1);
    },
    addEditTaxDetails() {
      const filterData = this.taxDetailsData.filter(
        (data) => data.tax_type && !data.tax_amt
      );
      if (!filterData.length) {
        const filterTaxAmt = this.taxDetailsData.filter((ele) => ele.tax_amt);
        const result = filterTaxAmt.map((elem) => elem.tax_amt);
        const total = commonHelper.calculateTotal(result);
        if (total) {
          this.poLineDetailsData[this.sumIndex].tax_amount = parseFloat(
            total
          ).toFixed(2);
        }
        const taxDetails = filterTaxAmt.map((elem) => {
          return {
            vendor_inv_tax_id: elem.vendor_inv_tax_id
              ? elem.vendor_inv_tax_id
              : elem.vnd_inv_tax_id
              ? elem.vnd_inv_tax_id
              : 0,
            tax_rate_id: elem.tax_rate_id,
            tax_rate: elem.tax_rate_percentage,
            tax_type_id: elem.tax_type_id,
            tax_type: elem.tax_type,
            tax_rate_type: elem.tax_rate_type,
            rcm_flag: elem.rcm_flag,
            amount: Number(elem.tax_amt),
            taxable_amount: elem.taxable_amount,
            manual: elem.manual == "Y" ? "Y" : elem.manual == "N" ? "N" : null,
          };
        });
        if (taxDetails.length) {
          this.poLineDetailsData[this.sumIndex].tax_dtls = taxDetails;
          this.showTaxModal = false;
        }
      } else {
        alert("Please Enter Tax Amount");
      }
    },
    showHideTaxTypeModal(flag, index) {
      this.showTaxTypeModal = flag;
      this.specIndex = index;
      if (flag) {
        this.getTaxTypeValueSet();
      }
    },
    getTaxTypeValueSet() {
      const payload = {
        org_id: this.poHeaderDetails.org_id,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getTaxTypeValueSet", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data;
            this.taxTypeData = result;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelectedTaxType(item) {
      this.taxDetailsData[this.specIndex].tax_type = item.tax_rate_name;
      // this.taxDetailsData[this.specIndex].taxable_amount = this.taxDetailsData[
      //   this.specIndex - 1
      // ].taxable_amount;
      this.taxDetailsData[this.specIndex].tax_rate_id = item.tax_rate_id;
      this.taxDetailsData[this.specIndex].tax_type_id = item.tax_type_id;
      this.taxDetailsData[this.specIndex].tax_rate_type = item.tax_rate_type;
      this.showHideTaxTypeModal(false, null);
    },
    testingFunction(selected, vendorInvoiceId) {
      if (selected == "poRowDtls") {
        const payload = {
          _page: 0,
          _limit: 50,
          po_num: this.poHeaderDetails.po_number,
        };
        this.loader = true;
        this.$store
          .dispatch("vendor/getPoLineDetailsById", payload)
          .then((resp) => {
            this.loader = false;
            if (resp.status === 200) {
              const result = resp.data.data.data.page;
              // this.poLineDetailsData = result;
              this.poLineDetailsData = result.map((data) => {
                data.tax_details = "Details";
                return data;
              });
              this.newInvoiceDetailsData = [];
              this.getPendingQtyforInvoice();
              // this.totalRows = resp.data.data.data.total_elements;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      } else if (selected == "invoiceRowDtls" && vendorInvoiceId) {
        const payload = {
          _page: 0,
          _limit: 50,
          po_num: this.poHeaderDetails.po_number,
        };
        this.loader = true;
        this.$store
          .dispatch("vendor/getPoLineDetailsById", payload)
          .then((resp) => {
            this.loader = false;
            if (resp.status === 200) {
              const result = resp.data.data.data.page;
              // this.poLineDetailsData = result;
              const poLineDetailsTesting = result.map((data) => {
                data.tax_details = "Details";
                return data;
              });
              this.getVendorInvoiceLineDetails(
                vendorInvoiceId,
                poLineDetailsTesting
              );
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    showHidePreviewTaxModal(flag, item) {
      this.showPreviewTaxModal = flag;
      if (flag) {
        this.previewTaxData = item.tax_dtls ? item.tax_dtls : item.tax_details;
        this.previewTaxData.map((ele) => {
          if (ele.manual !== "Y") {
            ele.taxable_amount = item.deliver_qty * item.po_price;
          } else {
            ele.taxable_amount = null;
          }
          // ele.amount = (ele.taxable_amount * ele.tax_rate) / 100;
        });
      }
    },
    validateDocument() {
      this.currentPage = 1;
      this.perPage = commonHelper.perPageRecord;
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        menu_id: this.menuId,
        sub_menu_id: this.subMenuId,
        record_id: this.venInvoiceId,
      };
      this.loader = true;
      this.$store
        .dispatch("uploadDocument/getUploadedDocDtl", payload)
        .then((response) => {
          this.loader = false;
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            const result = response.data.data.page;
            if (result.length >= 2) {
              this.sendWorkflowProcess();
            } else {
              alert(
                "Please Upload the Documents:-\nItems Supply :- Need to upload Invoice Copy & Proof Of Delivery (POD)\nService Items :- Invoice Copy and Work Completed Certificate (WCC)"
              );
            }
          }
        })
        .catch(() => {
          this.loader = false;
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    sendWorkflowProcess() {
      const payload = {
        event_id: 34,
        record_id: this.venInvoiceId,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/sendWorkflowProcess", payload)
        .then((resp) => {
          this.loader = false;
          this.showAlert = true;
          if (resp.status === 200) {
            this.isSuccess = true;
            this.responseMsg = resp.data.message;
            this.approvalDetails = resp.data.data;
            this.sendWorkflowApproval();
          } else {
            alert(resp.data.message);
          }
        })
        .catch(() => {
          this.loader = false;
          alert(resp.data.message);
        });
    },
    sendWorkflowApproval() {
      const payload = {
        event_id: 34,
        record_id: this.venInvoiceId,
        approval_details: this.approvalDetails,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/sendWorkflowApproval", payload)
        .then((resp) => {
          this.loader = false;
          this.showAlert = true;
          if (resp.status === 200) {
            this.isApprovalSent = true;
            this.isSuccess = true;
            this.responseMsg = resp.data.message;
            alert("Approval Sent Successfully.");
            this.testingFunction("invoiceRowDtls", this.venInvoiceId);
          } else {
            alert(resp.data.message);
          }
        })
        .catch(() => {
          this.loader = false;
          alert(resp.data.message);
        });
    },
    calculateInvoiceAmount() {
      const filterDeliverQty = this.newInvoiceDetailsData.filter(
        (elem) => elem.deliver_qty
      );
      const taxableAmount = filterDeliverQty.map(
        (data) => data.deliver_qty * data.po_price
      );
      const totalTaxableAmount = commonHelper.calculateTotal(taxableAmount);
      const filterTaxAmount = this.newInvoiceDetailsData.filter(
        (elem) => elem.tax_amount
      );
      const taxAmount = filterTaxAmount.map((data) => data.tax_amount);
      const totalTaxAmount = commonHelper.calculateTotal(taxAmount);
      this.poHeaderDetails.dif_amount =
        this.poHeaderDetails.inv_amount -
        (Number(totalTaxableAmount) + Number(totalTaxAmount));
      this.poHeaderDetails.dif_amount = this.poHeaderDetails.dif_amount.toFixed(
        2
      );
    },
    clearDeliveryQty() {
      if (this.newInvoiceDetailsData && this.newInvoiceDetailsData.length) {
        this.calculateInvoiceAmount();
      }
      this.poLineDetailsData.map((data) => {
        data.deliver_qty = null;
        return data;
      });
      this.poLineDetailsData = JSON.parse(
        JSON.stringify(this.poLineDetailsData)
      );
    },
    getPendingQtyforInvoice() {
      const payload = {
        po_num: this.poHeaderDetails.po_number,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getPendingQtyforInvoice", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            for (let i = 0; i < this.poLineDetailsData.length; i++) {
              for (let j = 0; j < result.length; j++) {
                if (
                  this.poLineDetailsData[i].po_line_location_id ===
                  result[j].po_line_location_id
                ) {
                  this.poLineDetailsData[i].pending_qty =
                    result[j].realapp_pending_quantity;
                }
              }
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    // getPendingQtyforInvoice() {
    //   const payload = {
    //     po_num: this.poHeaderDetails.po_number,
    //   };
    //   this.loader = true;
    //   this.$store
    //     .dispatch("vendor/getPendingQtyforInvoice", payload)
    //     .then((resp) => {
    //       this.loader = false;
    //       if (resp.status === 200) {
    //         const result = resp.data.data;
    //         if (result.length) {
    //           this.poLineDetailsData.forEach((elem, index) => {
    //             const key = result[index];
    //             if (
    //               key &&
    //               elem.po_line_location_id === key.po_line_location_id
    //             ) {
    //               elem.pending_qty = key.realapp_pending_quantity;
    //             } else {
    //               elem.pending_qty = elem.quantity;
    //             }
    //           });
    //         }
    //       }
    //     })
    //     .catch(() => {
    //       this.loader = false;
    //     });
    // },
    formattedCurrency(num) {
      return commonHelper.formatAmount(num);
    },
    // Function Starting for Invoice Without PO
    addVendorInvoiceLine() {
      this.invoiceWithoutPoData.push({
        item_description: null,
        price: null,
        deliver_qty: null,
        total_price: null,
        hsn_sac: null,
      });
    },
    removeVendorInvoiceLine(index) {
      this.invoiceWithoutPoData.splice(index, 1);
    },
    showHideInvoiceCommonModal(flag, type) {
      this.modalType = type;
      if (this.modalType === "legal_entity") {
        this.getLegalEntityVendorInvoice();
      }
      if (this.modalType === "company_gstn") {
        this.getCompanyGstnVendorInvoice();
      }
      if (this.modalType === "vendor_gstn") {
        this.getVendorGstnVendorInvoice();
      }
      if (this.modalType === "currency_code") {
        this.getCurrencyVendorInvoice();
      }
      this.showInvoiceCommonModal = flag;
      if (!flag) {
        this.legalEntitySearch = "";
        this.companyGstnSearch = "";
        this.vendorGstnSearch = "";
        this.currencySearch = "";
      }
    },
    rowSelectedLegalEntity(item) {
      this.invoiceHeader.le_id = item.legal_entity_id;
      this.invoiceHeader.le_name = item.name;
      this.showInvoiceCommonModal = false;
      this.perPageLegalEntity = commonHelper.perPageRecord;
      this.currentPageLegalEntity = 1;
      this.totalRowsLegalEntity = null;
    },
    rowSelectedCompanyGstn(item) {
      this.invoiceHeader.company_gstn_name = item.registration_number;
      this.showInvoiceCommonModal = false;
      this.perPageCompanyGstn = commonHelper.perPageRecord;
      this.currentPageCompanyGstn = 1;
      this.totalRowsCompanyGstn = null;
    },
    rowSelectedVendorGstn(item) {
      this.invoiceHeader.vendor_gstn_name = item.registration_number;
      this.showInvoiceCommonModal = false;
      this.perPageVendorGstn = commonHelper.perPageRecord;
      this.currentPageVendorGstn = 1;
      this.totalRowsVendorGstn = null;
    },
    rowSelectedCurrency(item) {
      this.invoiceHeader.currency = item.currency_code;
      this.showInvoiceCommonModal = false;
      this.perPageCurrency = commonHelper.perPageRecord;
      this.currentPageCurrency = 1;
      this.totalRowsCurrency = null;
    },
    getLegalEntityVendorInvoice() {
      const payload = {
        _page: this.currentPageLegalEntity - 1,
        _limit: this.perPageLegalEntity,
        le_name: this.legalEntitySearch,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getLegalEntityVendorInvoice", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.legalEntityData = result;
            this.totalRowsLegalEntity = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getCompanyGstnVendorInvoice() {
      const payload = {
        _page: this.currentPageCompanyGstn - 1,
        _limit: this.perPageCompanyGstn,
        legal_entity: this.invoiceHeader.le_id,
        invoice_date: this.invoiceHeader.inv_date,
        company_gstn: this.companyGstnSearch,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getCompanyGstnVendorInvoice", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.companyGstnData = result;
            this.totalRowsCompanyGstn = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getVendorGstnVendorInvoice() {
      const payload = {
        _page: this.currentPageVendorGstn - 1,
        _limit: this.perPageVendorGstn,
        le_id: this.invoiceHeader.le_id,
        vendor_id: this.vendorId,
        invoice_date: this.invoiceHeader.inv_date,
        vendor_gstn: this.vendorGstnSearch,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getVendorGstnVendorInvoice", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.vendorGstnData = result;
            this.totalRowsVendorGstn = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getCurrencyVendorInvoice() {
      const payload = {
        _page: this.currentPageCurrency - 1,
        _limit: this.perPageCurrency,
        currency: this.currencySearch,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getCurrencyVendorInvoice", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.currencyData = result;
            this.totalRowsCurrency = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideTaxCommonModal(flag, type, index) {
      this.modalType = type;
      this.specIndex = index;
      if (this.modalType === "taxType") {
        this.getTaxTypeVendorInvoice();
      }
      if (this.modalType === "taxRate") {
        this.getTaxRateVendorInvoice();
      }
      this.showTaxCommonModal = flag;
    },
    getTaxTypeVendorInvoice() {
      this.loader = true;
      this.$store
        .dispatch("vendor/getTaxTypeVendorInvoice")
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data;
            this.taxTypeWithoutPoData = result;
            // this.totalRows = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getTaxRateVendorInvoice() {
      const payload = {
        _page: this.currentPageCurrency - 1,
        _limit: this.perPageCurrency,
        tax_type_id: this.taxWithoutPoData[this.specIndex].tax_type_id,
        legal_entity: this.invoiceHeader.le_id,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getTaxRateVendorInvoice", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.taxRateWithoutPoData = result;
            this.totalRowsTaxRateWithoutPo = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelectedTaxTypeWithoutPo(item) {
      this.taxWithoutPoData[this.specIndex].tax_type = item.tax_type_code;
      this.taxWithoutPoData[this.specIndex].tax_type_id = item.tax_type_id;
      this.taxWithoutPoData[this.specIndex].tax_rate_percentage = null;
      this.taxWithoutPoData = JSON.parse(JSON.stringify(this.taxWithoutPoData));
      this.showTaxCommonModal = false;
    },
    rowSelectedTaxRateWithoutPo(item) {
      this.taxWithoutPoData[this.specIndex].tax_rate_percentage =
        item.tax_rate_percentage;
      this.taxWithoutPoData = JSON.parse(JSON.stringify(this.taxWithoutPoData));
      this.showTaxCommonModal = false;
    },

    calculateTotalAmount(item, type) {
      if (type === "price") {
        if (item.price) {
          if (item.price > 0) {
            // item.total_price = item.deliver_qty
            //   ? item.price * item.deliver_qty
            //   : null;
            if (item.deliver_qty) {
              item.total_price = item.price * item.deliver_qty;
            } else {
              item.total_price = null;
            }
          } else {
            alert("Item price cannot be 0");
            item.price = null;
          }
        }
      }
      if (type === "deliver_qty") {
        if (item.deliver_qty) {
          item.total_price = item.price * item.deliver_qty;
        } else {
          item.total_price = null;
        }
      }
    },
    gstnBasedOnLeInvDate(gstnTpe) {
      this.invoiceHeader.inv_date && this.invoiceHeader.le_name
        ? gstnTpe === "compGstn"
          ? this.showHideInvoiceCommonModal(true, "company_gstn")
          : gstnTpe === "vendorGstn"
          ? this.showHideInvoiceCommonModal(true, "vendor_gstn")
          : ""
        : !this.invoiceHeader.inv_date && this.invoiceHeader.le_name
        ? alert("Please Select Invoice Date.")
        : this.invoiceHeader.inv_date && !this.invoiceHeader.le_name
        ? alert("Please Select Legal Entity.")
        : alert("Please Select Invoice Date and Legal entity.");
    },
    //
    show1HideTaxModal(flag, item, index) {
      if (flag) {
        if (item.deliver_qty) {
          this.showTaxModal = flag;
          this.sumIndex = index;
          if (item.vendor_inv_dtl_id) {
            this.taxDetailsData = item.tax_details;
            this.taxDetailsData = item.tax_details.map((elem) => {
              if (elem.manual !== "Y") {
                elem.taxable_amount = item.deliver_qty * item.po_price;
                elem.tax_rate_percentage = elem.tax_rate;
              } else {
                elem.taxable_amount = null;
              }
              elem.tax_amt = elem.amount;
              // elem.tax_amt =
              //   (elem.taxable_amount * elem.tax_rate_percentage) / 100;
              return elem;
            });
          } else {
            this.getTaxInvoiceDetails(item);
          }
        } else {
          alert("Please Enter Deliver Qty.");
        }
      }
    },
    //
    showHideTaxWithoutPoModal(flag, item) {
      if (flag) {
        if (item.deliver_qty > 0) {
          this.showTaxWithoutPoModal = flag;
        } else {
          if (item.deliver_qty) {
            alert("Deliver Qty. Cannot be 0");
          } else {
            alert("Please Enter Deliver Qty.");
          }
        }
      }
    },
    addTaxWithoutPoRow() {
      this.taxWithoutPoData.push({
        tax_type_id: null,
        tax_type: null,
        tax_rate_percentage: null,
      });
    },
    removeTaxWithoutPoRow(index) {
      this.taxWithoutPoData.splice(index, 1);
    },
    clearFieldsWithoutPo(item) {
      if (item == "legal_entity") {
        this.invoiceHeader.le_id = null;
        this.invoiceHeader.le_name = null;
      }
    },
    // Function Ending for Invoice Without PO
    //
    addMultipleRow() {
      const filterSelectedData = this.poLineDetailsData.filter(
        (elem) => elem.select
      );
      const filterUnselectedData = this.poLineDetailsData.filter(
        (data) => !data.select
      );
      if (filterSelectedData.length) {
        filterSelectedData.map((elem) => {
          elem.select_box = false;
          elem.select = false;
          return elem;
        });
        filterUnselectedData.map((elem) => {
          elem.select_box = false;
          elem.select = false;
          return elem;
        });
        // filterSelectedData.forEach((ele) => {
        //   ele.select_box = false;
        //   ele.select = false;
        // });
        // filterUnselectedData.forEach((ele) => {
        //   ele.select_box = false;
        //   ele.select = false;
        // });
        this.poLineDetailsData = filterUnselectedData;
        this.newInvoiceDetailsData.push(...filterSelectedData);
      } else {
        alert("Please Select Invoice");
      }
    },
    removeMultipleRow() {
      const filterSelectedData = this.newInvoiceDetailsData.filter(
        (elem) => elem.select1
      );
      const filterUnselectedData = this.newInvoiceDetailsData.filter(
        (elem) => !elem.select1
      );
      if (filterSelectedData.length) {
        filterSelectedData.forEach(
          (ele) => ((ele.select_box = false), (ele.select1 = false))
        );
        filterUnselectedData.forEach(
          (ele) => ((ele.select_box = false), (ele.select1 = false))
        );
        this.newInvoiceDetailsData = filterUnselectedData;
        this.poLineDetailsData.push(...filterSelectedData);
      } else {
        alert("Please Select Invoice");
      }
    },
    getPoLineDetailsById() {
      const payload = {
        _page: 0,
        _limit: 50,
        po_num: this.poHeaderDetails.po_number,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getPoLineDetailsById", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            // this.poLineDetailsData = result;
            this.poLineDetailsData = result.map((data) => {
              data.tax_details = "Details";
              return data;
            });
            // this.totalRows = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    truncateComment(souce, size) {
      return commonHelper.truncate(souce, size);
    },
    upperCaseFunction(invNum) {
      this.poHeaderDetails.inv_number = invNum.toUpperCase();
    },
  },
};
</script>
<style>
.item-col > div {
  width: 40px !important;
  margin-left: 5px !important;
}
.width-fix > div {
  width: 85px !important;
}
.poItem-col > div {
  margin-left: 2%;
}
.font-fix {
  font-size: 14px !important;
}
.width-fix-des > div {
  width: 250px !important;
}
.text-fix-invamt {
  margin-right: 25% !important;
  text-align: right !important;
}
.pagination-count1 {
  margin-top: -4%;
  font-size: 0.9rem;
}
.align-item-text {
  text-align: left !important;
}
</style>
