<template>
  <md-card-content>
    <div class="overlayLoader" v-if="loader">
      <md-progress-spinner
        :md-diameter="100"
        class="md-accent spinner-postion"
        :md-stroke="7"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <b-alert
      style="width:50%;"
      :variant="isSuccess ? 'primary' : 'danger'"
      :show="showAlert"
      dismissible
      fade
      @dismissed="showAlert = false"
      >{{ responseMsg }}
    </b-alert>
    <div class="md-layout">
      <div class="md-layout-item md-small-size-100 md-size-33 position-rel">
        <md-field>
          <label class="mandatory" for="search">Address Type</label>
          <md-input
            v-model="addressType.text"
            :title="addressType.text"
            readonly
            @click="openValueSetModal('ADDRESS_TYPE')"
            :disabled="!editMode"
          >
          </md-input>
        </md-field>
        <div class="error" v-if="$v.addressType.text.$error">
          This field is required
        </div>

        <span
          aria-hidden="true"
          class="clearable-icon"
          v-if="addressType.text && editMode"
          @click="clearVsetValues(addressType.value)"
          >×</span
        >
      </div>
      <div class="md-layout-item md-small-size-100 md-size-33">
        <md-field>
          <label class="mandatory" for="search">Address 1</label>
          <md-input
            v-model="address1"
            :title="address1"
            maxlength="30"
            :disabled="!editMode"
          >
          </md-input>
        </md-field>
        <div class="error" v-if="$v.address1.$error">
          This field is required
        </div>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-33">
        <md-field>
          <label for="search">Address 2</label>
          <md-input
            v-model="address2"
            :title="address2"
            maxlength="30"
            :disabled="!editMode"
          >
          </md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-33 position-rel">
        <md-field>
          <label class="mandatory" for="search">Country</label>
          <md-input
            v-model="country.text"
            :title="country.text"
            readonly
            @click="openValueSetModal('SELECT_COUNTRY')"
            :disabled="!editMode"
          >
          </md-input>
        </md-field>
        <div class="error" v-if="$v.country.text.$error">
          This field is required
        </div>
        <span
          aria-hidden="true"
          class="clearable-icon"
          v-if="country.text && editMode"
          @click="clearVsetValues(country.value)"
          >×</span
        >
      </div>
      <div class="md-layout-item md-small-size-100 md-size-33 position-rel">
        <md-field>
          <label class="mandatory" for="search">State</label>
          <md-input
            v-model="state.text"
            :title="state.text"
            readonly
            @click="openValueSetModal('SELECT_STATE')"
            :disabled="!country.value || !editMode"
          >
          </md-input>
        </md-field>
        <div class="error" v-if="$v.state.text.$error">
          This field is required
        </div>
        <span
          aria-hidden="true"
          class="clearable-icon"
          v-if="state.text && editMode"
          @click="clearVsetValues(state.value)"
          >×</span
        >
      </div>
      <div class="md-layout-item md-small-size-100 md-size-33 position-rel">
        <md-field>
          <label class="mandatory" for="search">City</label>
          <md-input
            v-model="city.text"
            :title="city.text"
            readonly
            @click="openValueSetModal('SELECT_CITY')"
            :disabled="!state.value || !editMode"
          >
          </md-input>
        </md-field>
        <div class="error" v-if="$v.city.text.$error">
          This field is required
        </div>
        <span
          aria-hidden="true"
          class="clearable-icon"
          v-if="city.text && editMode"
          @click="clearVsetValues(city.value)"
          >×</span
        >
      </div>
      <div class="md-layout-item md-small-size-100 md-size-33 position-rel">
        <md-field>
          <label class="mandatory" for="search">Pin Code</label>
          <md-input
            v-model="pincode.text"
            :title="pincode.text"
            readonly
            @click="openValueSetModal('SELECT_PINCODE')"
            :disabled="!city.value || !editMode"
          >
          </md-input>
        </md-field>
        <div class="error" v-if="$v.pincode.text.$error">
          This field is required
        </div>
        <span
          aria-hidden="true"
          class="clearable-icon"
          v-if="pincode.text && editMode"
          @click="clearVsetValues(pincode.value)"
          >×</span
        >
      </div>
      <div class="md-layout-item md-small-size-100 md-size-33">
        <md-field>
          <label for="search">GSTN</label>
          <md-input
            v-model="gstn"
            :title="gstn"
            maxlength="15"
            @input="upperCaseFunction(gstn)"
            :disabled="gstnNotAvail || !editMode"
          >
          </md-input>
        </md-field>
      </div>

      <div
        style="margin-top:10px;"
        class="md-layout-item md-small-size-100 md-size-33"
      >
        <md-checkbox
          v-model="gstnNotAvail"
          :true-value="true"
          :false-value="false"
          @change="gstDefaultValue(gstnNotAvail)"
          :disabled="!editMode || isPanAvail"
          >GST Not Available</md-checkbox
        >
      </div>
      <div
        style="margin-top:10px;"
        class="md-layout-item md-small-size-100 md-size-33"
      >
        <md-checkbox
          v-model="sez"
          :true-value="true"
          :false-value="false"
          :disabled="!editMode"
          >SEZ</md-checkbox
        >
      </div>

      <div class="md-layout-item md-small-size-100 md-size-33 position-rel">
        <md-field>
          <label class="" for="search">TDS Section</label>
          <md-input
            v-model="tdsSection.text"
            :title="tdsSection.text"
            readonly
            @click="openValueSetModal('TDS_SECTION_ACTUAL')"
            :disabled="tdsNotAvail || !editMode"
          >
          </md-input>
        </md-field>
        <!-- <div class="error" v-if="$v.tdsSection.text.$error">
          This field is required
        </div> -->
        <span
          aria-hidden="true"
          class="clearable-icon"
          v-if="tdsSection.text && editMode"
          @click="clearVsetValues(tdsSection.value)"
          >×</span
        >
      </div>
      <div
        style="margin-top:10px;"
        class="md-layout-item md-small-size-100 md-size-33"
      >
        <md-checkbox
          v-model="tdsNotAvail"
          :true-value="true"
          :false-value="false"
          @change="tdsDefaultValue(tdsNotAvail)"
          :disabled="!editMode"
          >TDS Not Available
        </md-checkbox>
      </div>
    </div>
    <div class="md-layout" v-if="isAddressSaved && !isDocumentUploaded">
      <span v-if="gstn && !gstnNotAvail" style="color:red;">
        <b>*Maximum file size 5 MB</b><br />
        <b>*only pdf, jpg, jpeg, png, zip file allowed</b>
      </span>
    </div>
    <div class="md-layout" v-if="isAddressSaved && !isDocumentUploaded">
      <div
        v-if="gstn && !gstnNotAvail"
        class="md-layout-item md-small-size-100 md-size-33 position-rel"
      >
        <md-field md-clearable>
          <label>GST Certificate</label>
          <md-file required v-model="single" @md-change="selectFile" />
        </md-field>
      </div>
      <div>
        <md-button
          style="margin-top:20px; margin-left:15px;"
          class="md-raised md-primary search-btn"
          v-if="single"
          @click="getOpenKmAuthenticate()"
          >Upload Document</md-button
        >
      </div>
    </div>
    <div v-if="isDocumentUploaded" class="md-layout">
      <div style="margin-top:1.5rem;" class="md-layout-item md-size-50">
        <b>Certificate Name: </b>
        <span style="cursor:pointer;">
          <a
            style="margin-left:0.5rem;"
            target="_blank"
            :href="
              downloadDocUrl +
                '/download/' +
                docDetailId +
                '?token=' +
                openKmAuth
            "
            ><b>{{ fileName }}</b></a
          >
        </span>
      </div>
      <div
        style="margin-top:1rem;"
        class="md-layout-item md-size-50"
        v-if="approvalStatus === 'DRAFT' || approvalStatus === 'REJECTED'"
      >
        <b style="margin-left:1rem;">Delete Document: </b>
        <span
          class="material-icons"
          style="cursor:pointer; margin-left:2rem;"
          @click="deleteDocument(docDetailId, openKmAuth)"
          >delete</span
        >
      </div>
      <!-- <span style="margin-top:1.8rem;">
        <a
          href="#"
          style="margin-left:4rem;"
          @click="showHideOpenModal(true, docDetailId, openKmAuth, fileName)"
          ><b>Preview Document</b></a
        >
      </span> -->
      <b-modal
        v-model="showOpenModal"
        no-close-on-esc
        no-close-on-backdrop
        hide-footer
        size="xl"
        @hidden="showHideOpenModal(false)"
        title="Preview"
      >
        <previewDoc
          :authToken="authToken"
          :docDtlId="docDtlId"
          :uploadedFileName="uploadedFileName"
        ></previewDoc>
      </b-modal>
    </div>
    <md-button
      style="margin-top:20px; margin-left:15px;"
      class="md-raised md-primary search-btn"
      @click="validateGst()"
      v-if="!isAddressSaved"
      >Save</md-button
    >
    <!-- <md-button
      style="margin-top:20px; margin-left:15px;"
      class="md-raised md-primary search-btn"
      @click="validateGst()"
      >Validate GST</md-button
    > -->
    <md-button
      style="margin-top:20px; margin-left:15px;"
      class="md-raised md-primary search-btn"
      @click="editModeAddress()"
      v-if="!editMode"
      >Edit</md-button
    >
    <valueSetModal
      v-if="showValueSetModal"
      @selectedvalueSet="selectedvalueSet"
      @closeValueSetModal="closeValueSetModal"
      :parent_value_set_id="parent_value_set_id"
    ></valueSetModal>
  </md-card-content>
</template>

<script>
import appStrings from "../../../utility/string.utility";
import URL_UTILITY from "../../../utility/url.utility";
import { required } from "vuelidate/lib/validators";
import previewDoc from "../PreviewDoc/PreviewDoc.vue";
import commonHelper from "../../../utility/common.helper.utility";
export default {
  components: { previewDoc },
  name: "PartyOnboardingAddress",
  props: ["addressRowDetails", "approvalStatus", "vendorPan"],
  data() {
    return {
      showAlert: false,
      loader: false,
      isSuccess: false,
      responseMsg: "",
      editMode: false,
      isAddressSaved: false,
      addressId: 0,
      addressType: { value: null, text: null },
      address1: null,
      address2: null,
      country: { value: null, text: null },
      state: { value: null, text: null },
      city: { value: null, text: null },
      pincode: { value: null, text: null },
      gstn: null,
      gstnNotAvail: false,
      sez: false,
      tdsSection: { value: null, text: null },
      tdsNotAvail: false,
      showValueSetModal: false,
      parent_value_set_id: null,
      single: null,
      isDocumentUploaded: false,
      openKmAuth: null,
      fileRef: null,
      menuId: 374,
      submenuId: 79,
      serviceUser: "REALAPP",
      ipAddress: "182.18.157.99",
      verifyGstData: {},
      docDetailId: null,
      downloadDocUrl: URL_UTILITY.getDocumentDownloadUrl,
      previewDocUrl: URL_UTILITY.getDocumentPreviewUrl,
      fileName: null,
      isPanAvail: false,
      uploadedFileName: null,
      showOpenModal: false,
      docDtlId: null,
      authToken: null,
      addressMenu: {},
    };
  },
  validations: {
    addressType: {
      text: {
        required,
      },
    },
    country: {
      text: {
        required,
      },
    },
    state: {
      text: {
        required,
      },
    },
    city: {
      text: {
        required,
      },
    },
    pincode: {
      text: {
        required,
      },
    },
    // tdsSection: {
    //   text: {
    //     required,
    //   },
    // },
    address1: {
      required,
    },
  },
  mounted() {
    if (this.vendorPan && this.vendorPan === appStrings.DEFAULTPAN) {
      this.gstnNotAvail = true;
      this.gstn = appStrings.DEFAULTGST;
      this.isPanAvail = true;
    }
    if (this.addressRowDetails) {
      this.fillRecordForAddressDetails(this.addressRowDetails);
      this.editMode = false;
      this.isAddressSaved = true;
    } else {
      this.editMode = true;
      this.setDefaultCountry();
    }
  },
  methods: {
    deleteDocument(docDetailId, token) {
      const promt = confirm("Are you sure, you want to delete this document?");
      if (promt) {
        const payload = {
          docDetailId: docDetailId,
          token: token,
        };
        this.loader = true;
        this.$store
          .dispatch("vendor/deleteOpenKmDoc", payload)
          .then((response) => {
            if (response.status === 200) {
              alert("Document Deleted Successfully");
              this.$emit("updatedList");
              this.getUploadedDoc();
            } else {
              alert(response.response.data.message || "Something went wrong");
            }
            this.loader = false;
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    setDefaultCountry() {
      const country = commonHelper.getValuesetData(
        appStrings.VALUESETTYPE.SELECT_COUNTRY,
        "India"
      );
      country.then((key) => {
        this.country.text = key[0].value_code;
        this.country.value = key[0].value_set_dtl_id;
      });
    },
    showHideOpenModal(flag, docDtlId, authToken, fileName) {
      if (flag) {
        this.fileExtension = commonHelper.getFileExtension(fileName);
      }
      if (this.fileExtension === "xlsx") {
        alert("Not Allowed");
      } else {
        this.showOpenModal = flag;
        this.docDtlId = docDtlId;
        this.authToken = authToken;
        this.uploadedFileName = fileName;
      }
    },
    validateGst() {
      if (this.gstn && this.gstn.length == 15 && !this.gstnNotAvail) {
        const checkValidGst = this.gstn.substr(2, 10);
        if (checkValidGst === this.vendorPan) this.verifyGst();
        else alert("Invalid GST as per PAN Number");
      } else if (this.gstn && this.gstn.length == 15 && this.gstnNotAvail) {
        this.addEditVendorAddressDetails();
      } else {
        alert("Please Enter GST Number");
      }
    },
    tdsDefaultValue(tdsValue) {
      if (tdsValue) {
        this.tdsSection.text = null;
        this.tdsSection.value = null;
      }
    },
    getOpenKmAuthenticate() {
      this.loader = true;
      this.$store
        .dispatch("uploadDocument/getOpenKmAuthenticate")
        .then((response) => {
          this.loader = false;
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            if (!this.isDocumentUploaded) {
              this.uploadFile();
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectFile(event) {
      if (event[0].size <= 5242880) {
        this.fileRef = event;
      } else {
        this.fileRef = null;
        this.showAlert = true;
        this.isSuccess = false;
        this.responseMsg = event[0].name + " file should be less than 5 MB.";
      }
      // const fileName = event[0].name;
      // const contain = fileName.includes(".pdf") || fileName.includes(".jpg");
      // if (contain) {
      //   this.fileRef = event;
      // } else {
      //   this.fileRef = null;
      //   alert("Invalid File Type");
      // }
    },
    uploadFile() {
      if (this.fileRef) {
        let formData = new FormData();
        formData.append("file", this.fileRef.item(0));
        formData.append("token", this.openKmAuth);
        formData.append("doc_detail_id", 0);
        formData.append("menu_id", this.menuId);
        formData.append("sub_menu_id", this.submenuId);
        formData.append("record_id", this.addressId);
        this.loader = true;
        this.$store
          .dispatch("uploadDocument/uploadFile", formData)
          .then((response) => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.editMode = false;
              this.isSuccess = true;
              this.responseMsg = response.data.message;
              this.isDocumentUploaded = true;
              this.docDetailId = response.data.data.doc_detail_id;
              this.fileName = response.data.data.original_file_name;
              this.$emit("updatedList");
            } else {
              this.isSuccess = false;
              this.responseMsg =
                response.data.message || "Something went wrong";
            }
          })
          .catch(() => {
            this.loader = false;
          });
      } else {
        alert("Please select file less than 5 MB");
      }
    },
    getUploadedDoc() {
      const payload = {
        menu_id: this.menuId,
        sub_menu_id: this.submenuId,
        record_id: this.addressId,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getUploadedDoc", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page[0];
            if (result) {
              this.fileName = result.original_file_name;
              this.docDetailId = result.doc_detail_id;
              this.isDocumentUploaded = true;
              this.getOpenKmAuthenticate();
            } else {
              this.fileName = null;
              this.docDetailId = null;
              this.isDocumentUploaded = false;
              this.single = null;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditVendorAddressDetails() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          address_id: this.addressId,
          party_req_id: this.$store.state.auth.partyId,
          address_type_lookup: this.addressType.value,
          address1: this.address1,
          address2: this.address2 ? this.address2 : "",
          country_id: this.country.value,
          state_id: this.state.value,
          city_id: this.city.value,
          pincode_id: this.pincode.value,
          gstn: this.gstn,
          gstn_not_avail: this.gstnNotAvail,
          sez: this.sez,
          tds_section_id: this.tdsSection.value,
          tds_flag: this.tdsNotAvail,
          bnm: this.verifyGstData.bnm,
          stjCd: this.verifyGstData.stjCd,
          st: this.verifyGstData.st,
          loc: this.verifyGstData.loc,
          bno: this.verifyGstData.bno,
          lgnm: this.verifyGstData.lgnm,
          dty: this.verifyGstData.dty,
          stj: this.verifyGstData.stj,
          cxdt: this.verifyGstData.cxdt,
          pncd: this.verifyGstData.pncd,
          ntr: this.verifyGstData.ntr,
          lstupdt: this.verifyGstData.lstupdt,
          ctb: this.verifyGstData.ctb,
          rgdt: this.verifyGstData.rgdt,
          sts: this.verifyGstData.sts,
          ctjCd: this.verifyGstData.ctjCd,
          tradeNam: this.verifyGstData.tradeNam,
          stcd: this.verifyGstData.stcd,
          flno: this.verifyGstData.flno,
          ctj: this.verifyGstData.ctj,
        };
        this.loader = true;
        this.$store
          .dispatch("vendor/addEditVendorAddressDetails", payload)
          .then((response) => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              const result = response.data.data;
              this.editMode = false;
              this.isSuccess = true;
              this.responseMsg = response.data.message;
              this.isAddressSaved = true;
              this.addressId = result.address_id;
              this.addressType.text = result.address_type_lookup_meaning;
              this.addressType.value = result.address_type_lookup_meaning;
              this.address1 = result.address1;
              this.address2 = result.address2;
              this.country.text = result.country;
              this.country.value = result.country_id;
              this.state.text = result.state;
              this.state.value = result.state_id;
              this.city.text = result.city;
              this.city.value = result.city_id;
              this.pincode.text = result.pincode;
              this.pincode.value = result.pincode_id;
              this.gstn = result.gstn;
              this.gstnNotAvail = result.gstn_not_avail;
              this.sez = result.sez;
              this.tdsSection.text = result.section;
              this.tdsSection.value = result.tds_section_id;
              this.tdsNotAvail = result.tds_flag;
              this.$emit("updatedList");
            } else {
              this.isSuccess = false;
              this.responseMsg = response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      }
    },
    editModeAddress() {
      if (
        this.approvalStatus === "DRAFT" ||
        this.approvalStatus === "REJECTED"
      ) {
        this.editMode = true;
        this.isAddressSaved = false;
      } else {
        alert("Record is in " + this.approvalStatus + " Stage");
      }
    },
    fillRecordForAddressDetails(result) {
      this.addressId = result.address_id;
      this.addressType.text = result.address_type_lookup;
      this.addressType.value = result.address_type_lookup_meaning;
      this.address1 = result.address1;
      this.address2 = result.address2;
      this.country.text = result.country;
      this.country.value = result.country_id;
      this.state.text = result.state;
      this.state.value = result.state_id;
      this.city.text = result.city;
      this.city.value = result.city_id;
      this.pincode.text = result.pincode;
      this.pincode.value = result.pincode_id;
      this.gstn = result.gstn;
      this.gstnNotAvail = result.gstn_not_avail;
      this.sez = result.sez;
      this.tdsSection.text = result.section;
      this.tdsSection.value = result.tds_section_id;
      this.tdsNotAvail = result.tds_flag;
      this.getUploadedDoc();
    },
    getVendorAddressDetailsById() {
      const payload = {
        addressId: this.addressId,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getVendorAddressDetailsById", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data[0];
            this.addressId = result.address_id;
            this.addressType.text = result.address_type_lookup_meaning;
            this.addressType.value = result.address_type_lookup;
            this.address1 = result.address1;
            this.address2 = result.address2;
            this.country.text = result.country;
            this.country.value = result.country_id;
            this.state.text = result.state;
            this.state.value = result.state_id;
            this.city.text = result.city;
            this.city.value = result.city_id;
            this.pincode.text = result.pincode;
            this.pincode.value = result.pincode_id;
            this.gstn = result.gstn;
            this.gstnNotAvail = result.gstn_not_avail;
            this.sez = result.sez;
            this.tdsSection.text = result.section;
            this.tdsSection.value = result.tds_section_id;
            this.tdsNotAvail = result.tds_flag;
            this.getUploadedDoc();
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    upperCaseFunction(gstNumber) {
      if (gstNumber) {
        this.gstn = gstNumber.toUpperCase();
      }
    },
    gstDefaultValue(gstValue) {
      this.gstn = gstValue ? "GSTNOTAVAILABLE" : null;
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.parent_value_set_id = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.SELECT_STATE) {
        this.parent_value_set_id = this.country.value;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.SELECT_CITY ||
        this.vsetCode === appStrings.VALUESETTYPE.SELECT_PINCODE
      ) {
        this.parent_value_set_id = this.state.value;
      }
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null,
        };
        this.eventBus.$emit("valueSetCode", vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.ADDRESS_TYPE) {
        this.addressType.text = item.value_meaning;
        this.addressType.value = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.SELECT_COUNTRY) {
        this.country.text = item.value_code;
        this.country.value = item.value_set_dtl_id;
        this.state.text = null;
        this.state.value = null;
        this.city.text = null;
        this.city.value = null;
        this.pincode.text = null;
        this.pincode.value = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.SELECT_STATE) {
        this.state.text = item.value_code;
        this.state.value = item.value_set_dtl_id;
        this.city.text = null;
        this.city.value = null;
        this.pincode.text = null;
        this.pincode.value = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.SELECT_CITY) {
        this.city.text = item.value_code;
        this.city.value = item.value_set_dtl_id;
        this.pincode.text = null;
        this.pincode.value = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.SELECT_PINCODE) {
        this.pincode.text = item.value_code;
        this.pincode.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.TDS_SECTION_ACTUAL) {
        this.tdsSection.text = item.value_code;
        this.tdsSection.value = item.value_set_dtl_id;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.addressType.value) {
        this.addressType.text = null;
        this.addressType.value = null;
      } else if (vsetCode === this.country.value) {
        this.country.text = null;
        this.country.value = null;
        this.state.text = null;
        this.state.value = null;
        this.city.text = null;
        this.city.value = null;
        this.pincode.text = null;
        this.pincode.value = null;
      } else if (vsetCode === this.state.value) {
        this.state.text = null;
        this.state.value = null;
        this.city.text = null;
        this.city.value = null;
        this.pincode.text = null;
        this.pincode.value = null;
      } else if (vsetCode === this.city.value) {
        this.city.text = null;
        this.city.value = null;
        this.pincode.text = null;
        this.pincode.value = null;
      } else if (vsetCode === this.pincode.value) {
        this.pincode.text = null;
        this.pincode.value = null;
      } else if (vsetCode === this.tdsSection.value) {
        this.tdsSection.text = null;
        this.tdsSection.value = null;
      } else if (vsetCode === "gst_certificate") {
        this.single = null;
      }
    },
    verifyGst() {
      // if (this.gstn && this.gstn.length == 15 && !this.gstnNotAvail) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          gstin: this.gstn,
          ipaddress: this.ipAddress,
          serviceuser: this.serviceUser,
        };
        this.loader = true;
        this.$store
          .dispatch("vendor/gstnVerify", payload)
          .then((resp) => {
            this.loader = false;
            this.showAlert = true;
            if (resp.status === 200) {
              if (resp.data.data.sts === "Active") {
                this.verifyGstData = resp.data.data;
                this.addEditVendorAddressDetails();
              } else if (resp.data.data.sts === "Invalid GSTIN / UID") {
                // this.editMode = false;
                this.isSuccess = false;
                this.responseMsg = resp.data.data.message;
              } else {
                this.isSuccess = false;
                this.responseMsg = "GST Number is " + resp.data.data.sts;
              }
            } else {
              this.showAlert = false;
              this.isSuccess = false;
              this.responseMsg = resp.resp.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      }
      // }
      //  else if (this.gstnNotAvail) {
      //   this.addEditVendorAddressDetails();
      // } else {
      //   alert("Please Fill Valid GSTN");
      // }
    },
    getMenuSubMenuId() {
      this.loader = true;
      this.$store
        .dispatch("vendor/getMenuSubMenuId")
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            result.map((elem) => {
              if (elem.sub_menu_name == "ADDRESS") {
                this.addressMenu = {
                  menu_id: elem.menu_id,
                  menu_name: elem.menu_name,
                  module_id: elem.module_id,
                  sub_menu_id: elem.sub_menu_id,
                  sub_menu_name: elem.sub_menu_name,
                };
                return elem;
              }
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
  },
};
</script>
