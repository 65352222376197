const API_BASE_URL = process.env.VUE_APP_API_URL + "vendor-portal/";
const URL_UTILITY = {
  loginUrl: API_BASE_URL + "v2/authenticate",
  getPartyDetailsUrl: API_BASE_URL + "gl/party",
  getCustomerBillInfoUrl: API_BASE_URL + "gl/customers/invoices",
  getCustomerReceiptUrl: API_BASE_URL + "gl/customers/receipts",
  getModuleListUrl: API_BASE_URL + "module",
  getLegalEntityListUrl: API_BASE_URL + "le",
  getvendorInvoiceGridDataUrl: API_BASE_URL + "gl/vendors/invoices",
  getFmsUnitDetailsGridDataUrl: API_BASE_URL + "gl/customers/fms-unit-details",
  getLeaseUnitGridDataUrl: API_BASE_URL + "gl/customers/lease-unit-details",
  addEditTaxInvoiceDetailUrl: API_BASE_URL + "gl/vendor-invoice-details",
  getVendorCountUrl: API_BASE_URL + "gl/payables-vendor-os",
  getOpenKmAuthenticateUrl: API_BASE_URL + "openkm/authenticate",
  getUploadedDocDtlUrl: API_BASE_URL + "openkm/docs",
  getUploadFileUrl: API_BASE_URL + "openkm/upload-doc",
  getOpenKmDocDeleteUrl: API_BASE_URL + "openkm/doc",
  getCustomerCountUrl: API_BASE_URL + "gl/receivables-customer-os",
  getVendorDashboardCountUrl: API_BASE_URL + "gl/vendor-dashboard-count",
  getVendorDashboardCountGstUrl:
    API_BASE_URL + "gl/vendor-dashboard-gst-missing-mismatch",
  getRejectedInvoiceUrl: API_BASE_URL + "gl/vendor/rejected",
  getGstrMismatchUrl: API_BASE_URL + "gl/vendor/gst-mismatch",
  getScheduleToPayUrl: API_BASE_URL + "gl/vendor/schedule-to-pay",
  getPendingApprovalsUrl: API_BASE_URL + "gl/vendor/pending-approval",
  getNewSubmissionUrl: API_BASE_URL + "gl/vendor/new",
  getValueSetUrl: API_BASE_URL + "admin/value-set/{valueSetName}",
  getCustomerInvoiceDetailsUrl: API_BASE_URL + "gl/customer/os",
  verifyOtpUrl: API_BASE_URL + "general/verify/otp",
  newPasswordUrl: API_BASE_URL + "general/reset/password",
  getFinancialYearUrl: API_BASE_URL + "financial-year",
  getLovByLookupTypeUrl: API_BASE_URL + "admin/lookup",
  getCountryLovUrl: API_BASE_URL + "admin/countries/lov",
  getStatesLovByCountryIdUrl: API_BASE_URL + "admin/states/lov/{countryId}",
  getCitiesLovByStateIdUrl: API_BASE_URL + "admin/cities/lov/{stateId}",
  getPincodesLovByStateIdUrl: API_BASE_URL + "admin/pincodes/lov/{stateId}",
  getTaxGroupLovUrl: API_BASE_URL + "gl/lov/tax-groups/{taxType}",
  changePasswordUrl: API_BASE_URL + "general/change/password",
  getCustomerUrl: API_BASE_URL + "customer",
  generateCustomerDataUrl: API_BASE_URL + "admin/submit-requests",
  getCustomerLedgerUrl: API_BASE_URL + "customer-ledger",
  getSubmitRequestUrl: API_BASE_URL + "admin/submit-requests",
  getDownloadReportUrl: API_BASE_URL + "download-report",
  getCustomerLedgerHdrUrl: API_BASE_URL + "gl/customer-ledger-hdr",
  getCustomerLedgerDetailsUrl: API_BASE_URL + "gl/customer-ledger-details",
  getMismatchDetailsUrl: API_BASE_URL + "gl/vendor/gst-mismatch-details",
  getGstrMissingUrl: API_BASE_URL + "gl/vendor/gst-missing",
  getBillInfoUrl: API_BASE_URL + "customer-dashboard",
  generateVendorDataUrl: API_BASE_URL + "admin/submit-requests",
  getVendorUrl: API_BASE_URL + "vendor",
  getVendorLedgerDetailsUrl: API_BASE_URL + "gl/investor-ledger-details",
  getVendorLedgerHdrUrl: API_BASE_URL + "gl/investor-ledger-hdr",
  getVendorLedgerUrl: API_BASE_URL + "vendor-ledger",
  getVendorFmsUnitDetailsUrl: API_BASE_URL + "gl/vendor/fms-unit-details",
  getVendorLeaseUnitDetailsUrl: API_BASE_URL + "gl/vendor/lease-unit-details",
  getVendorDetailsUrl: API_BASE_URL + "external/party-req",
  getVendorAddDetailsUrl: API_BASE_URL + "external/party-req-add",
  getVendorContactDetailsUrl: API_BASE_URL + "external/party-contact-req",
  getVendorBankDetailsUrl: API_BASE_URL + "external/party-bank-req",
  sendWorkflowProcessUrl: API_BASE_URL + "workflow/process-workflow",
  sendWorkflowApprovalUrl: API_BASE_URL + "workflow/approval",
  gstnVerifyUrl: API_BASE_URL + "verify/gstn",
  getDocumentDownloadUrl: API_BASE_URL + "openkm/doc",
  getUploadedDocUrl: API_BASE_URL + "openkm/docs",
  getDocumentPreviewUrl: API_BASE_URL + "openkm/doc/preview",
  getMenuSubMenuIdUrl: API_BASE_URL + "menu/vendor-menu-submenu",
  email: API_BASE_URL + "general/verify/email",
  getDefaultDataAdditionalDetailsUrl:
    API_BASE_URL + "external/party-additional-dtls-setup",
  addEditAdditionalDetailsUrl: API_BASE_URL + "external/party-additional-dtls",
  validateBankDetailsUrl: API_BASE_URL + "bank/bank-verify",
  getTermsConditionUrl: API_BASE_URL + "external/party-req-terms",
  getManualDocumentUrl:
    API_BASE_URL + "download-vendor-portal-manual-samplefile",
  addEditVendorInvoiceDetailsUrl: API_BASE_URL + "external/vendor-invoices-po",
  getTaxInvoiceDetailsUrl:
    API_BASE_URL + "vendor/vendor-portal-invoices-tax-details",
  getInvoiceReportCountUrl: API_BASE_URL + "vendor/dashboard-invoice-report",
  getPaymentReportCountUrl: API_BASE_URL + "vendor/dashboard-payment-report",
  getPoReportCountUrl: API_BASE_URL + "vendor/dashboard-po-report",
  getPendingReportCountUrl:
    API_BASE_URL + "external/dashboard-pending-invoices",
  getSummaryKeyDtlsUrl: API_BASE_URL + "admin/menu-help-id",
  getVendorDisclosureDetailsUrl:
    API_BASE_URL + "external/party-disclosure-req-metadata",
  addEditVendorDisclosureDetailsUrl:
    API_BASE_URL + "external/party-disclosure-req",
  invoiceNumberValidationUrl: API_BASE_URL + "external/invoice-num-validation",
  getAdvanceReportCountUrl: API_BASE_URL + "vendor/dashboard-open-advance",
};

export default URL_UTILITY;
