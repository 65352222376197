import http from "@/services/httpServices";
import URL_UTILITY from "@/utility/url.utility";

const state = {
  list: {},
  // vendorId: sessionStorage.getItem("vendorId"),
};

const mutations = {
  setVendorId(state, vendorId) {
    state.vendorId = vendorId;
  },
  setVendorName(state, vendorName) {
    state.vendorName = vendorName;
  },
};

const actions = {
  setVendorId(context, vendorId) {
    context.commit("setVendorId", vendorId);
  },
  setVendorName(context, vendorName) {
    context.commit("setVendorName", vendorName);
  },
  getVendorInvoiceGridData(context, payload) {
    return http.getApi(URL_UTILITY.getvendorInvoiceGridDataUrl, payload);
  },
  getByIdTaxInvoiceDetails(context, ppVendorInvoiceId) {
    return http.getApi(
      URL_UTILITY.addEditTaxInvoiceDetailUrl + "/" + ppVendorInvoiceId
    );
  },
  addEditTaxInvoiceDetail(context, payload) {
    return http.postApi(URL_UTILITY.addEditTaxInvoiceDetailUrl, payload);
  },
  getVendorDashboardCount(context, payload) {
    return http.getApi(URL_UTILITY.getVendorDashboardCountUrl, payload);
  },
  getVendorDashboardCountGst(context, payload) {
    return http.getApi(URL_UTILITY.getVendorDashboardCountGstUrl, payload);
  },
  getRejectedInvoice(context, payload) {
    return http.getApi(URL_UTILITY.getRejectedInvoiceUrl, payload);
  },
  getGstrMismatch(context, payload) {
    return http.getApi(URL_UTILITY.getGstrMismatchUrl, payload);
  },
  getScheduleToPay(context, payload) {
    return http.getApi(URL_UTILITY.getScheduleToPayUrl, payload);
  },
  getPendingApprovals(context, payload) {
    return http.getApi(URL_UTILITY.getPendingApprovalsUrl, payload);
  },
  getNewSubmission(context, payload) {
    return http.getApi(URL_UTILITY.getNewSubmissionUrl, payload);
  },
  getMismatchDetails(context, payload) {
    return http.getApi(URL_UTILITY.getMismatchDetailsUrl, payload);
  },
  getGstrMissing(context, payload) {
    return http.getApi(URL_UTILITY.getGstrMissingUrl, payload);
  },
  generateVendorData(context, payload) {
    return http.postApi(URL_UTILITY.generateVendorDataUrl, payload);
  },
  getVendor() {
    return http.getApi(URL_UTILITY.getVendorUrl);
  },
  getVendorLedgerDetails(context, payload) {
    return http.getApi(URL_UTILITY.getVendorLedgerDetailsUrl, payload);
  },
  getVendorLedgerHdr(context, payload) {
    return http.getApi(URL_UTILITY.getVendorLedgerHdrUrl, payload);
  },
  getVendorRequestId() {
    return http.getApi(URL_UTILITY.getVendorLedgerUrl + "/requestId");
  },
  getVendorTemplateId() {
    return http.getApi(URL_UTILITY.getVendorLedgerUrl + "/templateId");
  },
  getVendorFmsUnitDetails() {
    return http.getApi(URL_UTILITY.getVendorFmsUnitDetailsUrl);
  },
  getVendorLeaseUnitDetails() {
    return http.getApi(URL_UTILITY.getVendorLeaseUnitDetailsUrl);
  },

  getVendorDetails(context, payload) {
    return http.getApi(
      URL_UTILITY.getVendorDetailsUrl + "/" + payload.party_req_id
    );
  },

  getVendorAddressDetails(context, payload) {
    return http.getApi(
      URL_UTILITY.getVendorAddDetailsUrl + "/" + payload.party_req_id
    );
  },

  addEditVendorAddressDetails(context, payload) {
    return http.postApi(URL_UTILITY.getVendorAddDetailsUrl, payload);
  },

  getVendorContactDetails(context, payload) {
    return http.getApi(
      URL_UTILITY.getVendorContactDetailsUrl + "/" + payload.party_req_id
    );
  },
  addEditVendorContactDetails(context, payload) {
    return http.postApi(URL_UTILITY.getVendorContactDetailsUrl, payload);
  },

  getVendorBankDetails(context, payload) {
    return http.getApi(
      URL_UTILITY.getVendorBankDetailsUrl + "/" + payload.party_req_id
    );
  },
  addEditVendorBankDetails(context, payload) {
    return http.postApi(URL_UTILITY.getVendorBankDetailsUrl, payload);
  },
  sendWorkflowProcess(context, payload) {
    return http.postApi(URL_UTILITY.sendWorkflowProcessUrl, payload);
  },
  sendWorkflowApproval(context, payload) {
    return http.postApi(URL_UTILITY.sendWorkflowApprovalUrl, payload);
  },
  getVendorInvoices(context, payload) {
    return http.postApi(URL_UTILITY.getVendorUrl + "/invoices", payload);
  },
  addEditVendorDetails(context, payload) {
    return http.postApi(URL_UTILITY.getVendorDetailsUrl + "/einvoice", payload);
  },
  getComplianceData() {
    return http.getApi(
      URL_UTILITY.getVendorDetailsUrl + "/compliance/meta-data"
    );
  },
  addEditComplianceDetails(context, payload) {
    return http.postApi(
      URL_UTILITY.getVendorDetailsUrl + "/compliance",
      payload
    );
  },
  getVendorComplianceDetails(context, payload) {
    return http.getApi(
      URL_UTILITY.getVendorDetailsUrl +
        "/" +
        payload.party_req_id +
        "/compliance"
    );
  },
  gstnVerify(context, payload) {
    return http.postApi(URL_UTILITY.gstnVerifyUrl, payload);
  },
  getUploadedDoc(context, payload) {
    return http.getApi(URL_UTILITY.getUploadedDocUrl, payload);
  },
  getMenuSubMenuId() {
    return http.getApi(URL_UTILITY.getMenuSubMenuIdUrl);
  },
  getVendorDocumentDetails(context, payload) {
    return http.getApi(
      URL_UTILITY.getVendorDetailsUrl +
        "/" +
        payload.party_req_id +
        "/uploaded-doc-list"
    );
  },
  getDefaultDataAdditionalDetails() {
    return http.getApi(URL_UTILITY.getDefaultDataAdditionalDetailsUrl);
  },
  addEditAdditionalDetails(context, payload) {
    return http.postApi(URL_UTILITY.addEditAdditionalDetailsUrl, payload);
  },
  getAdditionalDetailsById(context, payload) {
    return http.getApi(
      URL_UTILITY.addEditAdditionalDetailsUrl + "/" + payload.party_req_id
    );
  },
  validateBankDetails(context, payload) {
    return http.postApi(URL_UTILITY.validateBankDetailsUrl, payload);
  },
  getVendorPoDetails(context, payload) {
    return http.postApi(URL_UTILITY.getVendorUrl + "/po-details", payload);
  },
  getVendorPoLineDetailsById(context, payload) {
    return http.postApi(
      URL_UTILITY.getVendorUrl + "/po-details-by-Id",
      payload
    );
  },
  getVendorInvoiceLineDetailsById(context, payload) {
    return http.postApi(
      URL_UTILITY.getVendorUrl + "/vendor-invoices-by-id",
      payload
    );
  },
  getTermsCondition(context) {
    return http.getApi(URL_UTILITY.getTermsConditionUrl + "/details");
  },
  addEditTermsCondition(context, payload) {
    return http.postApi(
      URL_UTILITY.getTermsConditionUrl + "/update-party-req",
      payload
    );
  },
  getVendorPaymentData(context, payload) {
    return http.postApi(
      URL_UTILITY.getVendorUrl + "/vendor-payment-hdr",
      payload
    );
  },
  getVendorPaymentsDtlsById(context, payload) {
    return http.postApi(
      URL_UTILITY.getVendorUrl + "/vendor-payment-hdr-dtl",
      payload
    );
  },
  getPoNumberDetailsById(context, payload) {
    return http.postApi(
      URL_UTILITY.getVendorUrl + "/vendor-portal-po-by-vendor-id",
      payload
    );
  },

  getVendorBankDetailsById(context, payload) {
    return http.getApi(
      URL_UTILITY.getVendorBankDetailsUrl + "-details" + "/" + payload.bankId
    );
  },
  getVendorAddressDetailsById(context, payload) {
    return http.getApi(
      URL_UTILITY.getVendorAddDetailsUrl + "-details" + "/" + payload.addressId
    );
  },
  deleteOpenKmDoc(context, payload) {
    return http.deleteApi(
      URL_UTILITY.getOpenKmDocDeleteUrl +
        "/" +
        payload.docDetailId +
        "?token=" +
        payload.token
    );
  },
  getPoLineDetailsById(context, payload) {
    return http.postApi(
      URL_UTILITY.getVendorUrl + "/vendor-portal-invoices-po-line-details",
      payload
    );
  },
  deleteAddressRecord(context, payload) {
    return http.deleteApi(
      URL_UTILITY.getVendorAddDetailsUrl + "-delete/" + payload.addressId
    );
  },
  deleteContactRecord(context, payload) {
    return http.deleteApi(
      URL_UTILITY.getVendorContactDetailsUrl +
        "-details-delete/" +
        payload.contactId
    );
  },
  deleteBankRecord(context, payload) {
    return http.deleteApi(
      URL_UTILITY.getVendorBankDetailsUrl + "-details-delete/" + payload.bankId
    );
  },
  addEditVendorInvoiceDetails(context, payload) {
    return http.postApi(URL_UTILITY.addEditVendorInvoiceDetailsUrl, payload);
  },
  getTaxInvoiceDetails(context, payload) {
    return http.postApi(URL_UTILITY.getTaxInvoiceDetailsUrl, payload);
  },
  getVendorInvoiceDetails(context, payload) {
    return http.postApi(
      URL_UTILITY.addEditVendorInvoiceDetailsUrl + "-hdr",
      payload
    );
  },
  getVendorInvoiceLineDetails(context, payload) {
    return http.getApi(
      URL_UTILITY.addEditVendorInvoiceDetailsUrl + "/" + payload.invHdrId
    );
  },
  addEditVendorTaxDetails(context, payload) {
    return http.postApi(URL_UTILITY.addEditVendorInvoiceDetailsUrl, payload);
  },
  getTaxTypeValueSet(context, payload) {
    return http.postApi(
      URL_UTILITY.getVendorUrl + "/tax-detail-value-set",
      payload
    );
  },
  getInvoicePaymentDtls(context, payload) {
    return http.postApi(
      URL_UTILITY.getVendorUrl + "/vendor-invoice-payment-dtl",
      payload
    );
  },
  getPendingQtyforInvoice(context, payload) {
    return http.getApi(
      URL_UTILITY.addEditVendorInvoiceDetailsUrl +
        "-line-dtls-qty/" +
        payload.po_num
    );
  },
  getKeySummaryDtls(context, payload) {
    return http.getApi(URL_UTILITY.getSummaryKeyDtlsUrl, payload);
  },
  getVendorDisclosureDetails() {
    return http.getApi(URL_UTILITY.getVendorDisclosureDetailsUrl);
  },
  addEditVendorDisclosureDetails(context, payload) {
    return http.postApi(URL_UTILITY.addEditVendorDisclosureDetailsUrl, payload);
  },
  getLegalEntityVendorInvoice(context, payload) {
    return http.postApi(URL_UTILITY.getVendorUrl + "/legal-entity", payload);
  },
  getVendorGstnVendorInvoice(context, payload) {
    return http.postApi(URL_UTILITY.getVendorUrl + "/vendor-gstn", payload);
  },
  getCompanyGstnVendorInvoice(context, payload) {
    return http.getApi(URL_UTILITY.getVendorUrl + "/company-gstn", payload);
  },
  getTaxTypeVendorInvoice(context, payload) {
    return http.getApi(URL_UTILITY.getVendorUrl + "/tax-type", payload);
  },
  getTaxRateVendorInvoice(context, payload) {
    return http.getApi(URL_UTILITY.getVendorUrl + "/tax-rate", payload);
  },
  getCurrencyVendorInvoice(context, payload) {
    return http.getApi(URL_UTILITY.getVendorUrl + "/currency-code", payload);
  },
  invoiceNumberValidation(context, payload) {
    return http.getApi(URL_UTILITY.invoiceNumberValidationUrl, payload);
  },
  getTermsConditionById(context, payload) {
    return http.getApi(
      URL_UTILITY.addEditVendorDisclosureDetailsUrl + "/" + payload.party_req_id
    );
  },
  getAdvanceReportDtls(context, payload) {
    return http.postApi(
      URL_UTILITY.getVendorUrl + "/open-advance-detail",
      payload
    );
  },
};

const getters = {};

const vendor = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default vendor;
