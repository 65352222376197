const appStrings = {
  autoFailedMsg: "Something went wrong please try again.",
  countrySuccessMsg: "Country updated successfully",
  countryAddedMsg: "Country added successfully",
  stateSuccessMsg: "State updated successfully",
  stateAddedMsg: "State added successfully",
  citySuccessMsg: "City updated successfully",
  cityAddedMsg: "City added successfully",
  pincodeSuccessMsg: "City updated successfully",
  pincodeAddedMsg: "City added successfully",
  addressUpdatedSuccessMsg: "Address updated successfully",
  addressAddedSuccessMsg: "Address added successfully",
  currencySuccessMsg: "Currency updated successfully",
  currencyAddedMsg: "Currency added successfully",

  FMSLOV: {
    FMSPROJECT: "FMSPROJECT",
    FMSSECTOR: "FMSSECTOR",
    FMSPOCKET: "FMSPOCKET",
    FMSTOWER: "FMSTOWER",
    FMSFLOOR: "FMSFLOOR",
    FMSUNIT: "FMSUNIT",
    FMSSUBUNIT: "FMSSUBUNIT",
  },
  VALUESETTYPE: {
    FMS_SEARCH_BY: "FMS_SEARCH_BY",
    PAYMENT_MODE: "PAYMENT_MODE",
    BANK_NAME_VSET: "BANK_NAME_VSET",
    INSTRUMENT_STATUS: "INSTRUMENT_STATUS",
    PARTY_TYPE: "PARTY_TYPE",
    PARTY_CAT: "PARTY_CAT",
    BANK_LIST: "BANK_LIST",
    BANK_ACC_TYPE: "BANK_ACC_TYPE",
    CONTACT_PURPOSE_VSET: "CONTACT_PURPOSE_VSET",
    POSITION: "POSITION",
    WF_GROUP_TYPE: "WF_GROUP_TYPE",
    NFA_TYPE: "NFA_TYPE",
    NFA_CATEGORY: "NFA_CATEGORY",
    NFA_SUB_CATEGORY: "NFA_SUB_CATEGORY",
    NFA_LOCATION: "NFA_LOCATION",
    LEGAL_ENTITY_LIST: "LEGAL_ENTITY_LIST",
    OU_LIST: "OU_LIST",
    PERIOD_CLOSED_LEVEL: "PERIOD_CLOSED_LEVEL",
    FMS_PROJECT: "FMS_PROJECT",
    LEASE_RECOVERY: "LEASE_RECOVERY",
    LOCATION: "LOCATION",
    COMM_TEMPLATE: "COMM_TEMPLATE",
    UOM: "UOM",
    LEASE_PROJECT_LIST: "LEASE_PROJECT_LIST",
    LEASE_TOWER_LIST: "LEASE_TOWER_LIST",
    LEASE_FLOOR_LIST: "LEASE_FLOOR_LIST",
    LEASE_UNIT_LIST: "LEASE_UNIT_LIST",
    RECOVERY_BASIS: "RECOVERY_BASIS",
    RECOVERY_MODE_VSET: "RECOVERY_MODE_VSET",
    TAX_TYPE_INVOICE: "TAX_TYPE_INVOICE",
    CONTACT_PURPOSE: "CONTACT_PURPOSE",
    CONTACT_TYPE: "CONTACT_TYPE",
    OKM_SUB_CAT_DOC: "OKM_SUB_CAT_DOC",
    ADDRESS_TYPE: "ADDRESS_TYPE",
    TDS_SECTION_ACTUAL: "TDS_SECTION_ACTUAL",
    SELECT_COUNTRY: "SELECT_COUNTRY",
    SELECT_STATE: "SELECT_STATE",
    SELECT_CITY: "SELECT_CITY",
    SELECT_PINCODE: "SELECT_PINCODE",
    BRANCH_LIST: "BRANCH_LIST",
    PARTY_REQ_APPLICABLE: "PARTY_REQ_APPLICABLE",
    PARTY_REQ_APPROVAL_STATUS_VSET: "PARTY_REQ_APPROVAL_STATUS_VSET",
    CURRENCY: "CURRENCY",
  },
  TERMSANDCONDITION: {
    HEADING:
      "Delhivery and its subsidiaries are committed to perform their business activities in a responsible, ethical, safe, andenvironmentally conscious manner. We expect the highest standard of conduct from all our stakeholders (employees, suppliers, and their workers). Our sustainable sourcing policy complements our “Supplier Code of Conduct” which sets a baseline for ESG guidelines and applies to all our suppliers and vendors. We expect our suppliers to comply fully with the policy requirements and the supplier code of conduct.\n\n",
    TITLE:
      "Delhivery aims to incorporate the following factors into its procurement decisions:\n\n",
    ONE:
      "1.Suppliers must obey all relevant international and national laws regarding ethical business practices, such as anti-trust, anti-bribery, anti-competition laws, and conflict of interest.\n\n",
    TWO:
      "2. Suppliers should show the highest ethical standards concerning gifts and hospitality, money laundering practices, financial frauds and share values such as integrity, responsibility, transparency, and vigilance.\n\n",
    THREE:
      "3. Suppliers must comply with all applicable laws per the principles of the UN Global Compact, the International Labor Organization, and the UN Declaration of Human Rights.\n\n",
    FOUR:
      "4. Suppliers should form and implement policies and procedures to protect and promote human rights by managing forced and child labor, fair wages, freedom of association and collective bargaining, inclusive workplaces free from discrimination and abuse, and take responsibility for compliance throughout their supply chain.\n\n",
    FIVE:
      "5. Suppliers should Comply and adhere to all the applicable local, state, and national environmental laws, regulations, and obligations.\n\n",
    SIX:
      "6. Suppliers should optimize the use of natural resources, reduce the release of harmful emissions in the environment and produce environmentally compatible products and services which have extended product life, recyclability, reusability, and use of recycled content.\n\n",
    SEVEN:
      "7. Suppliers should ensure all legal requirements are addressed, including but not limited to occupational safety, emergency preparedness, occupational injury and illness, industrial hygiene, physically demanding work, machine safeguarding, sanitation, food, and housing.\n\n",
    EIGHT:
      "8. Suppliers should conduct training and awareness activities on employee health & safety and are committed to ensuring zero harm to employees and workers.\n\n",
    NINE:
      "9.Suppliers must safeguard and respect all confidential information, personal information, intellectual property, the know-how of the business, or any information that is not available in the public domain.",
  },
  LOOKUPTYPE: {
    SALUTATION: "SALUTATION",
    ADDRESS_TYPE: "ADDRESS_TYPE",
    USER_TYPE: "USER_TYPE",
    SECGRP: "SECGRP",
    EMPLOYEE_TYPE: "EMPLOYEE_TYPE",
    GENDER: "GENDER",
    MARITAL_STATUS: "MARITAL_STATUS",
    POSITION_CRITICALITY: "POSITION_CRITICALITY",
    EMPLOYMENT_STATUS: "EMPLOYMENT_STATUS",
    RELATION: "RELATION",
    GRADE: "GRADE",
  },
  DATEFORMAT: "DD-MMM-YYYY",
  DATEFNSFORMAT: "dd-MMM-yyyy",
  DEFAULTPAN: "PANNOTAVBL",
  DEFAULTGST: "GSTNOTAVAILABLE",
  MANUALFILENAME: "DELIVERY_VENDOR_REGISTRATION.pdf",
  INVOICEFILENAME: "VENDOR_INVOICE_SUBMISSION.pdf",
  DEPENDENTVSETMSG: {
    LEMSG: "Please select Legal Entity",
    OUMSG: "Please select Operating Unit",
    BANDMSG: "Please select Band",
    DESIGNATIONMSG: "Please select Designation",
    CATEGORYMSG: "Please select Category",
    SUBCATEGORYMSG: "Please select Sub Category",
    MODULEMSG: "Please select Module",
    ROLEMSG: "Please select Role",
    REPORTGRPMSG: "Please select Report Group",
    CRMMASTERPRJMSG: "Please select CRM Master Project",
    CRMSECTORMSG: "Please select CRM Sector",
    CRMPOCKETMSG: "Please select CRM Pocket",
    CRMPROJECTMSG: "Please select CRM Project",
    CRMTOWERMSG: "Please select CRM Tower",
    CRMFLOORMSG: "Please select CRM Floor",
    CRMUNITMSG: "Please select CRM Unit",
    CRMSUBUNITMSG: "Please select CRM SubUnit",
    FMSMASTERPRJMSG: "Please select FMS Master Project", //
    FMSSECTORMSG: "Please select FMS Sector",
    FMSPOCKETMSG: "Please select FMS Pocket",
    FMSPROJECTMSG: "Please select FMS Project",
    FMSTOWERMSG: "Please select FMS Tower",
    FMSFLOORMSG: "Please select FMS Floor",
    FMSUNITMSG: "Please select FMS Unit",
    FMSSUBUNITMSG: "Please select FMS SubUnit",
    PRJMASTERPRJMSG: "Please select PRJ Master Project",
    PRJSECTORMSG: "Please select PRJ Sector",
    PRJPOCKETMSG: "Please select PRJ Pocket",
    PRJPROJECTMSG: "Please select PRJ Project",
    PRJTOWERMSG: "Please select PRJ Tower",
    PRJFLOORMSG: "Please select PRJ Floor",
    PRJUNITMSG: "Please select PRJ Unit",
    PRJSUBUNITMSG: "Please select PRJ SubUnit",
    SYSTEMTABLESMSG: "Please select System Tables",
    RULETYPEMSG: "Please select Rule Type",
    ENTITYMSG: "Please select Entity",
    COLUMNMSG: "Please select Column",
    TAXGROUPMSG: "Please select Tax Group",
    LEASEPRJMSG: "Please select Lease Project",
    LEASETWRMSG: "Please select Lease Tower",
    ORGNAMEMSG: "Please Select Organisation",
    DEFAULTMSG: "Due to Technical Error. Please Contact System Administrator.",
  },
};

export default appStrings;
