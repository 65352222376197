<template>
  <div style="margin-left: 400px !important">
    <md-card class="md-layout-item md-size-40" style="height: 400px">
      <b-alert
        :variant="isSuccess ? 'primary' : 'danger'"
        :show="showAlert"
        dismissible
        fade
        @dismissed="showAlert = false"
        >{{ responseMsg }}
      </b-alert>
      <md-card-content>
        <h3 class="text-center">
          <b><u>Change Password</u></b>
        </h3>
        <div style="margin: auto; padding: 20px">
          <div class="form-group px-24">
            <label for="password">Current Password</label>
            <div>
              <v-otp-input
                ref="password"
                input-classes="otp-input"
                separator="-"
                :num-inputs="6"
                :should-auto-focus="true"
                :is-input-num="true"
                input-disabled="true"
                input-type="password"
                @on-change="handleOnComplete"
              />
            </div>
          </div>
          <div class="form-group px-24" style="margin-top: 1rem">
            <label for="password">New Password</label>
            <div>
              <v-otp-input
                ref="password"
                input-classes="otp-input"
                separator="-"
                :num-inputs="6"
                :is-input-num="true"
                input-disabled="true"
                input-type="password"
                @on-change="handleOnChange"
              />
            </div>
          </div>
          <div class="form-group px-24" style="margin-top: 1rem">
            <label for="password">Verify Password</label>
            <div>
              <v-otp-input
                ref="verifyPassword"
                input-classes="otp-input"
                separator="-"
                :num-inputs="6"
                :is-input-num="true"
                input-disabled="true"
                input-type="password"
                @on-complete="handleOnCompleteSetPassword"
              />
            </div>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>
<script>
import appStrings from "../../../utility/string.utility";
export default {
  // components: {ValidationError},
  data() {
    return {
      showAlert: false,
      loader: false,
      isSuccess: false,
      responseMsg: "",
      currentPassword: "",
      newPassword: "",
      verifyNewPassword: "",
    };
  },
  mounted() {},
  methods: {
    handleOnComplete: function(value) {
      this.currentPassword = value;
    },
    handleOnChange: function(value) {
      this.newPassword = value;
      this.error = {
        setPassword: "",
      };
      this.reset = false;
    },
    handleOnCompleteSetPassword: function(value) {
      this.verifyNewPassword = value;
      if (this.verifyNewPassword != this.newPassword) {
        this.error = {
          setPassword: "Password not match",
        };
        this.showAlert = true;
        this.isSuccess = false;
        this.responseMsg = this.error.setPassword;
      } else {
        this.changePassword();
      }
    },
    changePassword() {
      if (this.currentPassword !== this.newPassword) {
        const payload = {
          old_password: this.currentPassword,
          new_password: this.newPassword,
        };
        this.$store.dispatch("shared/setLoader", true);
        this.$store
          .dispatch("party/changePassword", payload)
          .then((response) => {
            this.$store.dispatch("shared/setLoader", false);
            this.showAlert = true;
            if (response.status === 200) {
              this.editMode = false;
              this.isSuccess = true;
              this.responseMsg = response.data.message;
              alert("Password Changed Successfully");
              this.$store.dispatch("logout");
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.$store.dispatch("shared/setLoader", false);
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      } else {
        alert("New password cannot be the same as your old password.");
      }
    },
  },
};
</script>
