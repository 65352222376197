<template>
  <div class="md-layout">
    <div class="overlayLoader" v-if="loader">
      <md-progress-spinner
        :md-diameter="100"
        class="md-accent spinner-postion"
        :md-stroke="7"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <div class="md-layout">
          <md-card-header
            class="md-card-header-text md-card-header-green search-icon"
          >
            <div class="card-icon vendor-icon">
              <md-icon style="font-size: 20px !important">search</md-icon>
            </div>
          </md-card-header>
          <div
            style="min-width:12%;"
            class="md-layout-item md-small-size-100 md-size-10"
          >
            <md-field>
              <label for="font">PO Number</label>
              <md-input v-model="poNum"> </md-input>
            </md-field>
          </div>
          <div
            style="min-width:12%;"
            class="md-layout-item md-small-size-100 md-size-10"
          >
            <md-field>
              <label>Amount From</label>
              <md-input type="text" v-model="poAmtFrom"></md-input>
            </md-field>
          </div>
          <div
            style="min-width:12%;"
            class="md-layout-item md-small-size-100 md-size-10"
          >
            <md-field>
              <label>Amount To</label>
              <md-input type="text" v-model="poAmtTo"></md-input>
            </md-field>
          </div>
          <div
            style="min-width:12%;"
            class="md-layout-item md-small-size-100 md-size-10"
          >
            <md-field>
              <label>Location</label>
              <md-input type="text" v-model="location"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-14 DateFormate">
            <!-- <md-datepicker
              md-immediately
              v-model="creationDateFrom"
              style="margin-top:-5px;"
              ><label>From Date</label></md-datepicker
            > -->
            <date-picker
              valueType="format"
              format="DD-MMM-YYYY"
              placeholder="From Date"
              v-model="creationDateFrom"
              @change="creationDateTo = null"
            ></date-picker>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-14 DateFormate">
            <!-- <md-datepicker
              md-immediately
              v-model="creationDateTo"
              style="margin-top:-5px;"
              ><label>To Date</label></md-datepicker
            > -->
            <date-picker
              md-immediately
              valueType="format"
              format="DD-MMM-YYYY"
              placeholder="To Date"
              v-model="creationDateTo"
              :disabled-date="disabledDates"
              :disabled="!creationDateFrom"
            ></date-picker>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-22">
            <div style="margin-top: 6%">
              <md-button
                class="md-raised md-success search-btn"
                style="margin-left: -8%; margin-right: 5%"
                @click="isVendorApproved()"
                >Search</md-button
              >
              <!-- @click="getVendorInvoiceGridData()" -->
              <md-button
                class="md-raised md-info search-btn"
                style="margin-right: 4%"
                @click="clearSearch()"
                >Clear</md-button
              >
              <md-button
                v-if="gridData.length"
                style="height:28px;"
                class="md-warning md-just-icon"
                @click="downloadCustomExcel()"
                title="Download Excel"
                ><md-icon>file_download</md-icon></md-button
              >
            </div>
          </div>
        </div>
      </md-card>
    </div>
    <!-- <div
      @click="getRejectedInvoice()"
      class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-16"
    >
      <stats-card header-color="rose" class="pointer">
        <template slot="header">
          <div class="card-icon vendor-icon">
            <md-icon style="font-size: 20px !important">perm_identity</md-icon>
          </div>
          <p class="category"><strong>Rejected</strong></p>
          <span
            ><strong>{{ vendorInvoiceCount.rejected }}</strong></span
          >
        </template>
      </stats-card>
    </div> -->
    <!-- <div
    @click="getGstrMissing()"
      class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-16"
    >
      <stats-card header-color="blue" class="pointer">
        <template slot="header">
          <div class="card-icon vendor-icon">
            <md-icon style="font-size: 20px !important">equalizer</md-icon>
          </div>
          <p class="category"><strong>GSTR2A Missing</strong></p>
          <span><strong>{{ vendorInvoiceCount.gst_missing}}</strong></span>
        </template>
      </stats-card>
    </div> -->
    <!-- <div
    @click="getGstrMismatch()"
      class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-16"
    >
      <stats-card header-color="green" class="pointer">
        <template slot="header">
          <div class="card-icon vendor-icon">
            <md-icon style="font-size: 20px !important">equalizer</md-icon>
          </div>
          <p class="category"><strong>GSTR2A Mismatch</strong></p>
          <span><strong>{{ vendorInvoiceCount.gst_mismatch }}</strong></span>
        </template>
      </stats-card>
    </div> -->
    <!-- <div
      @click="getScheduleToPay()"
      class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-16">
      <stats-card header-color="warning" class="pointer">
        <template slot="header">
          <div class="card-icon vendor-icon">
            <md-icon style="font-size: 20px !important">notifications</md-icon>
          </div>
          <p class="category"><strong>Scheduled To Pay</strong></p>
          <span
            ><strong>{{ vendorInvoiceCount.schedule_to_pay }}</strong></span
          >
        </template>
      </stats-card>
    </div> -->
    <!-- <div
      @click="getPendingApprovals()"
      class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-16">
      <stats-card header-color="warning" class="pointer">
        <template slot="header">
          <div class="card-icon vendor-icon">
            <md-icon style="font-size: 20px !important">notifications</md-icon>
          </div>
          <p class="category"><strong>Pending Approvals</strong></p>
          <span
            ><strong>{{ vendorInvoiceCount.pending_approval }}</strong></span
          >
        </template>
      </stats-card>
    </div> -->
    <!-- <div
      @click="getNewSubmission()"
      class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-16">
      <stats-card header-color="warning" class="pointer">
        <template slot="header">
          <div class="card-icon vendor-icon">
            <md-icon style="font-size: 20px !important">notifications</md-icon>
          </div>
          <p class="category"><strong>New Submission</strong></p>
          <span
            ><strong>{{ vendorInvoiceCount.new }}</strong></span
          >
        </template>
      </stats-card>
    </div> -->
    <!-- <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-size-100 text-right">
              <md-button
                class="md-raised md-success vendor-btn"
                @click="btnAction('rejectedReport')"
                >Rejected Report</md-button
              >
              <router-link to="/vendor/new-invoice-entry"
                ><md-button class="md-raised md-primary vendor-btn"
                  >New Invoice</md-button
                ></router-link>
              <md-button class="md-raised md-info vendor-btn"
                >Bulk Upload</md-button
              >
              <md-button class="md-raised md-warning vendor-btn"
                >Last Month Payment Report</md-button
              >
              <md-button class="md-raised md-danger vendor-btn"
                >New Credit Note</md-button
              >
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div> -->
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <div class="md-layout-item">
          <b-table
            striped
            hover
            outlined
            sticky-header
            :items="gridData"
            :fields="gridField"
            show-empty
            class="bg-white"
            style="max-height:calc(100vh - 220px)"
            @row-clicked="rowSelected"
          >
            <template #head(Invoice_type)="data">
              <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
            </template>
            <template #head(po_number)="data">
              <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
            </template>
            <template #head(revision_num)="data">
              <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
            </template>
            <template #head(creation_date)="data">
              <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
            </template>
            <template #head(legel_entity)="data">
              <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
            </template>
            <template #head(full_name)="data">
              <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
            </template>
            <template #head(first_party_reg)="data">
              <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
            </template>
            <template #head(third_party_reg)="data">
              <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
            </template>
            <template #head(location_code)="data">
              <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
            </template>
            <template #head(currency_code)="data">
              <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
            </template>
            <template #head(total_value)="data">
              <div style="text-align:center !important;">
                <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
              </div>
            </template>
            <template #cell(total_value)="data">
              <span class="text-fix-po">
                {{
                  data.item.total_value !== 0
                    ? formattedCurrency(data.item.total_value)
                    : data.item.total_value
                }}</span
              >
            </template>
            <template #head(open_value)="data">
              <div style="text-align:center !important;">
                <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
              </div>
            </template>
            <template #cell(open_value)="data">
              <span class="text-fix-po">
                {{
                  data.item.open_value !== 0
                    ? formattedCurrency(data.item.open_value)
                    : data.item.open_value
                }}</span
              >
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
          <div
            class="pagination-count"
            v-if="gridData.length && totalRows <= perPage"
          >
            <p>
              Showing
              {{ totalRows == 0 ? totalRows : perPage * (currentPage - 1) + 1 }}
              to {{ totalRows }} of {{ totalRows }} entries
            </p>
          </div>
          <div class="pagination-count" v-else-if="gridData.length">
            <p>
              Showing {{ perPage * (currentPage - 1) + 1 }} to
              {{
                perPage * currentPage >= totalRows
                  ? totalRows
                  : perPage * currentPage
              }}
              of {{ totalRows }}
              entries
            </p>
          </div>
        </div>
      </md-card>
    </div>
    <b-modal
      v-model="showModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="wide-modal"
      title="PO Line Details"
      @hidden="openShowModal(false)"
    >
      <!-- <upload :invoiceDetails="invoiceDetails" /> -->
      <PoLineDetails :poLineRowDetails="poLineRowDetails" />
    </b-modal>
    <!-- <b-modal v-model="showDetailsModal" no-close-on-esc no-close-on-backdrop hide-footer size="wide-modal"
      title="Mismatch Details" @hidden="openDetailsModal(false)">
      <MismatchDetails :apInvoiceId="apInvoiceId" />
    </b-modal> -->
  </div>
</template>

<script>
import commonHelper from "../../../utility/common.helper.utility";
// import upload from "./uploadinvoice.vue";
import PoLineDetails from "./PoLineDetails.vue";
// import MismatchDetails from "./MismatchDetails.vue";
import DatePicker from "vue2-datepicker";
import HeaderSummary from "../HeaderSummary.vue";

export default {
  components: {
    // upload,
    PoLineDetails,
    // MismatchDetails
    DatePicker,
    HeaderSummary,
  },
  name: "PoDetails",
  watch: {
    currentPage: function() {
      this.getVendorPoDetails();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getVendorPoDetails();
    },
  },
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      loader: null,
      // apInvoiceId: null,
      // vendorInvoiceCount: {
      //   new: 0,
      //   rejected: 0,
      //   schedule_to_pay: 0,
      //   pending_approval: 0,
      //   gst_mismatch: 0,
      //   gst_missing: 0
      // },
      // tabName: null,

      gridData: [
        // {
        //   po_num: "20",
        //   currency: "INR",
        //   first_party: "PO",
        //   third_party: "Vendor",
        //   ship_to_location: "abc",
        //   total_value: "65342465234",
        //   open_value: "3564",
        // },
      ],

      poNum: null,
      poAmtFrom: null,
      poAmtTo: null,
      creationDateFrom: null,
      creationDateTo: null,
      poLineRowDetails: null,
      // new: null,
      // pending_approval: null,
      // rejected: null,
      // schedule_to_pay: null,
      // gst_mismatch: null,
      // gst_missing: null,
      // unsubscribe: null,
      showModal: false,
      PoLineDetails: null,
      // showDetailsModal: false,
      gridField: [
        {
          key: "legel_entity",
          label: "Legal Entity",
        },
        {
          key: "po_number",
          label: "PO Number",
        },
        {
          key: "revision_num",
          label: "Revision Number",
        },
        {
          key: "creation_date",
        },
        {
          key: "full_name",
          label: "Buyer Name",
          class: "party-col",
        },
        {
          key: "first_party_reg",
          label: "Company GSTN",
          class: "party-col",
        },
        {
          key: "third_party_reg",
          label: "Third Party GSTN",
          class: "party-col",
        },
        {
          key: "location_code",
          class: "party-col",
        },
        {
          key: "total_value",
          class: "text-fix-po",
        },
        {
          key: "open_value",
          class: "text-fix-po",
        },
        {
          key: "currency_code",
          label: "Currency",
          class: "width-fix",
        },
      ],
      selected: [],
      location: null,
      totalPayload: null,
      // menuId: this.$store.state.auth.PODetails.menu_id,
      menuId: null,
      vendorId: null,
      // btnActionName: "rejectedReport",
      // tableData: [],
      // tabValue: "viewProfile",
      // isNavigatedFromVendorOs: false,
      // currentFinYear: null
    };
  },
  mounted() {
    const menuItem = JSON.parse(sessionStorage.getItem("PODetails"));
    this.vendorId = JSON.parse(sessionStorage.getItem("VendorId"));
    if (menuItem) {
      this.menuId = menuItem.menu_id;
    }
    if (this.vendorId) {
      this.getVendorPoDetails();
    }
    // if (!this.$store.state.vendor.vendorId) {
    //   alert("Vendor is Not Approved");
    // }
    //   this.isNavigatedFromVendorOs = this.$store.state.shared.vendorOsFlag;
    //   this.leId = sessionStorage.getItem("leId")
    //     ? sessionStorage.getItem("leId")
    //     : "All";
    //   this.finYear = sessionStorage.getItem("finYear")
    //     ? sessionStorage.getItem("finYear")
    //     : "All";
    //   this.currentFinYear = sessionStorage.getItem("currentFinYear")
    //     ? sessionStorage.getItem("currentFinYear")
    //     : null;
    //   this.invoiceDetails = null;
    //   this.getVendorDashboardCount();
    //   this.getVendorDashboardCountGst();
    //   // this.navigationBasedSearch();
    //   // this.unsubscribe = this.$store.subscribe((mutation, state) => {
    //   //   if (mutation.type === "shared/setLeId" || mutation.type === "shared/setFinYear") {
    //   //     this.leId = state.shared.leId;
    //   //     this.finYear = state.shared.finYear;
    //   //     this.getVendorDashboardCount();
    //   //     this.getVendorDashboardCountGst();
    //   //     // this.navigationBasedSearch();
    //   //   }
    //   // });
  },
  methods: {
    downloadExcelFile() {
      this.loader = true;
      /**
       * @param(payload, 'action name', 'file name')
       */
      const downloadpayload = { ...this.totalPayload };
      downloadpayload._limit = this.totalRows;
      this.downloadExcel.downloadData(
        downloadpayload,
        "vendor/getVendorPoDetails",
        "po-details",
        () => (this.loader = false)
      );
    },
    formattedCurrency(num) {
      return commonHelper.formatAmount(Number(num));
    },
    disabledDates(date) {
      return commonHelper.disabledDates(date, this.creationDateFrom);
    },
    isVendorApproved() {
      if (this.vendorId) {
        this.getVendorPoDetails();
      } else {
        alert("Vendor is Not Approved");
      }
    },
    getVendorPoDetails() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        amount_from: this.poAmtFrom ? this.poAmtFrom : "",
        amount_to: this.poAmtTo ? this.poAmtTo : "",
        vendor_id: this.vendorId,
        po_num: this.poNum ? this.poNum : "",
        po_creation_from_date: this.creationDateFrom,
        po_creation_to_date: this.creationDateTo,
        location_code: this.location,
      };
      this.totalPayload = payload;
      this.loader = true;
      this.$store
        .dispatch("vendor/getVendorPoDetails", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.gridData = result.map((elem) => {
              elem.creation_date = commonHelper.formatDate(elem.creation_date);
              return elem;
            });
            this.totalRows = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    downloadCustomExcel() {
      this.totalPayload._limit = this.totalRows;
      this.loader = true;
      this.$store
        .dispatch("vendor/getVendorPoDetails", this.totalPayload)
        .then((resp) => {
          this.loader = false;
          const result = resp.data.data.data.page.map((elem) => {
            elem.creation_date = commonHelper.formatDate(elem.creation_date);
            elem.total_value =
              elem.total_value !== 0
                ? commonHelper.formatAmount(elem.total_value)
                : elem.total_value;
            elem.open_value =
              elem.open_value !== 0
                ? commonHelper.formatAmount(elem.open_value)
                : elem.open_value;
            return elem;
          });
          const customDownloadData = result.map((obj) => ({
            legal_entity: obj.legel_entity,
            po_number: obj.only_po_number,
            revision_num: obj.revision_num,
            creation_date: obj.creation_date,
            buyer_name: obj.full_name,
            company_gstn: obj.first_party_reg,
            third_party_gstn: obj.third_party_reg,
            location_code: obj.location_code,
            total_value: obj.total_value,
            open_value: obj.open_value,
            currency: obj.currency_code,
          }));
          if (customDownloadData) {
            commonHelper.downloadExcel(customDownloadData, "po-details");
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    // getVendorInvoiceGridData() {
    //   const payload = {
    //     _page: this.currentPage - 1,
    //     _limit: this.perPage,
    //     invoice_no: this.invoice_no,
    //     invoice_amount_from: this.invoice_amount_from,
    //     invoice_amount_to: this.invoice_amount_to,
    //     invoice_date_from: commonHelper.formattedDate(this.invoice_date_from),
    //     invoice_date_to: commonHelper.formattedDate(this.invoice_date_to),
    //     le_id: this.leId !== "All" ? this.leId : null,
    //     fin_year: this.finYear !== "All" ? this.finYear : null
    //   };
    //   // this.gridData = [];
    //   this.$store.dispatch("shared/setLoader", true);
    //   this.$store
    //     .dispatch("vendor/getVendorInvoiceGridData", payload)
    //     .then((resp) => {
    //       this.$store.dispatch("shared/setLoader", false);
    //       if (resp.status === 200) {
    //         this.gridField[11] = { key: "2a_status", label: "2A Status" };
    //         this.gridData = resp.data.data.page;
    //         this.totalRows = resp.data.data.total_elements;
    //       }
    //     });

    // },

    // getVendorDashboardCount() {
    //   const payload = {
    //     le_id: this.leId !== "All" ? this.leId : null,
    //     fin_year: this.finYear !== "All" ? this.finYear : this.currentFinYear
    //   };
    //   this.$store.dispatch("shared/setLoader", true);
    //   this.$store
    //     .dispatch("vendor/getVendorDashboardCount", payload)
    //     .then((response) => {
    //       this.$store.dispatch("shared/setLoader", false);
    //       if (response.status === 200) {
    //         const results = response.data.data;
    //         this.vendorInvoiceCount.rejected = results.rejected;
    //         this.vendorInvoiceCount.schedule_to_pay = results.schedule_to_pay;
    //         this.vendorInvoiceCount.pending_approval = results.pending_approval;
    //         this.vendorInvoiceCount.new = results.new;
    //       }
    //     })
    //     .catch(() => {
    //       this.$store.dispatch("shared/setLoader", false);
    //     });
    // },

    // getVendorDashboardCountGst() {
    //   const payload = {
    //     le_id: this.leId !== "All" ? this.leId : null,
    //     fin_year: this.finYear !== "All" ? this.finYear : this.currentFinYear
    //   };
    //   this.$store.dispatch("shared/setLoader", true);
    //   this.$store
    //     .dispatch("vendor/getVendorDashboardCountGst", payload)
    //     .then((response) => {
    //       this.$store.dispatch("shared/setLoader", false);
    //       if (response.status === 200) {
    //         const results = response.data.data;
    //         this.vendorInvoiceCount.gst_mismatch = results.gst_mismatch;
    //         this.vendorInvoiceCount.gst_missing = results.gst_missing;
    //       }
    //     })
    //     .catch(() => {
    //       this.$store.dispatch("shared/setLoader", false);
    //     });
    // },

    // getRejectedInvoice() {
    //   this.tabName = 'reject';
    //   const payload = {
    //     _page: this.currentPage - 1,
    //     _limit: this.perPage,
    //     invoice_no: this.invoice_no,
    //     invoice_amount_from: this.invoice_amount_from,
    //     invoice_amount_to: this.invoice_amount_to,
    //     invoice_date_from: commonHelper.formattedDate(this.invoice_date_from),
    //     invoice_date_to: commonHelper.formattedDate(this.invoice_date_to),
    //     le_id: this.leId !== "All" ? this.leId : null,
    //     fin_year: this.finYear !== "All" ? this.finYear : this.currentFinYear
    //   };
    //   this.$store.dispatch("shared/setLoader", true);
    //   this.$store
    //     .dispatch("vendor/getRejectedInvoice", payload)
    //     .then((response) => {
    //       this.$store.dispatch("shared/setLoader", false);
    //       if (response.status === 200) {
    //         this.gridField[11] = { key: "2a_status", label: "2A Status" };
    //         this.gridData = response.data.data.page;
    //         this.totalRows = response.data.data.total_elements;
    //       }
    //     })
    //     .catch(() => {
    //       this.$store.dispatch("shared/setLoader", false);
    //     });
    // },
    // getGstrMismatch() {
    //   this.tabName = 'mismatch';
    //   const payload = {
    //     _page: this.currentPage - 1,
    //     _limit: this.perPage,
    //     invoice_no: this.invoice_no,
    //     invoice_amount_from: this.invoice_amount_from,
    //     invoice_amount_to: this.invoice_amount_to,
    //     invoice_date_from: commonHelper.formattedDate(this.invoice_date_from),
    //     invoice_date_to: commonHelper.formattedDate(this.invoice_date_to),
    //     le_id: this.leId !== "All" ? this.leId : null,
    //     fin_year: this.finYear !== "All" ? this.finYear : this.currentFinYear
    //   };
    //   this.$store.dispatch("shared/setLoader", true);
    //   this.$store
    //     .dispatch("vendor/getGstrMismatch", payload)
    //     .then((response) => {
    //       this.$store.dispatch("shared/setLoader", false);
    //       if (response.status === 200) {
    //         this.gridField[11] = { key: "details", label: "Mismatch Details" };
    //         this.gridData = response.data.data.page;
    //         this.totalRows = response.data.data.total_elements;
    //       }
    //     })
    //     .catch(() => {
    //       this.$store.dispatch("shared/setLoader", false);
    //     });
    // },
    // getGstrMissing() {
    //   this.tabName = 'missing';
    //   const payload = {
    //     _page: this.currentPage - 1,
    //     _limit: this.perPage,
    //     invoice_no: this.invoice_no,
    //     invoice_amount_from: this.invoice_amount_from,
    //     invoice_amount_to: this.invoice_amount_to,
    //     invoice_date_from: commonHelper.formattedDate(this.invoice_date_from),
    //     invoice_date_to: commonHelper.formattedDate(this.invoice_date_to),
    //     le_id: this.leId !== "All" ? this.leId : null,
    //     fin_year: this.finYear !== "All" ? this.finYear : this.currentFinYear
    //   };
    //   this.$store.dispatch("shared/setLoader", true);
    //   this.$store
    //     .dispatch("vendor/getGstrMissing", payload)
    //     .then((response) => {
    //       this.$store.dispatch("shared/setLoader", false);
    //       if (response.status === 200) {
    //         this.gridField[11] = { key: "2a_status", label: "2A Status" };
    //         this.gridData = response.data.data.page;
    //         this.totalRows = response.data.data.total_elements;
    //       }
    //     })
    //     .catch(() => {
    //       this.$store.dispatch("shared/setLoader", false);
    //     });
    // },
    // getScheduleToPay() {
    //   this.tabName = 'schedule';
    //   const payload = {
    //     _page: this.currentPage - 1,
    //     _limit: this.perPage,
    //     invoice_no: this.invoice_no,
    //     invoice_amount_from: this.invoice_amount_from,
    //     invoice_amount_to: this.invoice_amount_to,
    //     invoice_date_from: commonHelper.formattedDate(this.invoice_date_from),
    //     invoice_date_to: commonHelper.formattedDate(this.invoice_date_to),
    //     le_id: this.leId !== "All" ? this.leId : null,
    //     fin_year: this.finYear !== "All" ? this.finYear : this.currentFinYear
    //   };
    //   this.$store.dispatch("shared/setLoader", true);
    //   this.$store
    //     .dispatch("vendor/getScheduleToPay", payload)
    //     .then((response) => {
    //       this.$store.dispatch("shared/setLoader", false);
    //       if (response.status === 200) {
    //         this.gridField[11] = { key: "2a_status", label: "2A Status" };
    //         this.gridData = response.data.data.page;
    //         this.totalRows = response.data.data.total_elements;
    //       }
    //     })
    //     .catch(() => {
    //       this.$store.dispatch("shared/setLoader", false);
    //     });
    // },
    // getPendingApprovals() {
    //   this.tabName = 'pending';
    //   const payload = {
    //     _page: this.currentPage - 1,
    //     _limit: this.perPage,
    //     invoice_no: this.invoice_no,
    //     invoice_amount_from: this.invoice_amount_from,
    //     invoice_amount_to: this.invoice_amount_to,
    //     invoice_date_from: commonHelper.formattedDate(this.invoice_date_from),
    //     invoice_date_to: commonHelper.formattedDate(this.invoice_date_to),
    //     le_id: this.leId !== "All" ? this.leId : null,
    //     fin_year: this.finYear !== "All" ? this.finYear : this.currentFinYear
    //   };
    //   this.$store.dispatch("shared/setLoader", true);
    //   this.$store
    //     .dispatch("vendor/getPendingApprovals", payload)
    //     .then((response) => {
    //       this.$store.dispatch("shared/setLoader", false);
    //       if (response.status === 200) {
    //         this.gridField[11] = { key: "2a_status", label: "2A Status" };
    //         this.gridData = response.data.data.page;
    //         this.totalRows = response.data.data.total_elements;
    //       }
    //     })
    //     .catch(() => {
    //       this.$store.dispatch("shared/setLoader", false);
    //     });
    // },
    // getNewSubmission() {
    //   this.tabName = 'new';
    //   const payload = {
    //     _page: this.currentPage - 1,
    //     _limit: this.perPage,
    //     invoice_no: this.invoice_no,
    //     invoice_amount_from: this.invoice_amount_from,
    //     invoice_amount_to: this.invoice_amount_to,
    //     invoice_date_from: commonHelper.formattedDate(this.invoice_date_from),
    //     invoice_date_to: commonHelper.formattedDate(this.invoice_date_to),
    //     le_id: this.leId !== "All" ? this.leId : null,
    //     fin_year: this.finYear !== "All" ? this.finYear : this.currentFinYear
    //   };
    //   this.$store.dispatch("shared/setLoader", true);
    //   this.$store
    //     .dispatch("vendor/getPendingApprovals", payload)
    //     .then((response) => {
    //       this.$store.dispatch("shared/setLoader", false);
    //       if (response.status === 200) {
    //         this.gridField[11] = { key: "2a_status", label: "2A Status" };
    //         this.gridData = response.data.data.page;
    //         this.totalRows = response.data.data.total_elements;
    //       }
    //     })
    //     .catch(() => {
    //       this.$store.dispatch("shared/setLoader", false);
    //     });
    // },
    // getVendorInvoiceDetails() {
    //   const payload = {
    //     _page: this.currentPage - 1,
    //     _limit: this.perPage,
    //     invoice_no: this.invoice_no,
    //     invoice_amount_from: this.invoice_amount_from,
    //     invoice_amount_to: this.invoice_amount_to,
    //     invoice_date_from: commonHelper.formattedDate(this.invoice_date_from),
    //     invoice_date_to: commonHelper.formattedDate(this.invoice_date_to),
    //     le_id: this.leId !== "All" ? this.leId : null,
    //     fin_year: this.finYear !== "All" ? this.finYear : null
    //   };
    //   // this.gridData = [];
    //   this.$store.dispatch("shared/setLoader", true);
    //   this.$store
    //     .dispatch("vendor/getVendorInvoiceDetails", payload)
    //     .then((resp) => {
    //       this.$store.dispatch("shared/setLoader", false);
    //       if (resp.status === 200) {
    //         this.gridField[11] = { key: "2a_status", label: "2A Status" };
    //         this.gridData = resp.data.data.page;
    //         this.totalRows = resp.data.data.total_elements;
    //       }
    //     });
    // },
    clearSearch() {
      this.poNum = null;
      this.poAmtFrom = null;
      this.poAmtTo = null;
      this.creationDateFrom = null;
      this.creationDateTo = null;
      this.location = null;
      this.getVendorPoDetails();
    },

    rowSelected(item) {
      this.poLineRowDetails = item;
      this.openShowModal(true);
    },

    openShowModal(flag) {
      // this.poLineRowDetails = item;
      this.showModal = flag;
    },

    // openDetailsModal(flag, apInvoiceId) {
    //   this.apInvoiceId = apInvoiceId;
    //   this.showDetailsModal = flag;
    // },

    // btnAction(actionName) {
    //   this.btnActionName = actionName;
    // },

    // selectedTab(tab) {
    //   this.tabValue = tab;
    // },

    // onSelect: function (items) {
    //   this.selected = items;
    // },

    // navigationBasedSearch(){
    //   if (this.isNavigatedFromVendorOs) {
    //   this.getVendorInvoiceDetails();
    // } else {
    //   this.getVendorInvoiceGridData();
    // }
    // },

    // tabDataSearch() {
    //   if (this.tabName === 'reject') {
    //     this.getRejectedInvoice();
    //   } else if (this.tabName === 'missing') {
    //     this.getGstrMissing();
    //   } else if (this.tabName === 'mismatch') {
    //     this.getGstrMismatch();
    //   } else if (this.tabName === 'schedule') {
    //     this.getScheduleToPay();
    //   } else if (this.tabName === 'pending') {
    //     this.getPendingApprovals();
    //   } else if (this.tabName === 'new') {
    //     this.getNewSubmission();
    //   } else {
    //     // this.navigationBasedSearch();
    //   }
    // }
  },
  // beforeDestroy() {
  //   this.$store.dispatch("shared/setVendorOsFlag", false);
  //   this.unsubscribe();
  // },
};
</script>
<style>
.vendor-icon {
  margin-right: 0px !important;
  padding: 0px !important;
}

.vendor-icon i {
  font-size: 20px !important;
  line-height: 60px !important;
  width: 30px !important;
  height: 30px !important;
  text-align: center;
}

.vendor-btn {
  margin-right: 10px;
  border-radius: 15px;
  margin-top: 5px !important;
}

.md-card .md-card-content {
  padding: 5px 0px !important;
}

.search-icon {
  margin: 0 5px !important;
}

.invocie-col > div {
  width: 160px !important;
}
.DateFormate {
  margin-top: 17px !important;
}
.total-value {
  max-width: 45px !important;
  text-align: right !important;
}
.width-fix {
  max-width: 80px !important;
}
.party-col > div {
  width: 160px !important;
}
.text-fix-po {
  margin-right: 23%;
  text-align: right;
}
.pagination-count {
  font-weight: bold;
  margin-top: -3.2% !important;
}
</style>
