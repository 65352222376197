<template>
  <div>
    <!-- <div class="md-layout-item md-medium-size-100 md-small-size-100 md-size-100">
            <md-card> -->
    <div class="overlayLoader" v-if="loader">
      <md-progress-spinner
        :md-diameter="100"
        class="md-accent spinner-postion"
        :md-stroke="7"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label for="font">Legal Entity</label>
          <md-input disabled v-model="invoiceHdrDetails.legal_entity">
          </md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label for="font">Invoice Type</label>
          <md-input disabled v-model="invoiceHdrDetails.Invoice_type">
          </md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label for="font">Invoice No.</label>
          <md-input disabled v-model="invoiceHdrDetails.invoice_num">
          </md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label>Invoice Amount</label>
          <md-input
            disabled
            type="text"
            v-model="invoiceHdrDetails.invoice_amount"
          ></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label>Currency</label>
          <md-input
            disabled
            type="text"
            v-model="invoiceHdrDetails.invoice_currency_code"
          ></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label>Invoice Date</label>
          <md-input
            disabled
            type="text"
            v-model="invoiceHdrDetails.inovice_date"
          ></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label>Amount Paid</label>
          <md-input
            disabled
            type="text"
            v-model="invoiceHdrDetails.amount_paid"
          ></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label>TDS</label>
          <md-input
            disabled
            type="text"
            v-model="invoiceHdrDetails.tds_amount"
          ></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label>Company GSTN</label>
          <md-input
            disabled
            type="text"
            v-model="invoiceHdrDetails.first_party_req"
          ></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label>Third Party GSTN</label>
          <md-input
            disabled
            type="text"
            v-model="invoiceHdrDetails.third_party_req"
          ></md-input>
        </md-field>
      </div>
    </div>
    <!-- </md-card>
          </div> -->
    <!-- <div class="md-layout-item tabLayout md-medium-size-100 md-xsmall-size-100 md-size-100">
            <md-card> -->
    <div class="md-layout-item tabLayout">
      <b-table
        bordered
        striped
        hover
        outlined
        sticky-header
        :items="invoiceLineGridDetails"
        :fields="invoiceLineGridFields"
        show-empty
        md-height
      >
        <template #head(line_number)="data">
          <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
        </template>
        <template #head(rcm)="data">
          <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
        </template>
        <template #head(po_number)="data">
          <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
        </template>
        <template #head(item)="data">
          <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
        </template>
        <template #head(amount)="data">
          <div style="text-align:center !important;">
            <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
          </div>
        </template>
        <template #cell(amount)="data">
          <span style="margin-right: 36%;">
            {{ formattedCurrency(data.item.amount) }}</span
          >
        </template>
        <template #head(hsn)="data">
          <div style="text-align:left !important; margin-left:7%">
            <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
          </div>
        </template>
        <template #cell(hsn)="data">
          <span style="text-align:center;margin-right: 36%">
            {{ data.item.hsn }}</span
          >
        </template>
        <template #head(gst)="data">
          <div style="text-align:center !important;">
            <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
          </div>
        </template>
        <template #cell(gst)="data">
          <span style="margin-right: 45%;">
            {{ formattedCurrency(data.item.gst) }}</span
          >
        </template>
        <template #cell(rcm)="data">
          {{ data.item.rcm ? "Yes" : "No" }}
        </template>
      </b-table>
    </div>
    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      align="fill"
      size="sm"
      class="my-0"
    ></b-pagination>
    <div
      class="pagination-count"
      v-if="invoiceLineGridDetails.length && totalRows <= perPage"
    >
      <p>
        Showing
        {{ totalRows == 0 ? totalRows : perPage * (currentPage - 1) + 1 }} to
        {{ totalRows }} of {{ totalRows }} entries
      </p>
    </div>
    <div v-else-if="invoiceLineGridDetails.length" class="pagination-count">
      <p>
        Showing {{ perPage * (currentPage - 1) + 1 }} to
        {{
          perPage * currentPage >= totalRows ? totalRows : perPage * currentPage
        }}
        of {{ totalRows }} entries
      </p>
    </div>
    <!-- </md-card>
          </div> -->
  </div>
</template>

<script>
import commonHelper from "../../../utility/common.helper.utility";
import HeaderSummary from "../HeaderSummary.vue";

export default {
  name: "VendorInvoiceDetails",
  components: { HeaderSummary },
  watch: {
    currentPage: function() {
      this.getVendorInvoiceLineDetailsById();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getVendorInvoiceLineDetailsById();
    },
  },
  props: ["invoiceDetails"],
  data() {
    return {
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      totalRows: null,
      invoiceHdrDetails: {
        invoice_num: null,
        invoice_amount: null,
        invoice_currency_code: null,
        inovice_date: null,
        amount_paid: null,
        tds_amount: null,
        first_party_req: null,
        third_party_req: null,
      },
      loader: false,
      invoiceLineGridFields: [
        {
          key: "line_number",
          label: "Invoice Line No.",
        },
        {
          key: "item",
        },
        {
          key: "po_number",
          label: "PO Number",
        },
        {
          key: "hsn",
          label: "HSN/SAC",
        },
        {
          key: "amount",
          label: "Basic Amount",
          class: "text-fix-inv-line",
        },
        {
          key: "gst",
          label: "GST",
          class: "text-fix-inv-line",
        },
        {
          key: "rcm",
          label: "RCM",
        },
      ],
      invoiceLineGridDetails: [],
      // menuId: this.$store.state.auth.InvoiceDashboard.menu_id,
      menuId: null,
    };
  },
  mounted() {
    const menuItem = JSON.parse(sessionStorage.getItem("InvoiceDashboard"));
    if (menuItem) {
      this.menuId = menuItem.menu_id;
    }
    if (this.invoiceDetails) {
      this.fillRecordForInvoiceDetails(this.invoiceDetails);
      this.getVendorInvoiceLineDetailsById();
    }
  },
  methods: {
    fillRecordForInvoiceDetails(result) {
      this.invoiceHdrDetails = result;
      this.invoiceHdrDetails.invoice_amount = this.formattedCurrency(
        result.invoice_amount
      );
      this.invoiceHdrDetails.tds_amount = this.formattedCurrency(
        result.tds_amount
      );
      this.invoiceHdrDetails.amount_paid = this.formattedCurrency(
        result.amount_paid
      );
    },
    formattedCurrency(num) {
      return commonHelper.formatAmount(num);
    },
    getVendorInvoiceLineDetailsById() {
      const payload = {
        invoice_id: this.invoiceDetails.invoice_id,
        org_id: this.invoiceDetails.org_id,
        // invoice_id: 10001,
        // org_id: 81,
        _page: this.currentPage - 1,
        _limit: this.perPage,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getVendorInvoiceLineDetailsById", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.invoiceLineGridDetails = result;
            this.totalRows = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
  },
};
</script>

<style>
.tabLayout {
  margin-top: 20px !important;
}
.text-fix-inv-line {
  text-align: right !important;
}
</style>
