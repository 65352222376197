<template>
  <b-row>
    <md-card-content>
      <div class="overlayLoader" v-if="loader">
        <md-progress-spinner
          :md-diameter="100"
          class="md-accent spinner-postion"
          :md-stroke="7"
          md-mode="indeterminate"
        ></md-progress-spinner>
      </div>
      <b-alert
        style="width:50%;"
        :variant="isSuccess ? 'primary' : 'danger'"
        :show="showAlert"
        dismissible
        fade
        @dismissed="showAlert = false"
        >{{ responseMsg }}
      </b-alert>
      <div class="md-layout-item">
        <b-table
          style="max-height:25rem;"
          striped
          hover
          outlined
          sticky-header
          :items="additionalData"
          :fields="additionalFields"
          show-empty
          class="bg-white"
        >
          <template #cell(add_info_desc_meaning)="data">
            <span :class="data.item.gl_calendar_dtl_id ? 'mandatory' : ''">{{
              data.item.add_info_desc_meaning
            }}</span>
          </template>
          <template #cell(add_info_value)="data">
            <b-form-checkbox
              v-if="
                data.item.add_info_desc === 'MSME' ||
                  data.item.add_info_desc === 'E-Invoice'
              "
              v-model="data.item.add_info_value"
              :value="true"
              :unchecked-value="false"
              :disabled="!editMode"
            ></b-form-checkbox>
            <!-- @change="
                checkMsmeValue(
                  data.item.add_info_vset,
                  data.item.add_info_value,
                  data.item
                )
              " -->
            <b-form-input
              v-else
              v-model="data.item.add_info_value"
              v-numericOnly
              :disabled="!editMode || data.item.document_det_id > 0"
            ></b-form-input>
            <!-- <md-field v-else>
              <md-input v-model="data.item.add_info_vset_value"></md-input
            ></md-field> -->
          </template>
          <!-- <template #cell(certificate_no)="data">
            <b-form-input
              v-if="
                data.item.add_info_vset === 'MSME' &&
                  (data.item.add_info_value == 'true' ||
                    data.item.add_info_value == true)
              "
              v-model="data.item.certificate_no"
              :title="data.item.certificate_no"
              @input="upperCaseFunction(data.item.certificate_no, data.item)"
              :disabled="!editMode"
            ></b-form-input>
          </template> -->

          <template #cell(upload_document)="data">
            <md-field
              v-if="
                data.item.gl_calendar_dtl_id &&
                  data.item.add_info_value &&
                  data.item.add_info_value !== '0' &&
                  data.item.add_info_id &&
                  !data.item.document_det_id
              "
              md-clearable
            >
              <md-file
                v-model="data.item.upload_document"
                @md-change="selectFile"
              />
            </md-field>

            <a
              v-if="data.item.document_det_id"
              style="cursor:pointer;"
              @click="
                getOpenKmAuthenticate(
                  data.item.add_info_id,
                  data.item.document_det_id
                )
              "
              >{{ data.item.original_file_name }}</a
            >
          </template>
          <template #cell(save_document)="data">
            <a
              style="cursor:pointer;"
              v-if="data.item.upload_document"
              @click="getOpenKmAuthenticate(data.item.add_info_id)"
              >Upload</a
            >
          </template>
          <template
            #cell(delete)="data"
            v-if="approvalStatus === 'DRAFT' || approvalStatus === 'REJECTED'"
          >
            <span
              v-if="data.item.document_det_id"
              class="material-icons"
              style="cursor:pointer;"
              @click="getOpenKmAuthenticateDelete(data.item.document_det_id)"
            >
              delete
            </span>
          </template>

          <!-- <template #cell(view_document)="data">
            <a
              style="cursor:pointer;"
              @click="
                getOpenKmAuthenticate(
                  data.item.add_info_id,
                  data.item.document_det_id
                )
              "
              >{{ data.item.original_file_name }}</a
            >
          </template> -->
        </b-table>
      </div>

      <md-button
        style="margin-top:20px; margin-left:15px;"
        class="md-raised md-primary search-btn"
        @click="addEditAdditionalDetails()"
        v-if="!isAdditionalDetailsSaved"
        >Save</md-button
      >
      <md-button
        style="margin-top:20px; margin-left:15px;"
        class="md-raised md-primary search-btn"
        @click="editModeAdditionalDetails()"
        v-if="!editMode"
        >Edit</md-button
      >
      <span style="color:red; float:right;">
        <b>*Maximum file size 5 MB</b><br />
        <b>*only pdf, jpg, jpeg, png, zip file allowed</b> </span
      ><br />
      <span style="color:red; float:right;">
        <b
          >*Please enter 0 for the respective Financial Year if the company is
          not registered before 3 years</b
        >
      </span>
    </md-card-content>
  </b-row>
</template>

<script>
import URL_UTILITY from "../../../utility/url.utility";
export default {
  components: {},
  name: "PartyOnboardingAddDetails",
  props: ["additionalRowDetails", "approvalStatus"],
  data() {
    return {
      showAlert: false,
      loader: false,
      isSuccess: false,
      responseMsg: "",
      editMode: false,
      isAdditionalDetailsSaved: false,
      additionalData: [
        {
          add_info_id: null,
          add_info_vset: null,
          add_info_value: null,
          // certificate_no: null,
          gl_calendar_dtl_id: null,
        },
      ],
      additionalFields: [
        {
          key: "add_info_desc_meaning",
          label: "Additional Info",
        },
        {
          key: "add_info_value",
          label: "Additional Value",
        },
        // {
        //   key: "certificate_no",
        // },
        {
          key: "upload_document",
        },
        {
          key: "save_document",
        },
        {
          key: "delete",
        },
        // {
        //   key: "view_document",
        // },
      ],
      openKmAuth: null,
      fileRef: null,
      menuId: 374,
      submenuId: 83,
      addInfoId: null,
      isDocumentUploaded: false,
      downloadDocUrl: URL_UTILITY.getDocumentDownloadUrl,
    };
  },
  mounted() {
    if (this.additionalRowDetails) {
      this.getAdditionalDetailsById();
      this.editMode = false;
      this.isAdditionalDetailsSaved = true;
    } else {
      this.editMode = true;
      this.getDefaultDataAdditionalDetails();
    }
  },
  methods: {
    getOpenKmAuthenticateDelete(docDetailId) {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("uploadDocument/getOpenKmAuthenticate")
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            if (this.openKmAuth) {
              this.deleteDocument(docDetailId, this.openKmAuth);
            }
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    deleteDocument(docDetailId, token) {
      const promt = confirm("Are you sure, you want to delete this document?");
      if (promt) {
        const payload = {
          docDetailId: docDetailId,
          token: token,
        };
        this.$store
          .dispatch("vendor/deleteOpenKmDoc", payload)
          .then((response) => {
            if (response.status === 200) {
              alert("Document Deleted Successfully");
              this.getAdditionalDetailsById();
              this.$emit("updatedList");
            } else {
              alert(response.response.data.message || "Something went wrong");
            }
          })
          .catch(() => {});
      }
    },
    getDefaultDataAdditionalDetails() {
      this.loader = true;
      this.$store
        .dispatch("vendor/getDefaultDataAdditionalDetails")
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            this.additionalData = result;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    // validateDetails() {
    //   if (this.additionalData[0].add_info_value == true) {
    //     if (this.additionalData[0].certificate_no) {
    //       this.addEditAdditionalDetails();
    //     } else {
    //       alert("Please Enter Certificate Number.");
    //     }
    //   } else {
    //     this.addEditAdditionalDetails();
    //   }
    // },
    addEditAdditionalDetails() {
      const additionalDetails = this.additionalData.map((elem) => {
        return {
          add_info_id: elem.add_info_id ? elem.add_info_id : 0,
          add_info_vset: elem.add_info_vset,
          add_info_value: elem.add_info_value,
          gl_calendar_dtl_id: elem.gl_calendar_dtl_id,
          // add_info_value:
          //   (elem.add_info_vset == "MSME" ||
          //     elem.add_info_vset == "EINVOICE") &&
          //   elem.add_info_value
          //     ? true
          //     : (elem.add_info_vset == "MSME" ||
          //         elem.add_info_vset == "EINVOICE") &&
          //       !elem.add_info_value
          //     ? false
          //     : elem.add_info_value,
          // certificate_no: elem.certificate_no ? elem.certificate_no : null,
        };
      });
      const filterData = additionalDetails.filter(
        (elem) => elem.gl_calendar_dtl_id && !elem.add_info_value
      );
      if (!filterData.length) {
        const payload = {
          party_req_id: this.$store.state.auth.partyId,
          additional_info_party_req_dtls: additionalDetails
            ? additionalDetails
            : null,
        };
        this.loader = true;
        this.$store
          .dispatch("vendor/addEditAdditionalDetails", payload)
          .then((response) => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.editMode = false;
              this.isSuccess = true;
              this.responseMsg = response.data.message;
              this.isAdditionalDetailsSaved = true;
              this.$emit("updatedList");
              this.getAdditionalDetailsById();
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      } else {
        alert("Please Enter Last 3 Years Turnover.");
      }
    },
    getAdditionalDetailsById() {
      const payload = {
        party_req_id: this.$store.state.auth.partyId,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getAdditionalDetailsById", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            this.additionalData = result;
            // this.additionalData.map((elem) => {
            //   if (
            //     elem.add_info_vset == "MSME" &&
            //     elem.add_info_value == "true"
            //   ) {
            //     this.additionalFields.push({
            //       key: "certificate_no",
            //     });
            //   }
            // });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    // checkMsmeValue(msme, msmeValue, item) {
    //   if (msme == "MSME" && msmeValue) {
    //     this.additionalFields.push({
    //       key: "certificate_no",
    //     });
    //   } else if (msme == "MSME" && !msmeValue) {
    //     this.additionalFields.pop({
    //       key: "certificate_no",
    //     });
    //     item.certificate_no = null;
    //   }
    // },
    editModeAdditionalDetails() {
      if (
        this.approvalStatus === "DRAFT" ||
        this.approvalStatus === "REJECTED"
      ) {
        this.editMode = true;
        this.isAdditionalDetailsSaved = false;
      } else {
        alert("Record is in " + this.approvalStatus + " Stage");
      }
    },
    // upperCaseFunction(certNumber, item) {
    //   if (certNumber) {
    //     item.certificate_no = certNumber.toUpperCase();
    //   }
    // },
    getOpenKmAuthenticate(add_info_id, docDtlId) {
      this.addInfoId = add_info_id;
      if (docDtlId) {
        this.isDocumentUploaded = true;
      } else {
        this.isDocumentUploaded = false;
      }
      this.loader = true;
      this.$store
        .dispatch("uploadDocument/getOpenKmAuthenticate")
        .then((response) => {
          this.loader = false;
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            if (!this.isDocumentUploaded) {
              this.addEditAdditionalDetails();
              this.uploadFile();
            } else {
              window.location.href =
                this.downloadDocUrl +
                "/download/" +
                docDtlId +
                "?token=" +
                this.openKmAuth;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectFile(event) {
      if (event[0].size <= 5242880) {
        this.fileRef = event;
      } else {
        this.fileRef = null;
        this.showAlert = true;
        this.isSuccess = false;
        this.responseMsg = event[0].name + " file should be less than 5 MB.";
      }
    },
    uploadFile() {
      if (this.fileRef) {
        let formData = new FormData();
        formData.append("file", this.fileRef.item(0));
        formData.append("token", this.openKmAuth);
        formData.append("doc_detail_id", 0);
        formData.append("menu_id", this.menuId);
        formData.append("sub_menu_id", this.submenuId);
        formData.append("record_id", this.addInfoId);
        this.loader = true;
        this.$store
          .dispatch("uploadDocument/uploadFile", formData)
          .then((response) => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.editMode = false;
              this.isSuccess = true;
              this.responseMsg = response.data.message;
              this.isDocumentUploaded = true;
              this.isAdditionalDetailsSaved = true;
              this.getAdditionalDetailsById();
              this.$emit("updatedList");
            } else {
              this.isSuccess = false;
              this.responseMsg =
                response.data.message || "Something went wrong";
            }
          })
          .catch(() => {
            this.loader = false;
          });
      } else {
        alert("Please select file less than 5 MB");
      }
    },
  },
};
</script>
<style scoped>
.mx-datepicker {
  width: 85% !important;
  height: 26px !important;
}
.md-field {
  padding-bottom: 0px !important;
  padding-top: 0 !important;
  margin: 0px 0 0 !important;
  min-height: auto !important;
  width: 80% !important;
}
</style>
