// =========================================================
// * Vue Material Dashboard Laravel - v1.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-dashboard-laravel
// * Copyright Creative Tim (https://www.creative-tim.com) & UPDIVISION (https://www.updivision.com)
//
// * Coded by www.creative-tim.com & www.updivision.com
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from "vue";
import axios from "axios";

// Plugins
import App from "./App.vue";
import Chartist from "chartist";
import VueAxios from "vue-axios";
import DashboardPlugin from "./material-dashboard";
import valueSetModal from "./pages/Dashboard/ValueSetModal/valueSetModal.vue";
import "vue-search-select/dist/VueSearchSelect.css";
import Vuelidate from "vuelidate";
// import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue2-datepicker/index.css";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import OtpInput from "@bachdgvn/vue-otp-input";
import downloadExcel from "./utility/downloadExcel";
// import valueSetModal from "./pages/valueSetModal.vue";
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Vuelidate);
const eventBus = new Vue();

// mixin
Vue.mixin({
  data() {
    return {
      eventBus,
      downloadExcel,
    };
  },
});

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueAxios, axios);

// components
Vue.component("v-otp-input", OtpInput);
Vue.component("valueSetModal", valueSetModal);
// Vue.component('valueSetModal', valueSetModal);

// router & store setup
import router from "./router";
import store from "./store";

// global library setup
Vue.prototype.$Chartist = Chartist;
Vue.directive("numericOnly", {
  bind(el) {
    el.addEventListener("keypress", (event) => {
      let keyCode = event.keyCode ? event.keyCode : event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        event.preventDefault();
      }
    });
  },
});

/* eslint-disable no-new */
const app = new Vue({
  router: router,
  store: store,
  el: "#app",
  render: (h) => h(App),
});

store.$app = app;
