import http from "@/services/httpServices";
import URL_UTILITY from "@/utility/url.utility";

const state = {
  list: {},
};

const mutations = {};

const actions = {
  getOpenKmAuthenticate() {
    return http.postApi(URL_UTILITY.getOpenKmAuthenticateUrl);
  },
  getUploadedDocDtl(context, payload){
    return http.getApi(URL_UTILITY.getUploadedDocDtlUrl, payload);
  },
  uploadFile(context, payload){
    return http.postApi(URL_UTILITY.getUploadFileUrl, payload)
  },
  delteOpenKmDoc(context, docDtlId){
    return http.deleteApi(URL_UTILITY.getOpenKmDocDeleteUrl + '/' + docDtlId);
  }
};

const getters = {};

const uploadDocument = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default uploadDocument;