<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <div class="md-layout">
          <md-card-header class="md-card-header-text md-card-header-green">
            <div class="card-icon vendor-icon">
              <md-icon style="font-size: 30px !important">summarize</md-icon>
            </div>
          </md-card-header>
          <div class="md-layout-item md-small-size-100 md-size-20">
            <md-field>
              <label>Vendor</label>
              <md-input
                v-model="vendor"
                @click="openVendorModal(true)"
                readonly
              ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-datepicker md-immediately v-model="fromDate"
              ><label>GL From Date</label></md-datepicker
            >
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-datepicker md-immediately v-model="toDate"
              ><label>GL To Date</label></md-datepicker
            >
          </div>
          <div class="md-layout-item md-small-size-100 md-size-30">
            <div style="margin-top: 10px">
              <md-button
                class="md-raised md-info search-btn"
                @click="generateVendorData()"
                style="margin-right: 10px"
                >Generate</md-button
              >
              <a :href="downloadReport + '/' + vendorLegderId" target="_blank">
                <md-button
                  class="md-raised md-primary search-btn"
                  v-if="requestStatus === 'COMPLETED'"
                >
                  Download
                </md-button>
              </a>
              <md-button
                class="md-raised md-primary search-btn"
                v-if="requestStatus !== 'COMPLETED'"
                @click="getSubmitRequest()"
              >
                Refresh
              </md-button>
              <md-button
                class="md-raised md-rose search-btn"
                style="margin-left: 10px"
                @click="clear()"
              >
                Clear
              </md-button>
            </div>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-10">
            <md-field
              ><label>Status</label>
              <md-input v-model="requestStatus" readonly></md-input
            ></md-field>
          </div>
        </div>
      </md-card>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header
          class="md-card-header-text md-layout-item md-card-header-rose"
        >
          <div class="card-text">
            <h6 class="title">Vendor Ledger</h6>
          </div>
        </md-card-header>
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-20">
            <md-field>
              <label>Investor Name</label>
              <md-input type="text" v-model="investorName" disabled></md-input>
            </md-field>
          </div>
          <!-- <div class="md-layout-item md-small-size-100 md-size-20">
          <md-field>
            <label>Statement For Period of</label>
            <md-input type="text" v-model="statementForPeriodOf" disabled></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-field>
            <label>Escalation Due Date</label>
            <md-input type="text" v-model="escalationDueDate" disabled></md-input>
          </md-field>
        </div> -->
          <div class="md-layout-item md-small-size-100 md-size-20">
            <md-field>
              <label>PAN No.</label>
              <md-input type="text" v-model="panNo" disabled></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-20">
            <md-field>
              <label>LE Name</label>
              <md-input type="text" v-model="leName" disabled></md-input>
            </md-field>
          </div>

          <!-- <div class="md-layout-item md-small-size-100 md-size-20">
            <md-field>
              <label>Security Deposit Raised</label>
              <md-input
                type="text"
                v-model="securityDepositRaised"
                disabled
              ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-20">
            <md-field>
              <label>Security Deposit Paid</label>
              <md-input
                type="text"
                v-model="securityDepositPaid"
                disabled
              ></md-input>
            </md-field>
          </div> -->
        </div>
        <div class="md-layout-item" style="margin-top: 15px">
          <b-table
            striped
            hover
            outlined
            sticky-header
            :items="gridData"
            :fields="gridField"
            show-empty
            class="bg-white"
            md-height
          >
            <template #cell(unit_billable_area)="data">
              {{ data.item.unit_billable_area }}
              {{ data.item.unit_billable_area_uom }}
            </template>
          </b-table>
        </div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </md-card>
    </div>
    <b-modal
      v-model="showModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="xl"
      title="Vendor"
      @hidden="openVendorModal(false, null)"
    >
      <b-table
        striped
        hover
        outlined
        :items="gridDataVendor"
        :fields="gridFieldVendor"
        show-empty
        class="bg-white"
        sticky-header
        @row-clicked="selectedVendor"
      >
      </b-table>
      <vendor />
    </b-modal>
  </div>
</template>

<script>
import commonHelper from "../../../utility/common.helper.utility";
import URL_UTILITY from "../../../utility/url.utility";
import vendor from "../Vendor/Vendor.vue";
export default {
  components: {
    vendor,
  },
  name: "",
  watch: {
    currentPage: function() {
      this.getVendorLedgerDetails();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getVendorLedgerDetails();
    },
  },
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      gridData: [],
      vendor: null,
      downloadReport: URL_UTILITY.getDownloadReportUrl,
      vendorLegderId: null,
      requestStatus: null,
      gridDataVendor: [],
      gridFieldVendor: [
        {
          key: "vendor",
        },
        {
          key: "vendor_id",
        },
      ],
      investorName: null,
      securityDepositPaid: null,
      gstn: null,
      securityDepositRaised: null,
      statementForPeriodOf: null,
      escalationDueDate: null,
      tanNo: null,
      panNo: null,
      leName: null,
      vendorId: null,
      showModal: false,
      requestId: null,
      templateId: null,
      submitRequestId: null,
      leId: null,
      finYear: null,
      fromDate: null,
      toDate: null,
      unsubscribe: null,
      gridField: [
        {
          key: "lease_prj_name",
          label: "Project Name",
          class: "prj-col",
        },
        {
          key: "gl_date_format",
          label: " GL Date",
        },
        {
          key: "unit_billable_area",
          label: "Unit Area",
        },
        {
          key: "invoice_num",
          label: "Invoice No.",
        },
        {
          key: "trx_date",
        },
        {
          key: "description",
        },
        {
          key: "applied_trx",
        },
        {
          key: "amount_dr",
          label: "Debit",
        },
        {
          key: "amount_cr",
          label: "Credit",
        },
        {
          key: "net_amt",
          label: "Net Amount",
        },
        {
          key: "running_balance",
        },
        {
          key: "trx_type",
        },
        {
          key: "trx_status",
          label: "Status",
        },
        {
          key: "tp_gst_num",
          key: "TP GST No.",
        },
      ],
    };
  },
  mounted() {
    this.leId = sessionStorage.getItem("leId")
      ? sessionStorage.getItem("leId")
      : "All";
    this.finYear = sessionStorage.getItem("finYear")
      ? sessionStorage.getItem("finYear")
      : "All";
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (
        mutation.type === "shared/setLeId" ||
        mutation.type === "shared/setFinYear"
      ) {
        this.leId = state.shared.leId;
        this.finYear = state.shared.finYear;
      }
    });
    this.getVendor();
    this.getVendorRequestId();
    this.getVendorTemplateId();
  },
  methods: {
    generateVendorData() {
      if (this.vendorId && this.fromDate && this.toDate) {
        const payload = {
          request_num: 0,
          request_id: this.requestId,
          template_id: this.templateId,
          schedule_type: "asap", //pass hardcode
          request_start_date: null,
          request_end_date: null,
          resubmit_interval: null,
          resubmit_interval_unit: null,
          day_of_month: null,
          day_of_week: null,
          output_format_id: "XLS", //pass hardcode
          no_of_args: 25,
          request_parameter:
            this.vendorId +
            "," +
            commonHelper.formattedDate(this.fromDate) +
            "," +
            commonHelper.formattedDate(this.toDate),
          sms_flag: false,
          email_flag: false,
          whatsapp_flag: false,
          child_req_count: null,
          email_comm_template_id: null,
          sms_comm_template_id: null,
          whatsapp_comm_template_id: null,
          argument1: this.vendorId,
          argument2: commonHelper.formattedDate(this.fromDate),
          argument3: commonHelper.formattedDate(this.toDate),
          argument4: null,
          argument5: null,
          argument6: null,
          argument7: null,
          argument8: null,
          argument9: null,
          argument10: null,
          argument11: null,
          argument12: null,
          argument13: null,
          argument14: null,
          argument15: null,
          argument16: null,
          argument17: null,
          argument18: null,
          argument19: null,
          argument20: null,
          argument21: null,
          argument22: null,
          argument23: null,
          argument24: null,
          argument25: null,
        };
        this.gridData = [];
        this.$store.dispatch("shared/setLoader", true);
        this.$store
          .dispatch("vendor/generateVendorData", payload)
          .then((resp) => {
            this.$store.dispatch("shared/setLoader", false);
            if (resp.status === 201) {
              this.vendorLegderId = resp.data.data[0].id;
              this.getVendorLedgerHdr();
              this.getVendorLedgerDetails();
              this.getSubmitRequest();
            }
          });
      } else {
        alert("Please Select From Date & To Date");
      }
    },
    getVendorLedgerHdr() {
      const payload = {
        vendor_id: this.vendorId,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getVendorLedgerHdr", payload)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            const result = resp.data.data;
            this.investorName = result.vendor_name;
            this.tanNo = result.tan_no;
            this.panNo = result.pan_no;
            this.securityDepositRaised = result.raised_sd;
            this.securityDepositPaid = result.paid_sd;
          }
        });
    },
    getVendorLedgerDetails() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        vendor_id: this.vendorId,
        gl_from_date: commonHelper.formattedDate(this.fromDate),
        gl_to_date: commonHelper.formattedDate(this.toDate),
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getVendorLedgerDetails", payload)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            this.gridData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        });
    },
    getVendor() {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getVendor")
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            const result = resp.data.data;
            this.gridDataVendor = result;
            this.vendor = result[0].vendor;
            this.vendorId = result[0].vendor_id;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    openVendorModal(flag) {
      this.showModal = flag;
    },
    selectedVendor(item) {
      this.vendorId = item.vendor_id;
      this.vendor = item.vendor;
      this.showModal = false;
    },
    getVendorRequestId() {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getVendorRequestId")
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            this.requestId = resp.data.data.request_id;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getVendorTemplateId() {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getVendorTemplateId")
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            this.templateId = resp.data.data.template_id;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getSubmitRequest() {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("customer/getSubmitRequest", this.vendorLegderId)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            this.generateRequest = resp.data.data;
            this.requestStatus = resp.data.data[0].status;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    clear() {
      this.fromDate = null;
      this.toDate = null;
      this.gridData = [];
      this.requestStatus = null;
      this.investorName = null;
      this.securityDepositRaised = null;
      this.gstn = null;
      this.securityDepositPaid = null;
      this.statementForPeriodOf = null;
      this.escalationDueDate = null;
      this.tanNo = null;
      this.panNo = null;
      this.leName = null;
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  },
};
</script>
<style>
.prj-col > div {
  width: 180px !important;
}
</style>
