<template>
  <div>
    <div class="overlayLoader" v-if="loader">
      <md-progress-spinner
        :md-diameter="100"
        class="md-accent spinner-postion"
        :md-stroke="7"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-small-size-100 md-size-25">
        <md-field>
          <label for="font">Legal Entity</label>
          <md-input
            v-model="invoicePaymentHdrDetails.legal_entity"
            :title="invoicePaymentHdrDetails.legal_entity"
            disabled
          >
          </md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-25">
        <md-field>
          <label for="font">Invoice Number</label>
          <md-input v-model="invoicePaymentHdrDetails.invoice_num" disabled>
          </md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-25">
        <md-field>
          <label for="font">Invoice Amount</label>
          <md-input v-model="invoicePaymentHdrDetails.invoice_amount" disabled>
          </md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-25">
        <md-field>
          <label for="font">Invoice Date</label>
          <md-input v-model="invoicePaymentHdrDetails.inovice_date" disabled>
          </md-input>
        </md-field>
      </div>
    </div>
    <div class="md-layout-item tabLayout">
      <b-table
        bordered
        striped
        hover
        outlined
        sticky-header
        :items="paymentDtlsData"
        :fields="paymentDtlsField"
        class="bg-white"
        show-empty
        md-height
      >
        <template #head(pmt_type)="data">
          <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
        </template>
        <template #head(check_id)="data">
          <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
        </template>
        <template #head(doc_sequence_value)="data">
          <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
        </template>
        <template #head(utr)="data">
          <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
        </template>
        <template #head(check_amount)="data">
          <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
        </template>
        <template #head(check_date)="data">
          <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
        </template>
        <template #cell(amount)="data">
          <span class="text-align-amt">
            {{ formattedCurrency(data.item.amount) }}</span
          >
        </template>
        <template #cell(check_amount)="data">
          <span class="text-align-check-amt">
            {{ formattedCurrency(data.item.check_amount) }}</span
          >
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import commonHelper from "../../../utility/common.helper.utility";
import HeaderSummary from "../HeaderSummary.vue";

export default {
  name: "VendorInvoicePaymentDetails",
  props: ["invoiceDetails"],
  components: {
    HeaderSummary,
  },
  data() {
    return {
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      totalRows: null,
      loader: false,
      invoicePaymentHdrDetails: {
        invoice_num: null,
        invoice_amount: null,
        inovice_date: null,
      },
      paymentDtlsData: [],
      paymentDtlsField: [
        { key: "pmt_type", label: "Payment Type" },
        { key: "check_id", label: "Cheque Number" },
        // { key: "invoice_num", label: "Invoice Number" },
        {
          key: "doc_sequence_value",
          label: "Document Sequence",
          class: "inv-col-fix",
        },
        // { key: "amount", class: "text-align-amt" },
        { key: "utr", label: "UTR" },
        {
          key: "check_amount",
          label: "Cheque Amount",
          class: "text-align-check-amt",
        },
        { key: "check_date", label: "Cheque Date" },
        // { key: "invoice_date", label: "Invoice Date" },
        // { key: "adv_adj", label: "Advance Adj." },
      ],
      menuId: null,
    };
  },
  mounted() {
    const menuItem = JSON.parse(sessionStorage.getItem("InvoiceDashboard"));
    if (menuItem) {
      this.menuId = menuItem.menu_id;
    }
    if (this.invoiceDetails) {
      this.fillRecordForInvoicePaymentDetails(this.invoiceDetails);
      this.getPaymentDetails(this.invoiceDetails);
    }
  },
  methods: {
    //
    getPaymentDetails(item) {
      const payload = {
        invoice_id: item.invoice_id,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getInvoicePaymentDtls", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.paymentDtlsData = result.map((elem) => {
              elem.check_date = commonHelper.formatDate(elem.check_date);
              elem.invoice_date = commonHelper.formatDate(elem.invoice_date);
              return elem;
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    //
    fillRecordForInvoicePaymentDetails(result) {
      this.invoicePaymentHdrDetails = result;
      this.invoicePaymentHdrDetails.invoice_amount = this.formattedCurrency(
        result.invoice_amount
      );
    },
    formattedCurrency(num) {
      return commonHelper.formatAmount(num);
    },
    getVendorInvoiceLineDetailsById() {
      const payload = {
        invoice_id: this.invoiceDetails.invoice_id,
        org_id: this.invoiceDetails.org_id,
        // invoice_id: 10001,
        // org_id: 81,
        _page: this.currentPage - 1,
        _limit: this.perPage,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getVendorInvoiceLineDetailsById", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.invoiceLineGridDetails = result;
            this.totalRows = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
  },
};
</script>

<style></style>
