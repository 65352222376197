<template>
  <md-toolbar
    md-elevation="0"
    class="md-transparent"
    :class="{
      'md-toolbar-absolute md-white md-fixed-top': $route.meta.navbarAbsolute,
    }"
  >
    <div class="overlay" v-if="loader">
      <md-progress-spinner
        :md-diameter="100"
        class="md-accent spinner-postion"
        :md-stroke="7"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <div class="md-toolbar-row">
      <div class="md-toolbar-section-start" style="margin-right: 10px">
        <md-button class="md-raised md-danger vendor-btn">{{
          $route.name
        }}</md-button>
      </div>
      <!-- <div class="md-toolbar-section-start">
        <div class="md-size-15">
          <md-field>
            <md-select name="leId" id="leId" v-model="leId" md-dense>
              <md-option value="All">Default LE</md-option>
              <md-option
                v-for="(item, index) in legalEntity"
                :key="index"
                :value="item.le_id"
                >{{ item.le_name }}</md-option
              >
            </md-select>
          </md-field>
        </div>
      </div>
      <div class="md-toolbar-section-start" style="margin: 0 10px">
        <div class="md-size-15">
          <md-field>
            <md-select name="finYear" v-model="finYear" md-dense>
              <md-option value="All">{{ currentFinYear }}</md-option>
              <md-option
                v-for="(item, index) in financialYearList"
                :key="index"
                :value="item.fy_display"
                >{{ item.fy_display }}</md-option
              >
            </md-select>
          </md-field>
        </div>
      </div> -->
      <div class="" style="margin-left:28rem; margin-top: 10px">
        <!-- <marquee style="background-color: black; width: 25rem"> -->
        <div class="marque-bg">
          <p style="text-align:center;" class="marque-text">
            Welcome :- <b>{{ partyName }}</b>
          </p>
        </div>
        <!-- <marquee class="marque-bg">
          <p class="marque-text">
            Welcome :- <b>{{ partyName }}</b>
          </p>
        </marquee> -->
      </div>
      <a
        target="_blank"
        class="md-layout-item md-small-size-100 md-size-10"
        v-if="$route.name === 'Party Onboarding'"
        :href="downloadDocUrl + '/' + fileName"
      >
        <md-button class="md-raised md-rose search-btn vendor-btn"
          >Process Manual</md-button
        >
      </a>
      <a
        target="_blank"
        class="md-layout-item md-small-size-100 md-size-10"
        v-if="$route.name === 'Vendor Invoice Entry'"
        :href="downloadDocUrl + '/' + invoiceFileName"
      >
        <md-button class="md-raised md-rose search-btn vendor-btn"
          >Process Manual</md-button
        >
      </a>
      <!-- <div
        style="margin-top:5px"
        class="md-layout-item md-small-size-100 md-size-10"
        v-if="$route.name === 'Party Onboarding'"
      >
        <md-button
          class="md-raised md-rose search-btn vendor-btn"
          @click="showHideManualModal(true)"
          >Manual</md-button
        >
      </div> -->
      <div class="md-toolbar-section-end">
        <div class="md-collapse">
          <md-list>
            <!-- <li class="md-list-item">
              <a
                @click="goToNotifications"
                class="
                  md-list-item-router md-list-item-container md-button-clean
                  dropdown
                "
              >
                <div class="md-list-item-content">
                  <drop-down direction="down">
                    <md-button
                      slot="title"
                      class="md-button md-just-icon md-simple"
                      data-toggle="dropdown"
                    >
                      <md-icon>notifications</md-icon>
                      <span class="notification">5</span>
                      <p class="hidden-lg hidden-md">Notifications</p>
                    </md-button>
                  </drop-down>
                </div>
              </a>
            </li> -->
            <md-list-item>
              <router-link style="" to="/settings/change-password">
                <i class="material-icons" title="Change Password">lock</i>
              </router-link>
            </md-list-item>
            <md-list-item @click="logOutUser">
              <i class="material-icons" title="Logout">logout</i>
              <p class="hidden-lg hidden-md">Profile</p>
            </md-list-item>
          </md-list>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
import URL_UTILITY from "../../../utility/url.utility";
import appStrings from "../../../utility/string.utility";
export default {
  components: {},
  watch: {
    leId: function(id) {
      sessionStorage.setItem("leId", id);
      this.$store.dispatch("shared/setLeId", id);
    },
    finYear: function(year) {
      sessionStorage.setItem("finYear", year);
      this.$store.dispatch("shared/setFinYear", year);
    },
    currentFinYear: function(currentYear) {
      sessionStorage.setItem("currentFinYear", currentYear);
      this.$store.dispatch("shared/setCurrentFinYear", currentYear);
    },
  },
  data() {
    return {
      selectedEmployee: "",
      employees: [
        "Jim Halpert",
        "Dwight Schrute",
        "Michael Scott",
        "Pam Beesly",
        "Angela Martin",
        "Kelly Kapoor",
        "Ryan Howard",
        "Kevin Malone",
      ],
      documentationLink:
        "https://vue-material-dashboard-laravel.creative-tim.com/documentation/",
      downloadUrl:
        "https://www.creative-tim.com/product/vue-material-dashboard-laravel",
      unsubscribe: null,
      loader: true,
      legalEntity: [],
      financialYearList: [],
      finYear: "All",
      leId: "All",
      currentFinYear: null,
      partyName: this.getName(),
      showManualModal: false,
      downloadDocUrl: URL_UTILITY.getManualDocumentUrl,
      fileName: appStrings.MANUALFILENAME,
      invoiceFileName: appStrings.INVOICEFILENAME,
    };
  },
  mounted() {
    // this.leId = sessionStorage.getItem("leId")
    //   ? sessionStorage.getItem("leId")
    //   : "All";
    // this.finYear = sessionStorage.getItem("finYear")
    //   ? sessionStorage.getItem("finYear")
    //   : "All";
    // this.getLegalEntity();
    // this.getFinancialYear();
    // this.unsubscribe = this.$store.subscribe((mutation, state) => {
    //   if (mutation.type === "shared/setLoader") {
    //     this.loader = state.shared.loader;
    //   }
    // });
  },
  methods: {
    getLegalEntity() {
      const payload = {
        _page: 0,
        _limit: 100,
      };
      this.$store.dispatch("shared/getLegalEntity", payload).then((resp) => {
        if (resp.status === 200) {
          this.legalEntity = resp.data.data.page;
        }
      });
    },
    getFinancialYear() {
      this.$store.dispatch("shared/getFinancialYear").then((resp) => {
        if (resp.status === 200) {
          this.financialYearList = resp.data.data;
          this.currentFinYear = this.financialYearList[0].current_financial_year;
          sessionStorage.setItem("currentFinYear", this.currentFinYear);
          this.$store.dispatch("shared/setCurrentFinYear", this.currentFinYear);
        }
      });
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    goToNotifications() {
      this.$router.push({ name: "Notifications" });
    },
    logOutUser() {
      this.$store.dispatch("logout");
    },
    // getName() {
    //   this.$store.dispatch("party/getDetails", "details").then((resp) => {
    //     if (resp.status === 200) {
    //       this.partyName = resp.data.data.party_details.party_name;
    //     }
    //   });
    // },
    getName() {
      const payload = {
        party_req_id: this.$store.state.auth.partyId,
      };
      this.loader = true;
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getVendorDetails", payload)
        .then((resp) => {
          this.loader = false;
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            const result = resp.data.data;
            this.partyName = result.name;
            // this.$store.commit("vendor/setVendorId", result.ora_party_id);
            // sessionStorage.setItem("VendorId", JSON.stringify(result.ora_party_id));
            // this.$store.commit("vendor/setVendorName", result.name);
            // sessionStorage.setItem("VendorName", JSON.stringify(result.name));
          }
        })
        .catch(() => {
          this.loader = false;
          this.$store.dispatch("shared/setLoader", false);
        });
    },
  },
  beforeDestroy() {
    // this.unsubscribe();
  },
};
</script>
<style>
.selected-menu {
  background-color: #fea01c;
  height: 35px !important;
  padding: 10px !important;
  margin-bottom: 5px !important;
  font-weight: 400 !important;
}
.vendor-btn {
  margin-right: 10px;
  border-radius: 15px;
  margin-top: 5px !important;
}
.marque-text {
  color: white;
  margin: 2px;
  font-weight: 500;
  font-style: italic;
  font-size: 16px;
}
.marque-bg {
  background-image: linear-gradient(to right, black, rgb(150, 148, 148));
  width: 25rem !important;
  border-radius: 5px !important;
}
</style>
