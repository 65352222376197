<template>
  <div>
    <div class="overlayLoader" v-if="loader">
      <md-progress-spinner
        :md-diameter="100"
        class="md-accent spinner-postion"
        :md-stroke="7"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>

    <div class="md-layout">
      <div class="md-layout-item md-small-size-100 md-size-16.5">
        <md-field>
          <label for="text">Legal Entity</label>
          <md-input
            v-model="paymentHdrDtls.le"
            :title="paymentHdrDtls.le"
            disabled
          ></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-16.5">
        <md-field>
          <label for="text">Cheque Number</label>
          <md-input v-model="paymentHdrDtls.check_id" disabled> </md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-16.5">
        <md-field>
          <label for="text">UTR Number</label>
          <md-input v-model="paymentHdrDtls.utr" disabled></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-16.5">
        <md-field>
          <label for="text">Cheque Date</label>
          <md-input v-model="paymentHdrDtls.check_date" disabled></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-16.5">
        <md-field>
          <label for="text">Cheque Amount</label>
          <md-input v-model="paymentHdrDtls.check_amount" disabled></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-16.5">
        <md-field>
          <label>Currency</label>
          <md-input
            v-model="paymentHdrDtls.currency_code"
            :title="paymentHdrDtls.currency_code"
            disabled
          ></md-input>
        </md-field>
      </div>
      <md-button
        v-if="paymentDtlsData.length"
        style="height:28px; margin-top: .5%"
        class="md-warning md-just-icon"
        @click="downloadCustomExcel()"
        title="Download Excel"
        ><md-icon>file_download</md-icon></md-button
      >
      <div class="md-layout-item">
        <b-table
          striped
          hover
          outlined
          sticky-header
          :items="paymentDtlsData"
          :fields="paymentDtlsFields"
          show-empty
          class="bg-white"
        >
          <!-- <template #head(invoice_amount)="">
            <div style="text-align:center">Invoice Amount</div>
          </template> -->
          <template #head(doc_sequence_value)="data">
            <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
          </template>
          <template #head(inv_type)="data">
            <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
          </template>
          <template #head(invoice_num)="data">
            <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
          </template>
          <template #head(invoice_amount)="data">
            <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
          </template>
          <template #head(invoice_payment_amount)="data">
            <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
          </template>
          <template #head(invoice_date)="data">
            <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
          </template>
          <template #cell(invoice_amount)="data">
            <span class="text-fix">
              {{
                data.item.invoice_amount !== 0
                  ? formattedCurrency(data.item.invoice_amount)
                  : data.item.invoice_amount
              }}</span
            >
          </template>
          <template #cell(invoice_payment_amount)="data">
            <span class="text-fix-payment">
              {{
                data.item.invoice_payment_amount !== 0
                  ? formattedCurrency(data.item.invoice_payment_amount)
                  : data.item.invoice_payment_amount
              }}</span
            >
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
        <div
          v-if="paymentDtlsData.length && totalRows <= perPage"
          class="pagination-count"
        >
          <p>
            Showing
            {{ totalRows == 0 ? totalRows : perPage * (currentPage - 1) + 1 }}
            to {{ totalRows }} of {{ totalRows }} entries
          </p>
        </div>
        <div v-else-if="paymentDtlsData.length" class="pagination-count">
          <p>
            Showing {{ perPage * (currentPage - 1) + 1 }} to
            {{
              perPage * currentPage >= totalRows
                ? totalRows
                : perPage * currentPage
            }}
            of {{ totalRows }} entries
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commonHelper from "../../../utility/common.helper.utility";
import HeaderSummary from "../HeaderSummary.vue";
export default {
  name: "VendorPaymentDetails",
  watch: {
    currentPage: function() {
      this.getVendorPaymentsDtlsById();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getVendorPaymentsDtlsById();
    },
  },
  components: {
    HeaderSummary,
  },
  props: ["vendorPaymentRowDtls"],
  data() {
    return {
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      totalRows: null,
      loader: false,
      paymentHdrDtls: {
        le: null,
        check_id: null,
        check_amount: null,
        check_date: null,
        utr: null,
        currency_code: null,
      },
      paymentDtlsData: [],
      paymentDtlsFields: [
        {
          key: "inv_type",
          label: "Invoice Type",
        },
        {
          key: "doc_sequence_value",
          label: "Document Sequence",
          class: "col-fix",
        },
        {
          key: "invoice_num",
          label: "Invoice Number",
        },
        {
          key: "invoice_amount",
          class: "text-fix",
        },
        {
          key: "invoice_payment_amount",
          label: "Payment Amount",
          class: "text-fix-payment",
        },
        {
          key: "invoice_date",
        },
      ],
      // menuId: this.$store.state.auth.VendorPayment.menu_id,
      menuId: null,
      vendorId: null,
      totalPayload: null,
    };
  },

  mounted() {
    const menuItem = JSON.parse(sessionStorage.getItem("VendorPayment"));
    this.vendorId = JSON.parse(sessionStorage.getItem("VendorId"));
    if (menuItem) {
      this.menuId = menuItem.menu_id;
    }
    if (this.vendorPaymentRowDtls) {
      this.fillRecordForVendorPaymentDetails(this.vendorPaymentRowDtls);
      this.getVendorPaymentsDtlsById();
    }
  },
  methods: {
    fillRecordForVendorPaymentDetails(result) {
      this.paymentHdrDtls = result;
      this.vendorPaymentRowDtls.check_amount = this.formattedCurrency(
        result.check_amount
      );
    },
    getVendorPaymentsDtlsById() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        vendor_id: this.vendorId,
        check_id: this.paymentHdrDtls.check_id,
      };
      this.totalPayload = payload;
      this.loader = true;
      this.$store
        .dispatch("vendor/getVendorPaymentsDtlsById", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.paymentDtlsData = result.map((elem) => {
              elem.invoice_date = commonHelper.formatDate(elem.invoice_date);
              return elem;
            });
            this.totalRows = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    downloadCustomExcel() {
      this.totalPayload._limit = this.totalRows;
      this.loader = true;
      this.$store
        .dispatch("vendor/getVendorPaymentsDtlsById", this.totalPayload)
        .then((resp) => {
          this.loader = false;
          const result = resp.data.data.data.page.map((elem) => {
            elem.check_date = commonHelper.formatDate(elem.check_date);
            elem.invoice_date = commonHelper.formatDate(elem.invoice_date);
            elem.amount =
              elem.amount !== 0
                ? commonHelper.formatAmount(elem.amount)
                : elem.amount;
            elem.invoice_amount =
              elem.invoice_amount !== 0
                ? commonHelper.formatAmount(elem.invoice_amount)
                : elem.invoice_amount;
            elem.invoice_payment_amount =
              elem.invoice_payment_amount !== 0
                ? commonHelper.formatAmount(elem.invoice_payment_amount)
                : elem.invoice_payment_amount;
            return elem;
          });
          const customDownloadData = result.map((obj) => ({
            legal_entity: obj.le,
            cheque_number: obj.check_id,
            ut_number: obj.utr,
            cheque_date: obj.check_date,
            cheque_amount: obj.amount,
            invoice_type: obj.inv_type,
            doc_sequence: obj.doc_sequence_value,
            invoice_number: obj.invoice_num,
            invoice_amount: obj.invoice_amount,
            payment_amount: obj.invoice_payment_amount,
            invoice_date: obj.invoice_date,
            currency: obj.currency_code,
          }));
          if (customDownloadData) {
            commonHelper.downloadExcel(
              customDownloadData,
              "vendor-payment-details"
            );
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    formattedCurrency(num) {
      return commonHelper.formatAmount(num);
    },
  },
};
</script>

<style>
.col-fix > div {
  width: 10rem !important;
}

.col-fix-utr > div {
  width: 4rem !important;
}
.text-fix {
  margin-right: 42% !important;
  text-align: right !important;
}
.text-fix-payment {
  margin-right: 39% !important;
  text-align: right !important;
}
</style>
