import { format } from "date-fns";
import URL_UTILITY from "../utility/url.utility";
import store from "../store";
import XLSX from "xlsx";

const setStorage = (name, value) => {
  window.localStorage.setItem(name, value);
};
const getStorage = (name) => {
  return window.localStorage.getItem(name) || null;
};

const removeStorage = (name) => {
  window.localStorage.removeItem(name);
};

const clearStorage = () => {
  window.localStorage.clear();
};

const setSessionStorage = (name, value) => {
  sessionStorage.setItem(name, value);
};

const getSessionStorage = (name) => {
  sessionStorage.getItem(name);
};

const clearSession = () => {
  sessionStorage.clear();
};
const getPageOption = () => {
  return [10, 20, 50, 100];
};
const perPageRecord = 10;

const formattedDate = (date) => {
  return date ? format(new Date(date), "dd-MMM-yyyy") : null;
};
const downloadFile = (url, fileName) => {
  const downloadUrl = url;
  fetch(downloadUrl)
    .then((res) => res.blob())
    .then((data) => {
      var a = document.createElement("a");
      a.href = window.URL.createObjectURL(data);
      a.download = fileName;
      a.click();
    });
};

const formatCurrency = (num) => {
  num = Math.floor(num);
  num = num.toLocaleString("en-IN", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return num;
};
const formatAmount = (num) => {
  num =
    num || num == 0
      ? num.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : null;
  return num;
};

const formatAmountWithCurrency = (num) => {
  num =
    num || num == 0
      ? num.toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : null;
  return num;
};
const previewImgDoc = (payload) => {
  const url =
    URL_UTILITY.getOpenKmDocDeleteUrl +
    "/preview/" +
    payload.docDetailId +
    "?token=" +
    payload.token;
  const token = sessionStorage.getItem("authToken");
  fetch(url, {
    method: "get",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
  })
    .then((response) => {
      return response.blob();
    })
    .then((blob) => {
      let imgLink = null;
      if (payload.fileExtension === "pdf") {
        imgLink = window.URL.createObjectURL(
          new Blob([blob], { type: "application/pdf" })
        );
      } else {
        imgLink = window.URL.createObjectURL(new Blob([blob]));
      }
      document.querySelector("#prev-docs").src = imgLink + "#toolbar=0";
    });
};
const getFileExtension = (fileName) => fileName.split(".").pop();

const getValuesetData = async (valueSetName, searchKey) => {
  const payload = {
    valueSetName: valueSetName,
    dependentVariable: {
      search_key: searchKey,
    },
  };
  const response = await store.dispatch("valueSet/getValueSet", payload);
  const result = response.data.data;
  const valuesetData =
    result.data && result.data.length > 0 ? result.data : result.page;
  return valuesetData;
};
const disabledDates = (date, inputDate) => {
  const restrictedDate = new Date(inputDate);
  restrictedDate.setHours(0, 0, 0, 0);
  return date < restrictedDate;
};
const calculateTotal = (array) => {
  return array.reduce((preValue, curValue) => {
    return parseFloat(preValue) + parseFloat(curValue);
  });
};
const formatDate = (inputDate) => {
  if (inputDate) {
    const months = {
      JAN: "Jan",
      FEB: "Feb",
      MAR: "Mar",
      APR: "Apr",
      MAY: "May",
      JUN: "Jun",
      JUL: "Jul",
      AUG: "Aug",
      SEP: "Sep",
      OCT: "Oct",
      NOV: "Nov",
      DEC: "Dec",
    };
    const parts = inputDate.split("-");
    const day = parts[0];
    const month = months[parts[1]];
    const year = parts[2];
    return `${day}-${month}-${year}`;
  } else {
    return null;
  }
};
const truncate = (source, size) => {
  if (source) {
    return source.length > size ? source.slice(0, size - 1) + "…" : source;
  }
};
const containsSpecialCharacters = (inputString) => {
  const specialCharactersPattern = /[`!@#$%^&*()_+{}\[\]:;<>,.?~\\]/;
  return specialCharactersPattern.test(inputString);
};

const previewImgDocParty = (payload) => {
  const url =
    URL_UTILITY.getOpenKmDocDeleteUrl +
    "/preview/" +
    payload.docDetailId +
    "?token=" +
    payload.token;
  const token = sessionStorage.getItem("token");
  fetch(url, {
    method: "get",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
  })
    .then((response) => {
      return response.blob();
    })
    .then((blob) => {
      let imgLink = null;
      if (payload.fileExtension === "pdf") {
        imgLink = window.URL.createObjectURL(
          new Blob([blob], { type: "application/pdf" })
        );
      } else {
        imgLink = window.URL.createObjectURL(
          new Blob([blob], { type: "image/jpeg" })
        );
      }
      imgLink = imgLink + "#toolbar=0";
      window.open(imgLink, "_blank");
    });
};
const downloadExcel = (downloadData, compName) => {
  const dataToDownload = downloadData.map((data) => {
    let newData = {};
    for (const key in data) {
      const formatedKeyName = key.replace(/_/g, " ").toUpperCase();
      Object.assign(newData, { [formatedKeyName]: data[key] });
    }
    return newData;
  });
  const data = XLSX.utils.json_to_sheet(dataToDownload);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, data, "data");
  XLSX.writeFile(wb, `${compName}-${new Date().toLocaleDateString()}.xlsx`);
};

export default {
  setStorage,
  getStorage,
  removeStorage,
  clearStorage,
  setSessionStorage,
  getSessionStorage,
  clearSession,
  getPageOption,
  perPageRecord,
  formattedDate,
  downloadFile,
  formatCurrency,
  previewImgDoc,
  getFileExtension,
  getValuesetData,
  disabledDates,
  formatAmount,
  calculateTotal,
  formatDate,
  formatAmountWithCurrency,
  truncate,
  containsSpecialCharacters,
  previewImgDocParty,
  downloadExcel,
};
