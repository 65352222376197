import Vue from "vue";
import router from "@/router";
import { VueAuthenticate } from "vue-authenticate";
import http from "../../services/httpServices";
import URL_UTILITY from "../../utility/url.utility";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

const vueAuth = new VueAuthenticate(Vue.prototype.$http, {
  baseUrl: process.env.VUE_APP_API_BASE_URL,
  tokenName: "access_token",
  loginUrl: "/login",
  registerUrl: "/register",
});

export default {
  state: {
    isAuthenticated: sessionStorage.getItem("authToken") !== null,
    partyId: sessionStorage.getItem("partyId"),
    partyType: sessionStorage.getItem("partyType"),
    isNewVendor: sessionStorage.getItem("isNewVendor"),
    // vendorId: sessionStorage.getItem("vendorId"),
  },

  getters: {
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
    getPartyId(state) {
      return state.partyId;
    },
    getPartyType(state) {
      return state.partyType;
    },
  },

  mutations: {
    isAuthenticated(state, payload) {
      state.isAuthenticated = payload.isAuthenticated;
    },
    setPartyId(state, payload) {
      state.partyId = payload.partyId;
    },
    setCompliance(state, payload) {
      state.Compliance = payload.Compliance;
    },
    setVendorInvoiceEntry(state, payload) {
      state.VendorInvoiceEntry = payload.VendorInvoiceEntry;
    },
    setAddress(state, payload) {
      state.Address = payload.Address;
    },
    setOnBoarding(state, payload) {
      state.OnBoarding = payload.OnBoarding;
    },
    setBankDetails(state, payload) {
      state.BankDetails = payload.BankDetails;
    },
    setAddItionalDtls(state, payload) {
      state.AdditionalDtls = payload.AdditionalDtls;
    },
    setInvoiceDashboard(state, payload) {
      state.InvoiceDashboard = payload.InvoiceDashboard;
    },
    setVendorPayment(state, payload) {
      state.VendorPayment = payload.VendorPayment;
    },
    setPODetails(state, payload) {
      state.PODetails = payload.PODetails;
    },
    setPartyType(state, payload) {
      state.partyType = payload.partyType;
    },
    setIsNewVendor(state, payload) {
      state.isNewVendor = payload.isNewVendor;
    },
    // setVendorId(state, payload) {
    //   state.vendorId = payload.vendorId;
    // },
  },

  actions: {
    setAuthToken(context, token) {
      context.commit("isAuthenticated", {
        isAuthenticated: token,
      });
    },
    setPartyId(context, partyId) {
      context.commit("setPartyId", {
        partyId: partyId,
      });
    },
    setCompliance(context, Compliance) {
      context.commit("setCompliance", {
        Compliance: Compliance,
      });
    },
    setAddress(context, Address) {
      context.commit("setAddress", {
        Address: Address,
      });
    },
    setVendorInvoiceEntry(context, VendorInvoiceEntry) {
      context.commit("setVendorInvoiceEntry", {
        VendorInvoiceEntry: VendorInvoiceEntry,
      });
    },
    setBankDetails(context, BankDetails) {
      context.commit("setBankDetails", {
        BankDetails: BankDetails,
      });
    },
    setOnBoarding(context, OnBoarding) {
      context.commit("setOnBoarding", {
        OnBoarding: OnBoarding,
      });
    },
    setAddItionalDtls(context, AdditionalDtls) {
      context.commit("setAddItionalDtls", {
        AdditionalDtls: AdditionalDtls,
      });
    },
    setInvoiceDashboard(context, InvoiceDashboard) {
      context.commit("setInvoiceDashboard", {
        InvoiceDashboard: InvoiceDashboard,
      });
    },
    setVendorPayment(context, VendorPayment) {
      context.commit("setVendorPayment", {
        VendorPayment: VendorPayment,
      });
    },
    setPODetails(context, PODetails) {
      context.commit("setPODetails", {
        PODetails: PODetails,
      });
    },
    setPartyType(context, partyType) {
      context.commit("setPartyType", {
        partyType: partyType,
      });
    },
    setIsNewVendor(context, isNewVendor) {
      context.commit("setIsNewVendor", {
        isNewVendor: isNewVendor,
      });
    },
    // setVendorId(context, vendorId) {
    //   context.commit("setVendorId", {
    //     vendorId: vendorId,
    //   });
    // },

    login(context, payload) {
      return http.postApi(URL_UTILITY.loginUrl, payload);
    },
    passwordReset(context, payload) {
      return http.postApi(URL_UTILITY.newPasswordUrl + "/otp", payload);
    },
    confirmOTP(context, payload) {
      return http.postApi(URL_UTILITY.verifyOtpUrl, payload);
    },
    setNewPassword(context, payload) {
      return http.postApi(URL_UTILITY.newPasswordUrl, payload);
    },
    register(context, payload) {
      return vueAuth
        .register(payload.user, payload.requestOptions)
        .then((response) => {
          context.commit("isAuthenticated", {
            isAuthenticated: vueAuth.isAuthenticated(),
          });
          router.push({ name: "Home" });
        });
    },

    logout(context, payload) {
      sessionStorage.clear();
      localStorage.clear();
      context.commit("isAuthenticated", {
        isAuthenticated: null,
      });
      router.push({ name: "Login" });
    },
  },
};
