var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('md-card-content',[(_vm.loader)?_c('div',{staticClass:"overlayLoader"},[_c('md-progress-spinner',{staticClass:"md-accent spinner-postion",attrs:{"md-diameter":100,"md-stroke":7,"md-mode":"indeterminate"}})],1):_vm._e(),_c('b-alert',{staticStyle:{"width":"50%"},attrs:{"variant":_vm.isSuccess ? 'primary' : 'danger',"show":_vm.showAlert,"dismissible":"","fade":""},on:{"dismissed":function($event){_vm.showAlert = false}}},[_vm._v(_vm._s(_vm.responseMsg)+" ")]),_c('div',{staticClass:"md-layout-item"},[_c('b-table',{staticClass:"bg-white",staticStyle:{"max-height":"25rem"},attrs:{"striped":"","hover":"","outlined":"","sticky-header":"","items":_vm.additionalData,"fields":_vm.additionalFields,"show-empty":""},scopedSlots:_vm._u([{key:"cell(add_info_desc_meaning)",fn:function(data){return [_c('span',{class:data.item.gl_calendar_dtl_id ? 'mandatory' : ''},[_vm._v(_vm._s(data.item.add_info_desc_meaning))])]}},{key:"cell(add_info_value)",fn:function(data){return [(
              data.item.add_info_desc === 'MSME' ||
                data.item.add_info_desc === 'E-Invoice'
            )?_c('b-form-checkbox',{attrs:{"value":true,"unchecked-value":false,"disabled":!_vm.editMode},model:{value:(data.item.add_info_value),callback:function ($$v) {_vm.$set(data.item, "add_info_value", $$v)},expression:"data.item.add_info_value"}}):_c('b-form-input',{directives:[{name:"numericOnly",rawName:"v-numericOnly"}],attrs:{"disabled":!_vm.editMode || data.item.document_det_id > 0},model:{value:(data.item.add_info_value),callback:function ($$v) {_vm.$set(data.item, "add_info_value", $$v)},expression:"data.item.add_info_value"}})]}},{key:"cell(upload_document)",fn:function(data){return [(
              data.item.gl_calendar_dtl_id &&
                data.item.add_info_value &&
                data.item.add_info_value !== '0' &&
                data.item.add_info_id &&
                !data.item.document_det_id
            )?_c('md-field',{attrs:{"md-clearable":""}},[_c('md-file',{on:{"md-change":_vm.selectFile},model:{value:(data.item.upload_document),callback:function ($$v) {_vm.$set(data.item, "upload_document", $$v)},expression:"data.item.upload_document"}})],1):_vm._e(),(data.item.document_det_id)?_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.getOpenKmAuthenticate(
                data.item.add_info_id,
                data.item.document_det_id
              )}}},[_vm._v(_vm._s(data.item.original_file_name))]):_vm._e()]}},{key:"cell(save_document)",fn:function(data){return [(data.item.upload_document)?_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.getOpenKmAuthenticate(data.item.add_info_id)}}},[_vm._v("Upload")]):_vm._e()]}},(_vm.approvalStatus === 'DRAFT' || _vm.approvalStatus === 'REJECTED')?{key:"cell(delete)",fn:function(data){return [(data.item.document_det_id)?_c('span',{staticClass:"material-icons",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.getOpenKmAuthenticateDelete(data.item.document_det_id)}}},[_vm._v(" delete ")]):_vm._e()]}}:null],null,true)})],1),(!_vm.isAdditionalDetailsSaved)?_c('md-button',{staticClass:"md-raised md-primary search-btn",staticStyle:{"margin-top":"20px","margin-left":"15px"},on:{"click":function($event){return _vm.addEditAdditionalDetails()}}},[_vm._v("Save")]):_vm._e(),(!_vm.editMode)?_c('md-button',{staticClass:"md-raised md-primary search-btn",staticStyle:{"margin-top":"20px","margin-left":"15px"},on:{"click":function($event){return _vm.editModeAdditionalDetails()}}},[_vm._v("Edit")]):_vm._e(),_c('span',{staticStyle:{"color":"red","float":"right"}},[_c('b',[_vm._v("*Maximum file size 5 MB")]),_c('br'),_c('b',[_vm._v("*only pdf, jpg, jpeg, png, zip file allowed")])]),_c('br'),_c('span',{staticStyle:{"color":"red","float":"right"}},[_c('b',[_vm._v("*Please enter 0 for the respective Financial Year if the company is not registered before 3 years")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }